import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { NavigateFunction } from "react-router-dom";

import { ButtonProps, Flex, Skeleton } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { NavigationButton as LinkButton, Select } from "Molecules";
import { formatShopUrlName } from "Utils";
import { GET_PUBLIC_SHOP_INFOS_BY_IDS } from "GraphQLQueries";
import { Shop } from "Types";

type Props = {
    shopIds: string[];
    navigate: NavigateFunction;
    preferredShopId?: string;
    onClick?: ButtonProps["onClick"];
};

export const OnlineShopSelector: React.FC<Props> = ({ shopIds, navigate, preferredShopId = "", onClick }) => {
    const [publicId, setPublicId] = useState<string | null>(null);
    const { translate } = useLanguage();

    const { data: shopsData, loading: shopsLoading } = useQuery(GET_PUBLIC_SHOP_INFOS_BY_IDS, {
        variables: { shopIds }
    });

    useEffect(() => {
        if (shopsData && shopsData.getPublicShopInfosByIds) {
            let publicId = null;

            if (preferredShopId) {
                const preSelectedShop = shopsData.getPublicShopInfosByIds
                    .find(
                        (shop: Shop) => shop.id === preferredShopId
                    );

                if (preSelectedShop) {
                    publicId = preSelectedShop.publicId;
                }
            } else {
                const [preSelectedShop] = shopsData.getPublicShopInfosByIds;

                // This is the fallback in case the preferredShopId is not found
                const [firstShop] = shopsData.getPublicShopInfosByIds;

                // WARNING: If publicId is null, the skeleton will load forever
                // If we want to prevent this, we need to hide this entire component in the parent
                publicId = preSelectedShop?.publicId || firstShop?.publicId || null;
            }

            setPublicId(publicId);
        }
    }, [shopsData]);

    if (shopsLoading || !publicId) {
        return <Skeleton h="35px" w="100%" />;
    }

    const shops = shopsData.getPublicShopInfosByIds;

    const eligibleShops = shops.map((shop: any) => ({
        name: shop.name,
        publicId: shop.publicId
    }));

    const shop = shops.find((shop: any) => shop.publicId === publicId);
    const selectedShopUrl = `/restaurant/${formatShopUrlName(shop.name)}/${publicId}/order`;

    return (
        <Flex h="auto">
            <Select value={publicId} onChange={(event: any) => setPublicId(event.target.value)}>
                {eligibleShops.map((shop: any) => (
                    <option key={shop.publicId} value={shop.publicId}>
                        {shop.name}
                    </option>
                ))}
            </Select>
            <LinkButton size="md" ml={2} onClick={onClick} to={selectedShopUrl} navigate={navigate}>
                {translate("goToShop")}
            </LinkButton>
        </Flex>
    );
};
