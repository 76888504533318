import React, { useEffect, useRef, useState } from "react";
import { TiTick } from "@react-icons/all-files/ti/TiTick";

import { BaseBoxProps, Box, Flex, FlexProps, Image } from "Atoms";
import { useTheme } from "ThemeProvider";
import { getTextColor } from "Utils";
import { TruncatedText } from "./TruncatedText";

export type ProgressStep = {
    progressId: string;
    progressStepName: string;
    progressStep: number;
    progressStepText: string;
    progressStepCompleted: boolean;
    isCurrentProgressStep: boolean;
    selectedItemId?: string;
    selectedItemImage?: string;
    isDisabled?: boolean;
    skipStep?: boolean;
};

type Props = {
    selectedProgressItems: ProgressStep[];
    onClickStep?: (stepNumber: number) => void;
    isSmallerVersion?: boolean;
    textWrapperProps?: BaseBoxProps;
} & FlexProps;

type SmallVerison = {
    isSmallerVersion: boolean;
};

const ProgressStep = ({ isSmallerVersion, ...props }: FlexProps & SmallVerison) => (
    <Flex
        {...(isSmallerVersion ? { w: "62px", h: "62px" } : { w: "72px", h: "72px" })}
        borderRadius="full"
        borderWidth="5px"
        borderStyle="solid"
        color="white"
        fontWeight="600"
        justifyContent="center"
        alignItems="center"
        fontSize="2xl"
        {...props}
    />
);

const ProgressLine = ({ ...props }: BaseBoxProps) => <Box flex="1" {...props} />;

const ProgressStepWrapper = ({ ...props }: FlexProps) => (
    <Flex direction="column" alignItems="center" position="relative" height="auto" {...props} />
);

const ProgresStep = ({ step }: { step: ProgressStep }) => {
    if (!!step.selectedItemImage) {
        return <Image src={step.selectedItemImage} objectFit="cover" borderRadius="full" width="100%" height="100%" />;
    }
    return <Box as="span">{step.progressStep}</Box>;
};

const CompletedProgress = ({ step, ...props }: BaseBoxProps & { step: ProgressStep }) => {
    if (!!step.selectedItemImage) {
        return (
            <>
                <Image src={step.selectedItemImage} objectFit="cover" borderRadius="full" width="100%" height="100%" />
                <Box position="absolute" textAlign="center" top={0} {...props}>
                    <Box as={TiTick} />
                </Box>
            </>
        );
    }
    return <Box as={TiTick} {...props} />;
};

enum OVERLAY_POSITION {
    TOP = "TOP",
    BOTTOM = "BOTTOM",
    LEFT = "LEFT",
    RIGHT = "RIGHT"
}

type DimmedOverlayProps = {
    isPortrait: boolean;
    overlayPosition: OVERLAY_POSITION;
    isVisible: boolean;
} & BaseBoxProps;

const DimmedOverlay: React.FC<DimmedOverlayProps> = ({ isPortrait, overlayPosition, isVisible, ...props }) => {
    const overlayTransition = isPortrait
        ? "min-width 0.5s ease-in-out, max-width 0.5s ease-in-out, opacity 0.5s ease-in-out"
        : "min-height 0.5s ease-in-out, max-height 0.5s ease-in-out, opacity 0.5s ease-in-out";

    const overlayStyles = {
        TOP: {
            top: 0,
            left: 0,
            width: "100%",
            height: "60px",
            background: "linear-gradient(to bottom, white 70%, rgba(255, 255, 255, 0.5) 90%, transparent 100%)"
        },
        BOTTOM: {
            bottom: 0,
            left: 0,
            width: "100%",
            height: "60px",
            background: "linear-gradient(to top, white 70%, rgba(255, 255, 255, 0.5) 90%, transparent 100%)"
        },
        LEFT: {
            top: 0,
            left: 0,
            width: "45px",
            height: "100%",
            background: "linear-gradient(to right, white 70%, rgba(255, 255, 255, 0.5) 90%, transparent 100%)"
        },

        RIGHT: {
            top: 0,
            right: 0,
            width: "45px",
            height: "100%",
            background: "linear-gradient(to left, white 70%, rgba(255, 255, 255, 0.5) 90%, transparent 100%)"
        }
    };

    return isVisible ? (
        <Box
            position={"absolute"}
            zIndex={101}
            transition={overlayTransition}
            opacity={isVisible ? 1 : 0}
            {...overlayStyles[overlayPosition]}
            {...props}
        />
    ) : null;
};

export const ExpressProgressIndicator: React.FC<Props> = ({
    selectedProgressItems = [],
    onClickStep,
    textWrapperProps,
    isSmallerVersion = false,
    ...rest
}) => {
    const {
        colors: { expressPrimary, expressSuccess },
        orientation: { isPortrait }
    } = useTheme();

    const flexRef = useRef<HTMLDivElement>(null);
    const [showOverlay, setShowOverlay] = useState({
        start: false,
        end: false
    });

    const lastIndex = selectedProgressItems.length - 1;
    const textColour = getTextColor(expressPrimary);

    const checkVisibility = () => {
        if (flexRef.current) {
            const container = flexRef.current;
            const isScrolledTop = isPortrait ? container.scrollLeft > 0 : container.scrollTop > 0;
            const isScrolledBottom = isPortrait
                ? container.scrollLeft + container.clientWidth < container.scrollWidth
                : container.scrollTop + container.clientHeight < container.scrollHeight;
            setShowOverlay({ start: isScrolledTop, end: isScrolledBottom });
        }
    };

    useEffect(() => {
        const container = flexRef.current;
        if (container) {
            container.addEventListener("scroll", checkVisibility);
        }
        checkVisibility();

        return () => {
            if (container) {
                container.removeEventListener("scroll", checkVisibility);
            }
        };
    }, []);

    useEffect(() => {
        if (selectedProgressItems.length > 7) {
            if (!showOverlay.end) {
                setShowOverlay({ ...showOverlay, end: true });
            }

            const currentStepIdx = selectedProgressItems.findIndex(item => item.isCurrentProgressStep);

            if (flexRef.current && selectedProgressItems.length > 7) {
                if (isPortrait) {
                    flexRef.current.scrollTo({
                        left: 120 * (currentStepIdx - 1),
                        behavior: "smooth"
                    });
                } else {
                    flexRef.current.scrollTo({
                        top: 100 * (currentStepIdx - 1),
                        behavior: "smooth"
                    });
                }
            }
        }
    }, [selectedProgressItems]);

    return (
        <>
            <DimmedOverlay
                isVisible={showOverlay.start}
                overlayPosition={isPortrait ? OVERLAY_POSITION.LEFT : OVERLAY_POSITION.TOP}
                isPortrait={isPortrait}
            />
            <Flex
                direction={isPortrait ? "row" : "column"}
                alignItems="center"
                p={4}
                width="100%"
                ref={flexRef}
                {...rest}
            >
                {selectedProgressItems.map((value: ProgressStep, index: number) => {
                    const { progressStepText, isCurrentProgressStep, progressStepCompleted, progressStep } = value;

                    const showProgressLine = index !== lastIndex;
                    const isLastIndex = index === lastIndex;

                    const isDisabled = value?.isDisabled;
                    return (
                        <React.Fragment key={progressStep}>
                            <ProgressStepWrapper
                                cursor={!isDisabled ? "pointer" : "none"}
                                onClick={() => !isDisabled && !!onClickStep && onClickStep(progressStep)}
                            >
                                <ProgressStep
                                    backgroundColor={expressPrimary}
                                    borderColor={isCurrentProgressStep ? expressSuccess : expressPrimary}
                                    color={textColour}
                                    isSmallerVersion={isSmallerVersion}
                                    {...(progressStepCompleted && { position: "relative" })}
                                >
                                    {progressStepCompleted ? (
                                        <CompletedProgress
                                            step={value}
                                            color={expressSuccess}
                                            fontSize={"5xl"}
                                            top={!isSmallerVersion ? 0 : "-7px"}
                                        />
                                    ) : (
                                        <ProgresStep step={value} />
                                    )}
                                </ProgressStep>
                                <Box
                                    position="absolute"
                                    top={!isSmallerVersion ? "75px" : "60px"}
                                    textAlign="center"
                                    width="200px"
                                    zIndex={100}
                                    fontSize="sm"
                                    fontWeight="600"
                                    {...(!isPortrait && {
                                        backgroundColor: "white",
                                        marginTop: "0.5rem",
                                        padding: "0.5rem"
                                    })}
                                    {...textWrapperProps}
                                >
                                    <TruncatedText>{progressStepText}</TruncatedText>
                                </Box>
                            </ProgressStepWrapper>

                            {showProgressLine && (
                                <ProgressLine
                                    backgroundColor={expressPrimary}
                                    {...(isPortrait
                                        ? { height: "3px", minWidth: "70px" }
                                        : { minHeight: "75px", maxHeight: "75px", width: "3px" })}
                                />
                            )}
                            {isLastIndex && !isPortrait && (
                                <ProgressLine
                                    backgroundColor={expressPrimary}
                                    minHeight="7px"
                                    maxHeight="7px"
                                    width="3px"
                                />
                            )}
                        </React.Fragment>
                    );
                })}
            </Flex>
            <DimmedOverlay
                isVisible={showOverlay.end}
                overlayPosition={isPortrait ? OVERLAY_POSITION.RIGHT : OVERLAY_POSITION.BOTTOM}
                isPortrait={isPortrait}
            />
        </>
    );
};
