import React from "react";

import { NewButton } from "Atoms";
import { Modal, ModalBody, ModalActions } from "Molecules";
import { IModalContext } from "Providers";
import { modalNames } from "Constants";
import TermsAndConditions from "../../../customer/pages/termsAndConditions/TermsAndConditions";

export const TermsAndConditionsModal: React.FC<IModalContext<{}>> = ({ closeModal }) => (
    <Modal open={true} borderRadius="md" isScrolling onClose={() => closeModal(modalNames.TERMS_AND_CONDITIONS_MODAL)}>
        <ModalBody p={6}>
            <TermsAndConditions closeModal={closeModal} />
        </ModalBody>
        <ModalActions>
            <NewButton fullWidth themeColor="blue" onClick={() => closeModal(modalNames.TERMS_AND_CONDITIONS_MODAL)}>
                Okej
            </NewButton>
        </ModalActions>
    </Modal>
);
