import React, { forwardRef } from "react";
import { Table } from "semantic-ui-react";
import { capitalize } from "lodash";
import { Link as RLink } from "react-router-dom";

import { useQoplaStore } from "Stores";
import { formatDateToLocal } from "TempUtils";
import { swedishPaymentMethod } from "Utils";
import { formatFinancialNumbers } from "PriceUtils";
import { PaymentMethod, reportTypeConstants, THIRD_PARTY_DELIVERY_TYPE_IN_SWEDISH } from "Constants";
import {
    XZReportType,
    VatRatesAndAmountsType,
    OnlineSalesReportType,
    CardAmountsType,
    OnlineChannelSalesType,
    VatRatesAndAmountsWithRefundsType,
    PaymentMethodAmountWithTip
} from "./reportTypes";
import { CompanyLocale } from "Types";
import { Text, Header, Box, Link } from "Atoms";
import "./report.css";
import { LinkType } from "Types";

export const printCategories = (report: XZReportType, companyLocale: CompanyLocale) => {
    return (
        <React.Fragment>
            <Table.Row>
                <Table.Cell colSpan={3} className={"zReportHeader"}>
                    Försäljning per varugrupp
                </Table.Cell>
            </Table.Row>
            {report.categoryTotalSales.map(catAmount => {
                const categoryName =
                    catAmount.categoryName === "THIRD_PARTY_MENU_CAT_ID" ? "Leveranstjänster" : catAmount.categoryName;
                return (
                    <Table.Row key={catAmount.categoryName}>
                        <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                            {categoryName}
                        </Table.Cell>
                        <Table.Cell className={"noBorder"} textAlign={"right"}>
                            {formatFinancialNumbers(catAmount.totalSales, companyLocale)}
                        </Table.Cell>
                    </Table.Row>
                );
            })}
        </React.Fragment>
    );
};

export const printVatRates = (
    vatRates: VatRatesAndAmountsWithRefundsType[],
    companyLocale: CompanyLocale,
    shouldIncludesRefunds: boolean,
    isOnline = false
) => {
    const sourceText = isOnline ? "Online Moms " : "Kassa Moms ";
    if (!vatRates) return null;
    return vatRates.map(vatAmount => {
        // is negative number or 0
        const refundedAmount = vatAmount.refundedAmount && shouldIncludesRefunds ? vatAmount.refundedAmount : 0;
        return (
            <Table.Row key={vatAmount.vatRate}>
                <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                    {sourceText} {vatAmount.vatRate}%
                </Table.Cell>
                <Table.Cell className={"noBorder"} textAlign={"right"}>
                    {formatFinancialNumbers(vatAmount.amount + refundedAmount, companyLocale)}
                </Table.Cell>
            </Table.Row>
        );
    });
};

const printCardTypes = (
    cardPaymentMethod: PaymentMethodAmountWithTip,
    cardAmounts: CardAmountsType[],
    companyLocale: CompanyLocale
) => {
    /**
     * TODO: In order for this to function well in the future, we should filter on `next.cardType == AMEX`
     * And we should type the `CardType` to something other than `string`
     *
     * The danger of not doing this, is if `cardAmounts` is changed to include cardAmounts other than AMEX, we will incidentally treat NON-AMEX amounts as AMEX
     * WARNING: Don't forget to update PDFGenerator when changing this code.
     */
    const AMEXCardAmountTip = cardAmounts.reduce((acc, next) => {
        return (acc += next.tip);
    }, 0);

    const AMEXCardAmount = cardAmounts.reduce((acc, next) => {
        return (acc += next.amount);
    }, 0);
    return (
        <>
            <Table.Row>
                <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                    {` - MC / Visa`}
                </Table.Cell>
                <Table.Cell className={"noBorder"} textAlign={"right"}>
                    {formatFinancialNumbers(cardPaymentMethod.amount - AMEXCardAmount, companyLocale)}
                </Table.Cell>
            </Table.Row>
            {cardPaymentMethod.tip > 0 && tipAmount(cardPaymentMethod.tip - AMEXCardAmountTip, companyLocale)}
            {/* For AMEX */}
            {cardAmounts.map(cardAmount => (
                <React.Fragment key={cardAmount.cardType}>
                    <Table.Row>
                        <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                            {` - ${capitalize(cardAmount.cardType)}`}
                        </Table.Cell>
                        <Table.Cell className={"noBorder"} textAlign={"right"}>
                            {formatFinancialNumbers(cardAmount.amount, companyLocale)}
                        </Table.Cell>
                    </Table.Row>
                    {cardAmount.tip > 0 && tipAmount(cardAmount.tip, companyLocale)}
                </React.Fragment>
            ))}
        </>
    );
};

const tipAmount = (tipAmount: number, companyLocale: CompanyLocale) => {
    return (
        <Table.Row>
            <Table.Cell className={"zReportPadded40 noBorder"} colSpan={2}>
                {` - varav dricks`}
            </Table.Cell>
            <Table.Cell className={"noBorder"} textAlign={"right"}>
                {formatFinancialNumbers(tipAmount, companyLocale)}
            </Table.Cell>
        </Table.Row>
    );
};

const printCardPayments = (
    cardPaymentMethod: PaymentMethodAmountWithTip,
    cardAmounts: CardAmountsType[],
    companyLocale: CompanyLocale
) => {
    /**
     * TODO: Make this check more explicit for AMEX types on `cardAmount.type`
     */
    const hasOtherCardTypes = cardAmounts && cardAmounts.length > 0;

    if (hasOtherCardTypes) {
        return (
            <>
                <Table.Row>
                    <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                        Total kortbetalning
                    </Table.Cell>
                    <Table.Cell className={"noBorder"} textAlign={"right"}>
                        {formatFinancialNumbers(cardPaymentMethod.amount, companyLocale)}
                    </Table.Cell>
                </Table.Row>
                {printCardTypes(cardPaymentMethod, cardAmounts, companyLocale)}
            </>
        );
    } else {
        return (
            <>
                <Table.Row>
                    <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                        Kort
                    </Table.Cell>
                    <Table.Cell className={"noBorder"} textAlign={"right"}>
                        {formatFinancialNumbers(cardPaymentMethod.amount, companyLocale)}
                    </Table.Cell>
                </Table.Row>
                {cardPaymentMethod.tip > 0 && tipAmount(cardPaymentMethod.tip, companyLocale)}
            </>
        );
    }
};

export const printReportPaymentMethods = (
    paymentMethodAndAmounts: PaymentMethodAmountWithTip[],
    cardAmounts: CardAmountsType[],
    companyLocale: CompanyLocale,
    roundedCashDecimals: number
) => {
    const { CARD, SWISH, CASH, INVOICE, GIFT_CARD, MANUAL_CARD } = PaymentMethod;
    const sortingPayment = [CARD, SWISH, CASH, INVOICE, GIFT_CARD, MANUAL_CARD];
    if (!paymentMethodAndAmounts) return null;
    return paymentMethodAndAmounts
        .sort((a, b) => sortingPayment.indexOf(a.paymentMethod) - sortingPayment.indexOf(b.paymentMethod))
        .map((payAmount, idx) => {
            let tableLine = <></>;
            switch (payAmount.paymentMethod) {
                case PaymentMethod.CARD:
                    //const _cardAmounts = isOnlineWithControlUnitValues ? report.cardAmountsOnline : report.cardAmounts;
                    tableLine = printCardPayments(payAmount, cardAmounts, companyLocale);
                    break;
                case PaymentMethod.CASH:
                    tableLine = (
                        <>
                            <Table.Row>
                                <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                                    {
                                        //@ts-ignore
                                        swedishPaymentMethod[payAmount.paymentMethod].swe
                                    }
                                </Table.Cell>
                                <Table.Cell className={"noBorder"} textAlign={"right"}>
                                    {formatFinancialNumbers(payAmount.amount, companyLocale)}
                                </Table.Cell>
                            </Table.Row>
                            {roundedCashDecimals !== 0 && (
                                <Table.Row>
                                    <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                                        - Öresavrundning
                                    </Table.Cell>
                                    <Table.Cell className={"noBorder"} textAlign={"right"}>
                                        {formatFinancialNumbers(roundedCashDecimals, companyLocale)}
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </>
                    );
                    break;
                default:
                    tableLine = (
                        <React.Fragment>
                            <Table.Row>
                                <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                                    {
                                        //@ts-ignore
                                        swedishPaymentMethod[payAmount.paymentMethod].swe
                                    }
                                </Table.Cell>
                                <Table.Cell className={"noBorder"} textAlign={"right"}>
                                    {formatFinancialNumbers(payAmount.amount, companyLocale)}
                                </Table.Cell>
                            </Table.Row>
                            {payAmount.tip > 0 && tipAmount(payAmount.tip, companyLocale)}
                        </React.Fragment>
                    );
            }
            return <React.Fragment key={`${payAmount.paymentMethod}${idx}`}>{tableLine}</React.Fragment>;
        });
};

const printReportOnlineChannelSales = (onlineChannelSales: OnlineChannelSalesType[], companyLocale: CompanyLocale) => {
    const sortingOnlineChannel = ["WEB", "FOODORA", "UBEREATS", "WOLT", "BOLT"];
    if (!onlineChannelSales) return null;
    return onlineChannelSales
        .sort((a, b) => sortingOnlineChannel.indexOf(a.orderType) - sortingOnlineChannel.indexOf(b.orderType))
        .map((onlineChannel, idx) => (
            <React.Fragment key={`${onlineChannel.orderType}${idx}`}>
                <Table.Row>
                    <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                        {onlineChannel.orderType === "WEB"
                            ? "Qopla online"
                            : THIRD_PARTY_DELIVERY_TYPE_IN_SWEDISH[onlineChannel.orderType]}
                    </Table.Cell>
                    <Table.Cell className={"noBorder"} textAlign={"right"}>
                        {formatFinancialNumbers(onlineChannel.amount, companyLocale)}
                    </Table.Cell>
                </Table.Row>
            </React.Fragment>
        ));
};

const printTotalNettoAndVAT = (
    rowHeader: string,
    totalNetto: number,
    vatRatesAndAmounts: VatRatesAndAmountsWithRefundsType[],
    companyLocale: CompanyLocale,
    shouldIncludeRefunds: boolean,
    isOnline: boolean
) => {
    return (
        <>
            <Table.Row>
                <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                    {rowHeader}
                </Table.Cell>
                <Table.Cell className={"noBorder"} textAlign={"right"}>
                    {formatFinancialNumbers(totalNetto, companyLocale)}
                </Table.Cell>
            </Table.Row>
            {printVatRates(vatRatesAndAmounts, companyLocale, shouldIncludeRefunds, isOnline)}
        </>
    );
};

// TODO: Refactor: change name `printTotal` to `printTotalExludingRefunds`
//  When doing this, make a copy/modification of this function in CashInvoiceReportViewer
export const printTotal = (
    report: any,
    companyLocale: CompanyLocale,
    headerText: string,
    excludeRefundsText: string,
    printHeader = true
) => {
    const deprecatedReportTipSum = report.tip ?? 0;

    const totalSalesOnline = report.totalSalesOnline ?? 0;
    const vatRatesAmounts = report.vatRateAmountWithRefunds ?? report.vatRatesAndAmounts;
    return (
        <React.Fragment>
            {printHeader && (
                <Table.Row>
                    <Table.Cell colSpan={3} className={"zReportHeader"}>
                        {headerText}
                    </Table.Cell>
                </Table.Row>
            )}
            {printTotalNettoAndVAT(
                "Totalt Kassa Nettoförsäljning",
                // Does not include refunds, also this is POS only
                report.totalNetSales - deprecatedReportTipSum,
                vatRatesAmounts,
                companyLocale,
                false,
                false
            )}

            {!!totalSalesOnline &&
                printTotalNettoAndVAT(
                    "Totalt Online Nettoförsäljning",
                    // We do not subtract the deprecated `report.tip` field because it was not summed into the totalNetSalesOnline field
                    // In fact, online tips were not included in the ZReport at all
                    report.totalNetSalesOnline,
                    report.vatRateAmountWithRefundsOnline,
                    companyLocale,
                    false,
                    true
                )}
            <Table.Row>
                <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                    Totalt Bruttoförsäljning {excludeRefundsText}
                </Table.Cell>
                <Table.Cell className={"noBorder"} textAlign={"right"}>
                    {formatFinancialNumbers(
                        report.totalNetSales +
                            vatRatesAmounts.reduce(
                                (acc: number, next: VatRatesAndAmountsType) => acc + next.amount,
                                0
                            ) +
                            totalSalesOnline -
                            deprecatedReportTipSum,
                        companyLocale
                    )}
                </Table.Cell>
            </Table.Row>
        </React.Fragment>
    );
};

// refunds will be included in vatRatesAndAmounts for new reports
// to support old reports we still need to pass refundTotalVatAmount(negative number)
export const calculateTotalNettoAndVAT = (
    paymentMethodAndAmounts: any,
    vatRatesAndAmounts: VatRatesAndAmountsWithRefundsType[],
    refundTotalVatAmount = 0
) => {
    const totalSaleIncludingRefunds = !!paymentMethodAndAmounts
        ? paymentMethodAndAmounts.reduce((acc: number, next: any) => acc + next.amount - (next.tip || 0), 0)
        : 0;

    let hasVatRefunded = false;

    const vatAmount =
        vatRatesAndAmounts?.reduce((acc: number, next) => {
            // is negative value or 0
            const refundedAmount = next.refundedAmount ?? 0;
            hasVatRefunded = !!next.refundedAmount;
            return acc + next.amount + refundedAmount;
        }, 0) ?? 0;

    const _refundTotalVatAmount = hasVatRefunded ? 0 : refundTotalVatAmount;

    const vatAmountInclRefunds = vatAmount + _refundTotalVatAmount;

    const totalSalesNettoIncludingRefunds = totalSaleIncludingRefunds - vatAmountInclRefunds;
    return { totalNetto: totalSalesNettoIncludingRefunds, vatAmount: vatAmountInclRefunds };
};

// shows only in reports with vatRateAmountWithRefunds
const printTotalInclRefunds = (report: XZReportType, companyLocale: CompanyLocale) => {
    const totalSalesOnline = report.totalSalesOnline ?? 0;

    const { totalNetto, vatAmount } = calculateTotalNettoAndVAT(
        report.paymentMethodAndAmounts,
        report.vatRateAmountWithRefunds
    );
    const { totalNetto: totalNettoOnline, vatAmount: vatAmountOnline } = calculateTotalNettoAndVAT(
        report.paymentMethodAndAmountsOnline,
        report.vatRateAmountWithRefundsOnline
    );
    const totalBrutto = totalNetto + vatAmount + totalNettoOnline + vatAmountOnline;

    return (
        <React.Fragment>
            <Table.Row>
                <Table.Cell colSpan={3} className={"zReportHeader"}>
                    Totalt (inkl returer)
                </Table.Cell>
            </Table.Row>
            {printTotalNettoAndVAT(
                "Totalt Kassa Nettoförsäljning",
                totalNetto,
                report.vatRateAmountWithRefunds,
                companyLocale,
                true,
                false
            )}
            {!!totalSalesOnline &&
                printTotalNettoAndVAT(
                    "Totalt Online Nettoförsäljning",
                    totalNettoOnline,
                    report.vatRateAmountWithRefundsOnline,
                    companyLocale,
                    true,
                    true
                )}
            <Table.Row>
                <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                    Totalt Bruttoförsäljning
                </Table.Cell>
                <Table.Cell className={"noBorder"} textAlign={"right"}>
                    {formatFinancialNumbers(totalBrutto, companyLocale)}
                </Table.Cell>
            </Table.Row>
        </React.Fragment>
    );
};

const printSalesPerOnlineMeansOfSale = (report: OnlineSalesReportType, companyLocale: CompanyLocale) => {
    return (
        <>
            <Table.Row>
                <Table.Cell colSpan={3} className={"zReportHeader noBorder"}>
                    Försäljning per online försäljningsmedel
                </Table.Cell>
            </Table.Row>
            {printReportOnlineChannelSales(report.onlineChannelSales, companyLocale)}
            {printTotalForGroup(
                report.onlineChannelSales,
                report.refundTotalVatAmount,
                report.vatRatesAndAmounts,
                companyLocale
            )}
        </>
    );
};

const printSalesPerPaymentMethod = (
    groupHeader: string,
    paymentMethodAndAmounts: PaymentMethodAmountWithTip[],
    cardAmounts: CardAmountsType[],
    companyLocale: CompanyLocale,
    refundTotalVatAmount: number,
    vatRatesAndAmounts: VatRatesAndAmountsWithRefundsType[],
    report: XZReportType & OptionalOnlineReportProps
) => {
    if (!paymentMethodAndAmounts?.length) return <></>;

    /**
     * This is support for previously generated reports which don't have a `paymentMethodAndAmounts.$.tip` field
     */
    if (report.tip > 0) {
        // Find the CARD payment total
        const cardPaymentMethodAndAmount = paymentMethodAndAmounts.find(
            paymentMethodAndAmount => paymentMethodAndAmount.paymentMethod == PaymentMethod.CARD
        );

        // Set the tip field of the CARD payment total to calculated amount
        if (!!cardPaymentMethodAndAmount && !cardPaymentMethodAndAmount?.tip) {
            // We assume, for this use case that all tips are CARD tips, from all card types.
            cardPaymentMethodAndAmount.tip = report.tip;
        }
    }

    return (
        <>
            <Table.Row>
                <Table.Cell colSpan={3} className={"zReportHeader noBorder"}>
                    {groupHeader}
                </Table.Cell>
            </Table.Row>
            {printTotalForGroup(paymentMethodAndAmounts, refundTotalVatAmount, vatRatesAndAmounts, companyLocale)}
            {printReportPaymentMethods(paymentMethodAndAmounts, cardAmounts, companyLocale, report.roundedCashDecimals)}
        </>
    );
};

const printTotalForGroup = (
    paymentMethodAndAmounts: PaymentMethodAmountWithTip[] | OnlineChannelSalesType[],
    refundTotalVatAmount: number,
    vatRatesAndAmounts: VatRatesAndAmountsWithRefundsType[],
    companyLocale: CompanyLocale
) => {
    const { totalNetto, vatAmount } = calculateTotalNettoAndVAT(
        paymentMethodAndAmounts,
        vatRatesAndAmounts,
        refundTotalVatAmount
    );

    // must do null check
    const showTotalSaleNet = refundTotalVatAmount !== null;
    return (
        <>
            <Table.Row>
                <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                    Totalt Bruttoförsäljning
                </Table.Cell>
                <Table.Cell className={"noBorder"} textAlign={"right"}>
                    {formatFinancialNumbers(totalNetto + vatAmount, companyLocale)}
                </Table.Cell>
            </Table.Row>
            {showTotalSaleNet && (
                <Table.Row>
                    <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                        Totalt Nettoförsäljning
                    </Table.Cell>
                    <Table.Cell className={"noBorder"} textAlign={"right"}>
                        {formatFinancialNumbers(totalNetto, companyLocale)}
                    </Table.Cell>
                </Table.Row>
            )}
        </>
    );
};

type OptionalOnlineReportProps = Partial<OnlineSalesReportType>;

type ReportViewerProps = {
    report: XZReportType & OptionalOnlineReportProps;
};

export const ReportViewer = forwardRef<HTMLDivElement, ReportViewerProps>((props, ref) => {
    const { report } = props;
    if (!report) return <Box bg="gray.200" h="75vh" w="100%" border="1px solid #E2E8F0" rounded={"lg"} ref={ref} />;

    const { companyLocale } = useQoplaStore();

    const reportHeader = report.reportType === reportTypeConstants.ONLINE ? "Online" : report.reportType;

    const isNotOnlineReport = report.reportType !== reportTypeConstants.ONLINE;
    const shouldShowTotalIncludeRefundsSection = isNotOnlineReport && report.vatRateAmountWithRefunds;
    const refundTotalVatAmountOnline = report.vatRateAmountWithRefundsOnline
        ? report.vatRateAmountWithRefundsOnline.reduce((acc, next) => acc + next.refundedAmount!, 0)
        : 0;

    return (
        <Box ref={ref} width="100%">
            {/** @ts-ignore */}
            <Table
                className={"reportViewer"}
                striped
                compact
                style={{ border: "1px solid #6b6b6b", padding: "20px", fontSize: "0.9em" }}
            >
                <Table.Body>
                    <Table.Row>
                        {isNotOnlineReport ? (
                            <Table.Cell>
                                {report.reportType === reportTypeConstants.Z && (
                                    <Text style={{ fontWeight: "bold" }}>Z: {report.reportNumber}</Text>
                                )}
                            </Table.Cell>
                        ) : (
                            <Table.Cell>
                                <Text style={{ fontWeight: "bold" }}>Online rapport: {report.reportNumber}</Text>
                            </Table.Cell>
                        )}
                        <Table.Cell />
                        {isNotOnlineReport && (
                            <Table.Cell textAlign={"right"} style={{ fontSize: "11px" }}>
                                Genererad av: {report.createdBy}
                                <br />
                                {report.posName}
                            </Table.Cell>
                        )}
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colSpan={3} textAlign={"center"}>
                            <Header size={"xl"} mb={0}>
                                {reportHeader}-dagrapport
                            </Header>
                            {report.dateIntervalReport ? (
                                <React.Fragment>
                                    <Header size={"md"} mb={0}>
                                        Datumintervallrapport
                                    </Header>
                                    {formatDateToLocal(report.startDate)} - {formatDateToLocal(report.endDate)}
                                </React.Fragment>
                            ) : (
                                formatDateToLocal(report.createdAt)
                            )}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className={"noBorder"} colSpan={3} textAlign={"center"}>
                            <Header size={"lg"} mb={0}>
                                {report.shopName}
                            </Header>
                            Org nr: {report.organisationNumber}
                        </Table.Cell>
                    </Table.Row>
                    {isNotOnlineReport ? (
                        <>
                            {printSalesPerPaymentMethod(
                                "Försäljning per betalsätt kassa",
                                report.paymentMethodAndAmounts,
                                report.cardAmounts,
                                companyLocale,
                                report.refundTotalVatAmount,
                                report.vatRateAmountWithRefunds ?? report.vatRatesAndAmounts,
                                report
                            )}
                            {printSalesPerPaymentMethod(
                                "Försäljning per betalsätt online",
                                report.paymentMethodAndAmountsOnline,
                                report.cardAmountsOnline,
                                companyLocale,
                                refundTotalVatAmountOnline,
                                report.vatRateAmountWithRefundsOnline,
                                report
                            )}
                        </>
                    ) : (
                        printSalesPerOnlineMeansOfSale(
                            {
                                onlineChannelSales: report.onlineChannelSales || [],
                                refundTotalVatAmount: report.refundTotalVatAmount,
                                vatRatesAndAmounts: report.vatRatesAndAmounts
                            } as OnlineSalesReportType,
                            companyLocale
                        )
                    )}

                    {printCategories(report, companyLocale)}
                    {shouldShowTotalIncludeRefundsSection && printTotalInclRefunds(report, companyLocale)}
                    {printTotal(report, companyLocale, "Totalt (exkl returer)", "")}
                    {!report.dateIntervalReport && isNotOnlineReport && (
                        <React.Fragment>
                            <Table.Row>
                                <Table.Cell colSpan={3} className={"zReportHeader"}>
                                    Växelkassa
                                </Table.Cell>
                            </Table.Row>
                            {report.posIngoingCashChange.map(cash => (
                                <Table.Row key={cash.posId}>
                                    <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                                        {cash.posName}
                                    </Table.Cell>
                                    <Table.Cell className={"noBorder"} textAlign={"right"}>
                                        {formatFinancialNumbers(cash.amount, companyLocale)}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </React.Fragment>
                    )}
                    <Table.Row>
                        <Table.Cell colSpan={3} className={"zReportHeader"}>
                            Övrigt
                        </Table.Cell>
                    </Table.Row>
                    {isNotOnlineReport && (
                        <>
                            <Table.Row>
                                <Table.Cell className={"zReportPadded noBorder"}>Antal sålda varor</Table.Cell>
                                <Table.Cell className={"noBorder"} textAlign={"right"}>
                                    {report.sumSoldProducts} st
                                </Table.Cell>
                                <Table.Cell className={"noBorder"} />
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                                    Rabatter
                                </Table.Cell>
                                <Table.Cell className={"noBorder"} textAlign={"right"}>
                                    {formatFinancialNumbers(report.discounts.amount, companyLocale)}
                                </Table.Cell>
                            </Table.Row>
                        </>
                    )}
                    {!isNotOnlineReport && (
                        <>
                            {!!report.tip && (
                                <Table.Row>
                                    <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                                        Dricks
                                    </Table.Cell>
                                    <Table.Cell className={"noBorder"} textAlign={"right"}>
                                        {formatFinancialNumbers(report.tip, companyLocale)}
                                    </Table.Cell>
                                </Table.Row>
                            )}
                            {!!report.redeemedGiftCardAmount && (
                                <Table.Row>
                                    <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                                        Inlöst presentkortsbelopp
                                    </Table.Cell>
                                    <Table.Cell className={"noBorder"} textAlign={"right"}>
                                        {formatFinancialNumbers(report.redeemedGiftCardAmount, companyLocale)}
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </>
                    )}
                    <Table.Row>
                        <Table.Cell className={"zReportPadded noBorder"}>Returer</Table.Cell>
                        <Table.Cell className={"noBorder"} textAlign={"right"}>
                            {report.refunds.count} st
                        </Table.Cell>
                        <Table.Cell className={"noBorder"} textAlign={"right"}>
                            {formatFinancialNumbers(report.refunds.amount, companyLocale)}
                        </Table.Cell>
                    </Table.Row>

                    {isNotOnlineReport && (
                        <>
                            <Table.Row>
                                <Table.Cell className={"zReportPadded noBorder"}>Kassakvitton</Table.Cell>
                                <Table.Cell className={"noBorder"} textAlign={"right"}>
                                    {report.sumReceipts} st
                                </Table.Cell>
                                <Table.Cell className={"noBorder"} />
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"zReportPadded noBorder"}>Kvittokopior</Table.Cell>
                                <Table.Cell className={"noBorder"} textAlign={"right"}>
                                    {report.copies.count} st
                                </Table.Cell>
                                <Table.Cell className={"noBorder"} textAlign={"right"}>
                                    {formatFinancialNumbers(report.copies.amount, companyLocale)}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"zReportPadded noBorder"}>Övningskvitton</Table.Cell>
                                <Table.Cell className={"noBorder"} textAlign={"right"}>
                                    {report.tests.count} st
                                </Table.Cell>
                                <Table.Cell className={"noBorder"} textAlign={"right"}>
                                    {formatFinancialNumbers(report.tests.amount, companyLocale)}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"zReportPadded  noBorder"}>Oavslutade notor</Table.Cell>
                                <Table.Cell className={"noBorder"} textAlign={"right"}>
                                    {report.unfinished.count} st
                                </Table.Cell>
                                <Table.Cell className={"noBorder"} textAlign={"right"}>
                                    {formatFinancialNumbers(report.unfinished.amount, companyLocale)}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"zReportPadded  noBorder"}>Lådöppningar</Table.Cell>
                                <Table.Cell className={"noBorder"} textAlign={"right"}>
                                    {report.sumOpenedRegister} st
                                </Table.Cell>
                                <Table.Cell className={"noBorder"} />
                            </Table.Row>
                        </>
                    )}
                    {!report.dateIntervalReport && isNotOnlineReport && (
                        <React.Fragment>
                            <Table.Row>
                                <Table.Cell colSpan={3} className={"zReportHeader"}>
                                    Grand Total
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"zReportPadded  noBorder"} colSpan={2}>
                                    Grand Total Nettoförsäljning
                                </Table.Cell>
                                <Table.Cell className={"noBorder"} textAlign={"right"}>
                                    {formatFinancialNumbers(report.grandTotalNet, companyLocale)}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"zReportPadded  noBorder"} colSpan={2}>
                                    Grand Total Retur
                                </Table.Cell>
                                <Table.Cell className={"noBorder"} textAlign={"right"}>
                                    {formatFinancialNumbers(report.grandTotalRefund, companyLocale)}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"zReportPadded noBorder"} colSpan={2}>
                                    Grand Total
                                </Table.Cell>
                                <Table.Cell className={"noBorder"} textAlign={"right"}>
                                    {formatFinancialNumbers(report.grandTotalSales, companyLocale)}
                                </Table.Cell>
                            </Table.Row>
                        </React.Fragment>
                    )}
                    {report.cashInvoicesExists && isNotOnlineReport && (
                        <Table.Row>
                            <Table.Cell
                                colSpan={3}
                                className={"zReportHeader"}
                                textAlign={"center"}
                                style={{ fontWeight: "bold" }}
                            >
                                <span style={{ fontSize: "1.1em" }}>
                                    OBS! Kontantfakturor har skapats under samma period men är ej sammanställda här.
                                </span>
                                <br />
                                Gå till menyalternativet Kontantfakturor för att se dessa.
                            </Table.Cell>
                        </Table.Row>
                    )}
                    {report.invoicePaymentExists && isNotOnlineReport && (
                        <Table.Row>
                            <Table.Cell
                                colSpan={3}
                                className={"zReportHeader"}
                                textAlign={"center"}
                                style={{ fontWeight: "bold" }}
                            >
                                <Box as="span" style={{ fontSize: "1.1em" }}>
                                    OBS! Fakturor har skapats under samma period men är ej sammanställda här.
                                </Box>
                                <br />
                                <Box as="span">
                                    Vänligen gå till
                                    <Link as={RLink as LinkType} to="/admin/invoices" mx={1} color="gray.700">
                                        Fakturaunderlag
                                    </Link>
                                    för att se en översikt
                                </Box>
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
            <Box as="span" className={"poweredBy"} style={{ fontSize: "9px", color: "#a8a8a8", display: "none" }}>
                Powered by qopla
            </Box>
        </Box>
    );
});
