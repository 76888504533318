import gql from "graphql-tag";

import { ACTIVE_HOUR_ATTRIBUTES } from "./sharedFragments";

export const ALLERGEN_ATTRIBUTES = gql`
    fragment allergenResults on Allergen {
        id
        name
        desc
        disabled
        sortOrder
        createdAt
        deleted
        updatedAt
        __typename
    }
`;

export const MODIFICATION_ATTRIBUTES = gql`
    fragment modificationResults on Modification {
        addonPrice
        name
        price
        __typename
    }
`;

export const MODIFICATIONS_ATTRIBUTES = gql`
    fragment modificationsResults on Modifications {
        flavours {
            ... on Modification {
                ...modificationResults
            }
        }
        sizes {
            ... on Modification {
                ...modificationResults
            }
        }
        other {
            ... on Modification {
                ...modificationResults
            }
        }
        types {
            ... on Modification {
                ...modificationResults
            }
        }
        __typename
    }
    ${MODIFICATION_ATTRIBUTES}
`;

export const INGREDIENT_ATTRIBUTES = gql`
    fragment ingredientResults on Ingredient {
        name
        price
        quantity
        __typename
    }
`;

export const RESIZE_IMAGE_ATTRIBUTES = gql`
    fragment imageResizedUrlsResults on ImageResizedUrls {
        small
        medium
        large
        __typename
    }
`;

export const REF_PRODUCT_TAGS_ATTRIBUTES = gql`
    fragment productTagsResults on ProductTags {
        alcoholTag
        volumeInCl
        __typename
    }
`;

export const REF_PRODUCT_ATTRIBUTES = gql`
    fragment refProductResults on RefProduct {
        allergens {
            ... on Allergen {
                ...allergenResults
            }
        }
        articleNumber
        companyId
        defaultPrice
        deleted
        description
        contents
        disabled
        id
        imageUrl
        imageResizedUrls {
            ... on ImageResizedUrls {
                ...imageResizedUrlsResults
            }
        }
        ingredients {
            ... on Ingredient {
                ...ingredientResults
            }
        }
        modifications {
            ... on Modifications {
                ...modificationsResults
            }
        }
        name
        priceType
        refProductCategoryId
        vatRate
        productTags {
            ... on ProductTags {
                ...productTagsResults
            }
        }
        __typename
    }
    ${ALLERGEN_ATTRIBUTES}
    ${INGREDIENT_ATTRIBUTES}
    ${MODIFICATIONS_ATTRIBUTES}
    ${RESIZE_IMAGE_ATTRIBUTES}
    ${REF_PRODUCT_TAGS_ATTRIBUTES}
`;

export const BUNDLE_PRODUCT_CATEGORIES = gql`
    fragment bundleProductCategoryResults on BundleProductCategory {
        color
        id
        kdsUnitDisplayName
        limit
        name
        refProductIdList
        sortOrder
        __typename
    }
`;

export const REF_BUNDLE_PRODUCT_ATTRIBUTES = gql`
    fragment refBundleProductResults on RefBundleProduct {
        allergens {
            ... on Allergen {
                ...allergenResults
            }
        }
        articleNumber
        bundleProductCategories {
            ... on BundleProductCategory {
                ...bundleProductCategoryResults
            }
        }
        companyId
        defaultPrice
        deleted
        description
        contents
        disabled
        id
        imageUrl
        imageResizedUrls {
            ... on ImageResizedUrls {
                ...imageResizedUrlsResults
            }
        }
        name
        priceType
        refProductCategoryId
        vatRate
        productTags {
            ... on ProductTags {
                ...productTagsResults
            }
        }
        __typename
    }
    ${ALLERGEN_ATTRIBUTES}
    ${BUNDLE_PRODUCT_CATEGORIES}
    ${RESIZE_IMAGE_ATTRIBUTES}
    ${REF_PRODUCT_TAGS_ATTRIBUTES}
`;

export const MENU_PRODUCT_ATTRIBUTES = gql`
    fragment menuProductResults on MenuProduct {
        id
        activeHours {
            ... on ActiveHour {
                ...activeHourResults
            }
        }
        color
        modifications {
            ... on Modifications {
                ...modificationsResults
            }
        }
        price
        refProduct {
            ... on RefProduct {
                ...refProductResults
            }
        }
        sortOrder
        __typename
    }
    ${ACTIVE_HOUR_ATTRIBUTES}
    ${MODIFICATIONS_ATTRIBUTES}
    ${REF_PRODUCT_ATTRIBUTES}
`;

export const MENU_BUNDLE_PRODUCT_ATTRIBUTES = gql`
    fragment menuBundleProductResults on MenuBundleProduct {
        id
        bundleCategoryColors {
            bundleProductCategoryId
            color
        }
        activeHours {
            ... on ActiveHour {
                ...activeHourResults
            }
        }
        color
        menuBundleModifications {
            refProductId
            modifications {
                ... on Modifications {
                    ...modificationsResults
                }
            }
        }
        price
        refBundleProduct {
            ... on RefBundleProduct {
                ...refBundleProductResults
            }
        }
        sortOrder
        __typename
    }
    ${ACTIVE_HOUR_ATTRIBUTES}
    ${REF_BUNDLE_PRODUCT_ATTRIBUTES}
    ${MODIFICATION_ATTRIBUTES}
`;

// Unused? Can we remove? It could be problematic to maintain this copy of ORDER_PRODUCT_ATTRIBUTES
export const ORDER_PRODUCT_ATTRIBUTES_WITH_ID = gql`
    fragment orderProductResultsWithId on OrderProduct {
        id
        name
        refProductId
        refBundleProductId
        refProductCategoryId
        imageUrl
        unitPrice
        totalPrice
        totalNetPrice
        priceType
        quantity
        weight
        vatRate
        discountIds
        shopId
        menuCategoryId
        isStockTracked
        isOpenProduct
        discountValue
        discountRate
        comment
        combinedDiscounts {
            discountId
            name
            code
            discountValue
            discountRate
            discountedFrom
            quantityUsedForDiscount
            discountType
            discountOrder
        }
        addons {
            quantity
            name
            price
        }
        selectedBundleProductItems {
            name
            refProductId
            refProductCategoryId
            kdsUnitDisplayName
            bundleProductCategoryId
            modifications {
                sizes {
                    name
                    price
                    addonPrice
                }
                flavours {
                    name
                    price
                    addonPrice
                }
            }
            addons {
                groupId
                quantity
                name
                price
            }
        }
        modifications {
            sizes {
                name
                price
                addonPrice
            }
            flavours {
                name
                price
                addonPrice
            }
        }
        upsell
        __typename
    }
`;

export const ORDER_PRODUCT_ATTRIBUTES = gql`
    fragment orderProductResults on OrderProduct {
        name
        refProductId
        refBundleProductId
        refProductCategoryId
        imageUrl
        unitPrice
        totalPrice
        totalNetPrice
        priceType
        quantity
        weight
        vatRate
        discountIds
        menuCategoryId
        isStockTracked
        isOpenProduct
        comment
        isLastOrderProduct
        combinedDiscounts {
            discountId
            name
            code
            discountValue
            discountRate
            discountedFrom
            discountOrder
        }
        addons {
            quantity
            name
            price
        }
        selectedBundleProductItems {
            name
            refProductId
            kdsUnitDisplayName
            bundleProductCategoryId
            refProductCategoryId
            modifications {
                sizes {
                    name
                    price
                    addonPrice
                }
                flavours {
                    name
                    price
                    addonPrice
                }
            }
            addons {
                quantity
                name
                price
            }
        }
        modifications {
            sizes {
                name
                price
                addonPrice
            }
            flavours {
                name
                price
                addonPrice
            }
        }
        upsell
        __typename
    }
`;

export const LAST_ORDER_PRODUCT_ATTRIBUTES = gql`
    fragment lastOrderDTOResults on LastOrderDTO {
        orderId
        purchaseDate
        lastOrderProducts {
            name
            menuIds
            refProductId
            menuCategoryId
            refBundleProductId
            refProductCategoryId
            addons {
                quantity
                name
                price
            }
            selectedBundleProductItems {
                name
                refProductId
                kdsUnitDisplayName
                bundleProductCategoryId
                refProductCategoryId
                modifications {
                    sizes {
                        name
                        price
                        addonPrice
                    }
                    flavours {
                        name
                        price
                        addonPrice
                    }
                }
                addons {
                    quantity
                    name
                    price
                }
            }
            modifications {
                sizes {
                    name
                    price
                    addonPrice
                }
                flavours {
                    name
                    price
                    addonPrice
                }
            }
            quantity
            comment
            subscriptionProduct
            upsell
            totalPrice
        }
        __typename
    }
`;

export const CART_PRODUCT_ATTRIBUTES = gql`
    fragment cartProductResults on CartProduct {
        id
        menuProduct {
            ... on MenuProduct {
                ...menuProductResults
            }
        }
        menuBundleProduct {
            ... on MenuBundleProduct {
                ...menuBundleProductResults
            }
        }
        oldId
        isFinished
        orderProduct {
            ... on OrderProduct {
                ...orderProductResultsWithId
            }
        }
        updatedUnitPrice
        __typename
    }
    ${MENU_PRODUCT_ATTRIBUTES}
    ${MENU_BUNDLE_PRODUCT_ATTRIBUTES}
    ${ORDER_PRODUCT_ATTRIBUTES_WITH_ID}
`;
