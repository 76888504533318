import jsPDF from "jspdf";
import { capitalize } from "lodash";

import {
    Modifications,
    Modification,
    CompanyLocale,
    ServiceFee,
    Order,
    OrderType,
    InvoiceOrder,
    VatRateAmount
} from "Types";
import {
    GLOBAL_TIP_PRODUCT_NAME,
    PaymentMethod,
    reportTypeConstants,
    THIRD_PARTY_DELIVERY_TYPE_IN_SWEDISH
} from "Constants";
import { getTotalAddonPrice } from "../../../utils/newPriceHelpers";
import { formatDateToLocal, printModificatonNames, hasModifications } from "TempUtils";
import { getOrderVatRates, getSelectedModificationAddonPriceFromBackend, mathSign } from "../poses/pos/utils";
import { applyServiceFeeVatToVatRatesAndAmounts, formatFinancialNumbers } from "PriceUtils";
import {
    OnlineChannelSalesType,
    VatRatesAndAmountsType,
    XZReportType,
    OnlineSalesReportType,
    VatRatesAndAmountsWithRefundsType,
    PaymentMethodAmountWithTip,
    CardAmountsType
} from "./reportTypes";
import { calculateTotalNettoAndVAT } from "./ReportViewer";
import { swedishPaymentMethod } from "Utils";
import "./Lato-Regular-normal";
import { TranslateFunc } from "Providers";

//style
const mergeLeftSubTitle = 50;
const mergeLeft = 55;
const mergeRight = 550;
const center = 300;
const subFontStyle = "bold";
const fontName = "SansSerif";
const invoicePDFFont = "helvetica";
const fontStyle = "normal";
const column_1 = 100;
const column_2 = 200;
const column_3 = 350;
const column_4 = 500;
const year = new Date().getFullYear();

type CardAmount = {
    amount: number;
    cardType: string;
    tip: number;
};

export const reportPDFGenerator = (report: any, companyLocale: CompanyLocale) => {
    const doc = new jsPDF("p", "pt");
    const pageHeight = doc.internal.pageSize.height;

    let yLine = 20;
    const setYLine = (y: number) => {
        yLine = y;
        if (yLine >= pageHeight - 40) {
            doc.addPage();
            yLine = 40; // Restart height position
        }
    };
    let bg = false;
    const setBg = () => {
        bg = !bg;
    };
    const background = (h: number, reset?: boolean) => {
        setYLine(yLine + 5);
        doc.setFillColor(bg || reset ? "#fff" : "#fafafb");
        doc.rect(mergeLeftSubTitle, yLine, 500, yLine + h, "F");
        setBg();
    };

    const timeInterval = report.dateIntervalReport
        ? `${formatDateToLocal(report.startDate)} - ${formatDateToLocal(report.endDate)}`
        : null;

    const isNotOnlineReport = report.reportType !== reportTypeConstants.ONLINE;
    const shouldShowTotalIncludeRefundsSection = isNotOnlineReport && report.vatRateAmountWithRefunds;

    const printPaymentMethod = (paymentMethod: string, amount: number) => {
        doc.text(paymentMethod, mergeLeft, yLine);
        doc.text(`${formatFinancialNumbers(amount, companyLocale)}`, mergeRight, yLine, { align: "right" });
    };

    const printTipAmount = (tip: number) => {
        setYLine(yLine + 15);
        doc.text(`  - varav dricks`, 55, yLine);
        doc.text(`${formatFinancialNumbers(tip, companyLocale)}`, mergeRight - 3, yLine, { align: "right" });
    };

    const printCardTypes = (payAmount: PaymentMethodAmountWithTip, cardAmounts: CardAmountsType[]) => {
        /**
         * TODO: In order for this to function well in the future, we should filter on `next.cardType == AMEX`
         * And we should type the `CardType` to something other than `string`
         *
         * The danger of not doing this, is if `cardAmounts` is changed to include cardAmounts other than AMEX, we will incidentally treat NON-AMEX amounts as AMEX
         *
         * WARNING: Don't forget to update ReportViewer when changing this code.
         */
        const AMEXCardTipAmount = cardAmounts.reduce((acc: number, next: CardAmount) => {
            return (acc += next.tip);
        }, 0);

        const AMEXCardAmount = cardAmounts.reduce((acc: number, next: CardAmount) => {
            return (acc += next.amount);
        }, 0);

        const NONAMEXCardAmount = payAmount.amount - AMEXCardAmount;
        const NONAMEXCardTipAmount = payAmount.tip - AMEXCardTipAmount;

        setYLine(yLine + 15);
        printPaymentMethod(" - MC / Visa", NONAMEXCardAmount);
        if (NONAMEXCardTipAmount > 0) {
            printTipAmount(NONAMEXCardTipAmount);
        }

        cardAmounts.forEach((cardAmount: any) => {
            setYLine(yLine + 15);
            printPaymentMethod(` - ${capitalize(cardAmount.cardType)}`, cardAmount.amount);
            if (cardAmount.tip > 0) {
                printTipAmount(cardAmount.tip);
            }
        });
    };

    const printCardPayments = (payAmount: PaymentMethodAmountWithTip, cardAmounts: CardAmountsType[]) => {
        const hasOtherCardTypes = cardAmounts && cardAmounts.length > 0;
        if (hasOtherCardTypes) {
            printPaymentMethod("Total kortbetalning", payAmount.amount);
            printCardTypes(payAmount, cardAmounts);
        } else {
            printPaymentMethod(swedishPaymentMethod[payAmount.paymentMethod].swe, payAmount.amount);
            if (payAmount.tip > 0) {
                printTipAmount(payAmount.tip);
            }
        }
    };

    const printOnlineSalesChannels = (report: OnlineSalesReportType, companyLocale: CompanyLocale) => {
        const totalSaleIncludingRefunds = report.onlineChannelSales.reduce(
            (acc: number, next: OnlineChannelSalesType) => acc + next.amount,
            0
        );
        return (
            doc.setFont(fontName, subFontStyle),
            doc.setFontSize(13),
            background(5),
            setYLine(yLine + 25),
            doc.text("Försäljning per betalsätt", mergeLeftSubTitle, yLine),
            doc.setFont(fontName, fontStyle),
            doc.setFontSize(12),
            report.onlineChannelSales.map((onlineChannel: OnlineChannelSalesType) => {
                printOnlineSalesChannel(onlineChannel);
            }),
            printGroupTotal(
                totalSaleIncludingRefunds,
                report.vatRatesAndAmounts,
                report.refundTotalVatAmount,
                companyLocale
            )
        );
    };

    const printOnlineSalesChannel = (onlineSalesChannel: OnlineChannelSalesType) => {
        const channelTitle =
            onlineSalesChannel.orderType === "WEB"
                ? "Qopla online"
                : THIRD_PARTY_DELIVERY_TYPE_IN_SWEDISH[onlineSalesChannel.orderType];
        return background(5), setYLine(yLine + 15), printPaymentMethod(channelTitle, onlineSalesChannel.amount);
    };

    const printPaymentMethods = (
        headerText: string,
        paymentMethodAndAmounts: PaymentMethodAmountWithTip[],
        cardAmounts: CardAmountsType[],
        vatRatesAndAmounts: VatRatesAndAmountsType[],
        refundTotalVatAmount: number,
        report: XZReportType,
        companyLocale: CompanyLocale
    ) => {
        if (!paymentMethodAndAmounts?.length) return null;
        const totalSaleIncludingRefunds = paymentMethodAndAmounts.reduce(
            (acc: number, next: PaymentMethodAmountWithTip) => acc + next.amount - (next.tip || 0),
            0
        );

        return (
            doc.setFont(fontName, subFontStyle),
            doc.setFontSize(13),
            background(5),
            setYLine(yLine + 25),
            doc.text(headerText, mergeLeftSubTitle, yLine),
            doc.setFont(fontName, fontStyle),
            doc.setFontSize(12),
            printGroupTotal(totalSaleIncludingRefunds, vatRatesAndAmounts, refundTotalVatAmount, companyLocale),
            paymentMethodAndAmounts.map((payAmount: PaymentMethodAmountWithTip) => {
                background(15);
                setYLine(yLine + 15);

                if (payAmount.paymentMethod === PaymentMethod.CARD) {
                    printCardPayments(payAmount, cardAmounts);
                } else {
                    printPaymentMethod(swedishPaymentMethod[payAmount.paymentMethod].swe, payAmount.amount);
                    payAmount.tip > 0 && printTipAmount(payAmount.tip);
                }

                payAmount.paymentMethod === PaymentMethod.CASH &&
                    report.roundedCashDecimals !== 0 &&
                    (background(5),
                    setYLine(yLine + 15),
                    doc.text(`- Öresavrundning`, mergeLeftSubTitle, yLine),
                    doc.text(
                        `${formatFinancialNumbers(report.roundedCashDecimals, companyLocale)}`,
                        mergeRight,
                        yLine,
                        {
                            align: "right"
                        }
                    ));
            })
        );
    };

    const printGroupTotal = (
        totalSaleIncludingRefunds: number,
        vatRatesAndAmounts: VatRatesAndAmountsType[],
        refundTotalVatAmount: number,
        companyLocale: CompanyLocale
    ) => {
        const vatAmount = vatRatesAndAmounts.reduce(
            (acc: number, next: VatRatesAndAmountsType) => acc + next.amount,
            0
        );
        // is negative number
        const _refundTotalVatAmount = refundTotalVatAmount ? refundTotalVatAmount : 0;
        const totalSaleIncludingRefundsNet = totalSaleIncludingRefunds - vatAmount - _refundTotalVatAmount;
        const showTotalSaleNet = report.refundTotalVatAmount !== null;
        return (
            showTotalSaleNet &&
                (background(5),
                setYLine(yLine + 15),
                doc.text("Totalt Nettoförsäljning", mergeLeft, yLine),
                doc.text(`${formatFinancialNumbers(totalSaleIncludingRefundsNet, companyLocale)}`, mergeRight, yLine, {
                    align: "right"
                })),
            background(5),
            setYLine(yLine + 15),
            doc.text("Totalt Bruttoförsäljning", mergeLeft, yLine),
            doc.text(`${formatFinancialNumbers(totalSaleIncludingRefunds, companyLocale)}`, mergeRight, yLine, {
                align: "right"
            })
        );
    };

    const printVatRates = (
        vatRatesAndAmounts: VatRatesAndAmountsWithRefundsType[],
        companyLocale: CompanyLocale,
        shouldIncludesRefunds: boolean,
        isOnline = false
    ) => {
        const sourceText = isOnline ? "Online Moms " : "Kassa Moms ";
        return vatRatesAndAmounts.map((vatAmount: any) => {
            // is negative number or 0
            const refundedAmount = vatAmount.refundedAmount && shouldIncludesRefunds ? vatAmount.refundedAmount : 0;
            return (
                background(5),
                setYLine(yLine + 15),
                doc.text(`${sourceText} ${vatAmount.vatRate}%`, mergeLeft, yLine),
                doc.text(
                    `${formatFinancialNumbers(vatAmount.amount + refundedAmount, companyLocale)}`,
                    mergeRight,
                    yLine,
                    {
                        align: "right"
                    }
                )
            );
        });
    };

    const printTotal = (report: any, companyLocale: CompanyLocale) => {
        const deprecatedReportTipSum = report.tip ?? 0;

        const totalSalesOnline = report.totalSalesOnline ?? 0;
        const vatRatesAmounts = report.vatRateAmountWithRefunds ?? report.vatRatesAndAmounts;
        return (
            doc.setFont(fontName, fontStyle),
            doc.setFontSize(12),
            background(5),
            setYLine(yLine + 15),
            doc.text("Totalt Kassa Nettoförsäljning", mergeLeft, yLine),
            doc.text(
                // Does not include refunds, also this is POS only
                `${formatFinancialNumbers(report.totalNetSales - deprecatedReportTipSum, companyLocale)}`,
                mergeRight,
                yLine,
                {
                    align: "right"
                }
            ),
            printVatRates(vatRatesAmounts, companyLocale, false, false),
            totalSalesOnline &&
                (background(5),
                setYLine(yLine + 15),
                doc.text("Totalt Online Nettoförsäljning", mergeLeft, yLine),
                // We do not subtract the deprecated `report.tip` field because it was not summed into the totalNetSalesOnline field
                // In fact, online tips were not included in the ZReport at all
                doc.text(`${formatFinancialNumbers(report.totalNetSalesOnline, companyLocale)}`, mergeRight, yLine, {
                    align: "right"
                }),
                printVatRates(report.vatRateAmountWithRefundsOnline, companyLocale, false, true)),
            background(5),
            setYLine(yLine + 15),
            doc.text("Totalt Bruttoförsäljning (exkl returer)", mergeLeft, yLine),
            doc.text(
                `${formatFinancialNumbers(
                    report.totalNetSales +
                        vatRatesAmounts.reduce((acc: any, next: any) => acc + next.amount, 0) +
                        totalSalesOnline -
                        deprecatedReportTipSum,
                    companyLocale
                )}`,
                mergeRight,
                yLine,
                { align: "right" }
            )
        );
    };

    // shows only in reports with vatRateAmountWithRefunds
    const printTotalInclRefunds = (report: any, companyLocale: CompanyLocale) => {
        const totalSalesOnline = report.totalSalesOnline ?? 0;

        const { totalNetto, vatAmount } = calculateTotalNettoAndVAT(
            report.paymentMethodAndAmounts,
            report.vatRateAmountWithRefunds
        );
        const { totalNetto: totalNettoOnline, vatAmount: vatAmountOnline } = calculateTotalNettoAndVAT(
            report.paymentMethodAndAmountsOnline,
            report.vatRateAmountWithRefundsOnline
        );
        const totalBrutto = totalNetto + vatAmount + totalNettoOnline + vatAmountOnline;
        return (
            doc.setFont(fontName, fontStyle),
            doc.setFontSize(12),
            background(5),
            setYLine(yLine + 15),
            doc.text("Totalt Kassa Nettoförsäljning", mergeLeft, yLine),
            doc.text(`${formatFinancialNumbers(totalNetto, companyLocale)}`, mergeRight, yLine, {
                align: "right"
            }),
            printVatRates(report.vatRateAmountWithRefunds, companyLocale, true, false),
            totalSalesOnline &&
                (background(5),
                setYLine(yLine + 15),
                doc.text("Totalt Online Nettoförsäljning", mergeLeft, yLine),
                doc.text(`${formatFinancialNumbers(totalNettoOnline, companyLocale)}`, mergeRight, yLine, {
                    align: "right"
                }),
                printVatRates(report.vatRateAmountWithRefundsOnline, companyLocale, true, true)),
            background(5),
            setYLine(yLine + 15),
            doc.text("Totalt Bruttoförsäljning", mergeLeft, yLine),
            doc.text(`${formatFinancialNumbers(totalBrutto, companyLocale)}`, mergeRight, yLine, { align: "right" })
        );
    };

    const refundTotalVatAmountOnline = report.vatRateAmountWithRefundsOnline
        ? report.vatRateAmountWithRefundsOnline.reduce(
              (acc: number, next: VatRatesAndAmountsWithRefundsType) => acc + next.refundedAmount!,
              0
          )
        : 0;

    return (
        // add some text to pdf document
        doc.setFont(fontName),
        doc.setFontSize(11),
        isNotOnlineReport &&
            (doc.text(`Genererad av: ${report.createdBy}`, mergeRight, yLine, { align: "right" }),
            setYLine(yLine + 10),
            doc.text(`${report.posName}`, mergeRight, yLine, { align: "right" })),
        doc.text(`${capitalize(report.reportType)}: ${report.reportNumber}`, mergeLeftSubTitle, yLine),
        setYLine(yLine + 5),
        doc.setDrawColor("#e4e4e5"),
        doc.setLineWidth(0.5),
        doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
        doc.setFontSize(28),
        setYLine(yLine + 25),
        doc.text(`${capitalize(report.reportType)}-dagrapport`, center, yLine, { align: "center" }),
        doc.setFontSize(12),
        report.dateIntervalReport
            ? (setYLine(yLine + 20),
              doc.text(`Datumintervallrapport`, center, yLine, { align: "center" }),
              setYLine(yLine + 15),
              doc.text(`${timeInterval}`, center, yLine, { align: "center" }))
            : (setYLine(yLine + 20),
              doc.text(`${formatDateToLocal(report.createdAt)}`, center, yLine, { align: "center" })),
        doc.setFont(fontName),
        doc.setFontSize(18),
        setYLine(yLine + 20),
        doc.text(`${report.shopName}`, center, yLine, { align: "center" }),
        doc.setFontSize(12),
        setYLine(yLine + 20),
        doc.text(`Org nr: ${report.organisationNumber}`, center, yLine, { align: "center" }),
        //Payment methods for kassa orders
        isNotOnlineReport &&
            printPaymentMethods(
                "Försäljning per betalsätt kassa",
                report.paymentMethodAndAmounts,
                report.cardAmounts,
                report.vatRateAmountWithRefunds ?? report.vatRatesAndAmounts,
                report.refundTotalVatAmount,
                report,
                companyLocale
            ),
        //Payment methods for online orders with control unit values
        isNotOnlineReport &&
            printPaymentMethods(
                "Försäljning per betalsätt online",
                report.paymentMethodAndAmountsOnline,
                report.cardAmountsOnline,
                report.vatRateAmountWithRefundsOnline,
                refundTotalVatAmountOnline,
                report,
                companyLocale
            ),
        //Sales per online means of sale
        !isNotOnlineReport && printOnlineSalesChannels(report, companyLocale),
        doc.setLineWidth(0.5),
        setYLine(yLine + 10),
        background(5),
        doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
        setYLine(yLine + 25),
        doc.setFont(fontName, subFontStyle),
        doc.setFontSize(13),
        //Sales per products category
        doc.text("Försäljning per varugrupp", mergeLeftSubTitle, yLine),
        doc.setFont(fontName, fontStyle),
        doc.setFontSize(12),
        report.categoryTotalSales.map((catAmount: any) => {
            const categoryName =
                catAmount.categoryName === "THIRD_PARTY_MENU_CAT_ID" ? "Leveranstjänster" : catAmount.categoryName;
            return (
                background(5),
                setYLine(yLine + 15),
                doc.text(`${categoryName}`, mergeLeft, yLine),
                doc.text(`${formatFinancialNumbers(catAmount.totalSales, companyLocale)}`, mergeRight, yLine, {
                    align: "right"
                })
            );
        }),
        doc.setLineWidth(0.5),
        setYLine(yLine + 10),
        background(5),
        doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
        shouldShowTotalIncludeRefundsSection &&
            (setYLine(yLine + 25),
            doc.setFont(fontName, subFontStyle),
            doc.setFontSize(13),
            doc.text("Totalt (inkl returer)", mergeLeftSubTitle, yLine),
            printTotalInclRefunds(report, companyLocale)),
        setYLine(yLine + 25),
        doc.setFont(fontName, subFontStyle),
        doc.setFontSize(13),
        //Print total netto and moms per payment method, total brutto excl moms
        doc.text("Totalt (exkl returer)", mergeLeftSubTitle, yLine),
        printTotal(report, companyLocale),
        doc.setLineWidth(0.5),
        setYLine(yLine + 10),
        !report.dateIntervalReport &&
            isNotOnlineReport &&
            (background(5),
            doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
            setYLine(yLine + 25),
            doc.setFont(fontName, subFontStyle),
            doc.setFontSize(13),
            doc.text("Växelkassa", mergeLeftSubTitle, yLine),
            doc.setFont(fontName, fontStyle),
            doc.setFontSize(12),
            report.posIngoingCashChange.map((cash: any) => {
                background(5),
                    setYLine(yLine + 15),
                    doc.text(`${cash.posName}`, mergeLeft, yLine),
                    doc.text(`${formatFinancialNumbers(cash.amount, companyLocale)}`, mergeRight, yLine, {
                        align: "right"
                    });
            })),
        doc.setLineWidth(0.5),
        setYLine(yLine + 10),
        background(5),
        doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
        setYLine(yLine + 25),
        doc.setFont(fontName, subFontStyle),
        doc.setFontSize(13),
        doc.text("Övrigt", mergeLeftSubTitle, yLine),
        doc.setFont(fontName, fontStyle),
        doc.setFontSize(12),
        isNotOnlineReport &&
            (background(5),
            setYLine(yLine + 15),
            doc.text("Antal sålda varor", mergeLeft, yLine),
            doc.text(`${report.sumSoldProducts} st`, center, yLine, { align: "right" }),
            background(5),
            setYLine(yLine + 15),
            doc.text("Rabatter", mergeLeft, yLine),
            doc.text(`${formatFinancialNumbers(report.discounts.amount, companyLocale)}`, mergeRight, yLine, {
                align: "right"
            })),
        background(5),
        setYLine(yLine + 15),
        doc.text("Returer", mergeLeft, yLine),
        doc.text(`${report.refunds.count} st`, center, yLine, { align: "right" }),
        doc.text(`${formatFinancialNumbers(report.refunds.amount, companyLocale)}`, mergeRight, yLine, {
            align: "right"
        }),
        //tips for online basic(qr-tables)
        !isNotOnlineReport &&
            report.tip &&
            (background(5),
            setYLine(yLine + 15),
            doc.text("Dricks", mergeLeft, yLine),
            doc.text(`${formatFinancialNumbers(report.tip, companyLocale)}`, mergeRight, yLine, {
                align: "right"
            })),
        //redeemed gift cards
        !isNotOnlineReport &&
            report.redeemedGiftCardAmount &&
            (background(5),
            setYLine(yLine + 15),
            doc.text("Inlöst presentkortsbelopp", mergeLeft, yLine),
            doc.text(`${formatFinancialNumbers(report.redeemedGiftCardAmount, companyLocale)}`, mergeRight, yLine, {
                align: "right"
            })),
        isNotOnlineReport &&
            (background(5),
            setYLine(yLine + 15),
            doc.text("Kassakvitton", mergeLeft, yLine),
            doc.text(`${report.sumReceipts} st`, center, yLine, { align: "right" }),
            background(5),
            setYLine(yLine + 15),
            doc.text("Kvittokopior", mergeLeft, yLine),
            doc.text(`${report.copies.count} st`, center, yLine, { align: "right" }),
            doc.text(`${formatFinancialNumbers(report.copies.amount, companyLocale)}`, mergeRight, yLine, {
                align: "right"
            }),
            background(5),
            setYLine(yLine + 15),
            doc.text("Övningskvitton", mergeLeft, yLine),
            doc.text(`${report.tests.count} st`, center, yLine, { align: "right" }),
            doc.text(`${formatFinancialNumbers(report.tests.amount, companyLocale)}`, mergeRight, yLine, {
                align: "right"
            }),
            background(5),
            setYLine(yLine + 15),
            doc.text("Oavslutade notor", mergeLeft, yLine),
            doc.text(`${report.unfinished.count} st`, center, yLine, { align: "right" }),
            doc.text(`${formatFinancialNumbers(report.unfinished.amount, companyLocale)}`, mergeRight, yLine, {
                align: "right"
            }),
            background(5),
            setYLine(yLine + 15),
            doc.text("Lådöppningar", mergeLeft, yLine),
            doc.text(`${report.sumOpenedRegister} st`, center, yLine, { align: "right" })),
        !report.dateIntervalReport &&
            isNotOnlineReport &&
            (doc.setLineWidth(0.5),
            setYLine(yLine + 10),
            background(5),
            doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
            setYLine(yLine + 25),
            doc.setFont(fontName, subFontStyle),
            doc.setFontSize(13),
            doc.text("Grand Total", mergeLeftSubTitle, yLine),
            background(5),
            setYLine(yLine + 15),
            doc.setFont(fontName, fontStyle),
            doc.setFontSize(12),
            doc.text("Grand Total Nettoförsäjlning", mergeLeft, yLine),
            doc.text(`${formatFinancialNumbers(report.grandTotalNet, companyLocale)}`, mergeRight, yLine, {
                align: "right"
            }),
            background(5),
            setYLine(yLine + 15),
            doc.text("Grand Total Retur", mergeLeft, yLine),
            doc.text(`${formatFinancialNumbers(report.grandTotalRefund, companyLocale)}`, mergeRight, yLine, {
                align: "right"
            }),
            background(5),
            setYLine(yLine + 15),
            doc.text("Grand Total", mergeLeft, yLine),
            doc.text(`${formatFinancialNumbers(report.grandTotalSales, companyLocale)}`, mergeRight, yLine, {
                align: "right"
            }),
            background(0, true)),
        report.cashInvoicesExists &&
            isNotOnlineReport &&
            (doc.setLineWidth(0.5),
            setYLine(yLine + 10),
            background(5),
            doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
            setYLine(yLine + 25),
            doc.setFont(fontName, subFontStyle),
            doc.setFontSize(15),
            doc.text(
                "OBS! Kontantfakturor har skapats under samma period men är ej sammanställda här.",
                center,
                yLine,
                {
                    align: "center",
                    maxWidth: 500
                }
            ),
            setYLine(yLine + 40),
            doc.setFontSize(13),
            doc.text("Gå till menyalternativet Kontantfakturor för att se dessa.", center, yLine, {
                align: "center",
                maxWidth: 500
            })),
        report.invoicePaymentExists &&
            isNotOnlineReport &&
            (doc.setLineWidth(0.5),
            setYLine(yLine + 10),
            background(5),
            doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
            setYLine(yLine + 25),
            doc.setFont(fontName, subFontStyle),
            doc.setFontSize(15),
            doc.text("OBS! Fakturor har skapats under samma period men är ej sammanställda här.", center, yLine, {
                align: "center",
                maxWidth: 500
            }),
            setYLine(yLine + 40),
            doc.setFontSize(13),
            doc.text("Vänligen gå till Fakturaunderlag för att se en översikt", center, yLine, {
                align: "center",
                maxWidth: 500
            })),
        doc.setLineWidth(0.5),
        setYLine(yLine + 10),
        doc.setFont(fontName, fontStyle),
        doc.setFontSize(12),
        doc.line(mergeLeftSubTitle, pageHeight - 30, mergeRight, pageHeight - 30),
        doc.text("Powered by qopla", mergeRight, pageHeight - 10, { align: "right" }),
        //save the pdf document
        doc.save(`${report.shopName.toLowerCase().replaceAll(" ", "_")}_${report.reportType}${report.reportNumber}`)
    );
};

const printProductName = (prod: any, doc: jsPDF, column: number, yLine: number, setYLine: (y: number) => void) => {
    const mods = prod.modifications;

    if (mods && ((mods.sizes && mods.sizes.length > 0) || (mods.flavours && mods.flavours.length > 0))) {
        const productNameWithMods = printModificatonNames(mods, prod.name);
        doc.text(productNameWithMods, column - 25, yLine);
    } else if (!!prod.selectedBundleProductItems && prod.selectedBundleProductItems.length > 0) {
        doc.text(`${prod.name}`, column - 30, yLine);
        prod.selectedBundleProductItems.map((bundleItem: any) => {
            yLine = yLine + 15;
            hasModifications(bundleItem.modifications)
                ? printModificatonNames(bundleItem.modifications, bundleItem.name)
                : doc.text(`${bundleItem.name}`, column - 25, yLine);
        });
        setYLine(yLine);
    } else {
        doc.text(`${prod.name.trim()}`, column - 30, yLine);
    }
};

export const invoicesPDFGenerator = (invoice: InvoiceOrder, companyLocale: CompanyLocale, translate: TranslateFunc) => {
    const doc = new jsPDF("p", "pt");
    const pageHeight = doc.internal.pageSize.height;

    const vatRates = getOrderVatRates(invoice);

    let yLine = 20;
    const setYLine = (y: number) => {
        yLine = y;
        if (yLine >= pageHeight - 40) {
            doc.addPage();
            yLine = 40; // Restart height position
        }
    };

    let bg = false;
    const setBg = () => {
        bg = !bg;
    };

    const background = (h: number, reset?: boolean) => {
        setYLine(yLine + 5);
        doc.setFillColor(bg || reset ? "#fff" : "#fafafb");
        doc.rect(mergeLeftSubTitle, yLine, 500, yLine + h, "F");
        setBg();
    };

    return (
        doc.setFont(invoicePDFFont, subFontStyle),
        doc.setFontSize(11),
        setYLine(yLine + 15),
        doc.text(`${translate("invoiceDocumentForReceipt")}: #${invoice.receiptNumber}`, mergeLeft, yLine),
        doc.text(`${translate("date")}: ${formatDateToLocal(invoice.purchaseDate)}`, mergeRight, yLine, {
            align: "right"
        }),
        setYLine(yLine + 5),
        doc.setDrawColor("#e4e4e5"),
        doc.setLineWidth(0.5),
        setYLine(yLine + 10),
        background(invoice.invoiceData ? 135 : 5),
        doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
        setYLine(yLine + 25),
        doc.setFontSize(28),
        setYLine(yLine + 25),
        doc.text(`${translate("invoiceDocument")}`, center, yLine, { align: "center" }),
        invoice.invoiceData &&
            (setYLine(yLine + 25),
            doc.setFontSize(15),
            doc.text(`${invoice.invoiceData.invoiceAddress.name}`, center, yLine, { align: "center" }),
            setYLine(yLine + 15),
            doc.text(`${translate("orgNr")}: ${invoice.invoiceData.organisationNumber}`, center, yLine, {
                align: "center"
            }),
            setYLine(yLine + 15),
            doc.setFont(invoicePDFFont, fontStyle),
            doc.setFontSize(12),
            doc.text(`${invoice.invoiceData.invoiceAddress.addressLine}`, center, yLine, { align: "center" }),
            setYLine(yLine + 15),
            doc.text(
                `${invoice.invoiceData.invoiceAddress.postCode}, ${invoice.invoiceData.invoiceAddress.city}`,
                center,
                yLine,
                {
                    align: "center"
                }
            ),
            setYLine(yLine + 15),
            doc.text(`${invoice.invoiceData.invoiceReference}`, center, yLine, { align: "center" }),
            setYLine(yLine + 15),
            doc.setFont(invoicePDFFont, subFontStyle),
            doc.text(`${translate("contactPerson")}: ${invoice.invoiceData.contactName}`, center, yLine, {
                align: "center"
            }),
            doc.setFont(invoicePDFFont, fontStyle),
            doc.setFontSize(12),
            setYLine(yLine + 25),
            doc.text(`${invoice.comment ? invoice.comment : ""}`, center, yLine, { align: "center" })),
        doc.setFont(invoicePDFFont, subFontStyle),
        doc.setFontSize(12),
        setYLine(yLine + 10),
        background(5),
        doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
        setYLine(yLine + 25),
        doc.text(`${translate("products")}:`, mergeLeft, yLine),
        setYLine(yLine + 10),
        background(5),
        doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
        setYLine(yLine + 25),
        doc.text(`${translate("quantity")}:`, column_1, yLine, { align: "center" }),
        doc.text(`${translate("product")}:`, column_2, yLine, { align: "center" }),
        doc.text(`${translate("vat")} (%):`, column_3, yLine, { align: "center" }),
        doc.text(`${translate("price")}:`, column_4, yLine, { align: "right" }),
        doc.setFont(invoicePDFFont, fontStyle),
        setYLine(yLine + 10),
        invoice.orderProducts.map((product: any) => {
            background(5);
            doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine);
            setYLine(yLine + 15);
            doc.text(`${product.quantity}`, column_1, yLine, { align: "center" });
            doc.text(`${product.vatRate}%`, column_3, yLine, { align: "center" });
            doc.text(`${formatFinancialNumbers(product.totalPrice, companyLocale)}`, column_4, yLine, {
                align: "right"
            });
            printProductName(product, doc, column_2, yLine, setYLine);
        }),
        background(5),
        doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
        setYLine(yLine + 30),
        doc.setFontSize(16),
        Object.keys(vatRates).map(
            vatRate => (
                doc.text(`${translate("vat")} ${vatRate}%`, 350, yLine, { align: "center" }),
                doc.text(`${formatFinancialNumbers(vatRates[vatRate], companyLocale)}`, column_4, yLine, {
                    align: "right"
                }),
                setYLine(yLine + 30)
            )
        ),
        setYLine(yLine + 10),
        background(5),
        doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
        setYLine(yLine + 25),
        doc.setFont(invoicePDFFont, subFontStyle),
        doc.text(`${translate("sum")}:`, 350, yLine, { align: "center" }),
        doc.text(`${formatFinancialNumbers(invoice.totalAmount, companyLocale)}`, column_4, yLine, { align: "right" }),
        setYLine(yLine + 10),
        background(5, true),
        doc.line(mergeLeftSubTitle, pageHeight - 30, mergeRight, pageHeight - 30),
        doc.setFont(invoicePDFFont, fontStyle),
        doc.setFontSize(12),
        doc.text("Powered by qopla", mergeRight, pageHeight - 10, { align: "right" }),
        //save the pdf document
        doc.save(`${translate("invoice")}_${invoice.receiptNumber}`)
    );
};

export const mergedInvoicesPDFGenerator = async (
    groupedKey: string,
    invoiceOrders: Order[],
    companyLocale: CompanyLocale,
    translate: TranslateFunc
) => {
    const column_sm_1 = 75;
    const column_sm_2 = 150;
    const column_sm_3 = 275;
    const column_sm_4 = 375;
    const column_sm_5 = 525;

    const doc = new jsPDF("p", "pt");
    const pageHeight = doc.internal.pageSize.height;

    let yLine = 20;
    const setYLine = (y: number) => {
        yLine = y;
        if (yLine >= pageHeight - 40) {
            doc.addPage();
            yLine = 40; // Restart height position
        }
    };

    let bg = false;
    const setBg = () => {
        bg = !bg;
    };
    const background = (h: number, reset?: boolean) => {
        setYLine(yLine + 5);
        doc.setFillColor(bg || reset ? "#fff" : "#fafafb");
        doc.rect(mergeLeftSubTitle, yLine, 500, yLine + h, "F");
        setBg();
    };

    const invoiceCustomer = invoiceOrders[0].invoiceData;

    const totalAmount = invoiceOrders.reduce((acc: number, next: Order) => acc + next.totalAmount, 0);
    const totalVatRates = invoiceOrders.reduce((vatRates: { [key: string]: number }, order: Order) => {
        order.vatRatesAndAmounts.forEach((vatRateAndAmount: VatRateAmount) => {
            if (!vatRates[vatRateAndAmount.vatRate]) {
                vatRates[vatRateAndAmount.vatRate] = 0;
            }
            vatRates[vatRateAndAmount.vatRate] += vatRateAndAmount.amount;
        });
        return vatRates;
    }, {});
    const totalVATAmount = Object.values(totalVatRates).reduce(
        (acc: number, vatAmount: number) => (acc += vatAmount),
        0
    );
    const totalAmountExcludeVat = totalAmount - totalVATAmount;

    return (
        doc.setFont(invoicePDFFont, subFontStyle),
        doc.setFontSize(11),
        setYLine(yLine + 15),
        setYLine(yLine + 5),
        doc.setDrawColor("#e4e4e5"),
        doc.setLineWidth(0.5),
        setYLine(yLine + 10),
        // invoice header
        background(!!invoiceOrders.length ? 135 : 5),
        doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
        setYLine(yLine + 25),
        doc.setFontSize(28),
        setYLine(yLine + 25),
        doc.text(`${translate("invoiceDocument")}`, center, yLine, { align: "center" }),
        invoiceCustomer &&
            (setYLine(yLine + 25),
            doc.setFontSize(15),
            doc.text(`${invoiceCustomer.invoiceAddress.name}`, center, yLine, { align: "center" }),
            setYLine(yLine + 15),
            doc.text(`${translate("orgNr")}: ${invoiceCustomer.organisationNumber}`, center, yLine, {
                align: "center"
            }),
            invoiceCustomer.customerNumber &&
                (setYLine(yLine + 15),
                doc.text(`${translate("customerNr")}: ${invoiceCustomer.customerNumber}`, center, yLine, {
                    align: "center"
                })),
            setYLine(yLine + 15),
            doc.setFont(invoicePDFFont, fontStyle),
            doc.setFontSize(12),
            doc.text(`${invoiceCustomer.invoiceAddress.addressLine}`, center, yLine, { align: "center" }),
            setYLine(yLine + 15),
            doc.text(
                `${invoiceCustomer.invoiceAddress.postCode}, ${invoiceCustomer.invoiceAddress.city}`,
                center,
                yLine,
                {
                    align: "center"
                }
            ),
            setYLine(yLine + 15),
            doc.text(`${invoiceCustomer.invoiceReference}`, center, yLine, { align: "center" }),
            setYLine(yLine + 15)),
        invoiceOrders.map((invoice: any) => {
            doc.setFont(invoicePDFFont, subFontStyle),
                doc.setFontSize(16),
                background(5, true),
                setYLine(yLine + 30),
                doc.setLineWidth(2),
                doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
                setYLine(yLine + 30),
                // invoice order header
                doc.text(`${translate("altOrder")}: #${invoice.receiptNumber}`, mergeLeft, yLine),
                doc.setFontSize(12),
                doc.text(
                    `${translate("dateOfPurchase")}: ${formatDateToLocal(invoice.purchaseDate)}`,
                    mergeRight,
                    yLine,
                    {
                        align: "right"
                    }
                ),
                setYLine(yLine + 15),
                doc.setFont(invoicePDFFont, fontStyle),
                doc.text(`${translate("purchasedBy")}: ${invoice.invoiceData.contactName}`, mergeLeft, yLine);
            invoice.comment &&
                (setYLine(yLine + 15),
                doc.text(`${translate("comment")}: ${invoice.comment ? invoice.comment : ""}`, mergeLeft, yLine)),
                setYLine(yLine + 25),
                doc.setFont(invoicePDFFont, subFontStyle),
                // order products table
                doc.text(`${translate("products")}:`, mergeLeft, yLine),
                setYLine(yLine + 10),
                background(5),
                doc.setLineWidth(1),
                doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
                setYLine(yLine + 25),
                doc.text(`${translate("quantity")}:`, column_sm_1, yLine, { align: "center" }),
                doc.text(`${translate("product")}:`, column_sm_2, yLine, { align: "center" }),
                doc.text(`${translate("vat")} (%):`, column_sm_3, yLine, { align: "center" }),
                doc.text(`${translate("vat")} (kr):`, column_sm_4, yLine, { align: "center" }),
                doc.text(`${translate("price")}:`, column_sm_5, yLine, { align: "right" }),
                doc.setFont(invoicePDFFont, fontStyle),
                setYLine(yLine + 10),
                invoice.orderProducts.map((product: any) => {
                    background(5);
                    doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine);
                    setYLine(yLine + 15);
                    doc.text(`${product.quantity}`, column_sm_1, yLine, { align: "center" });
                    doc.text(`${product.vatRate}%`, column_sm_3, yLine, { align: "center" });
                    doc.text(
                        `${formatFinancialNumbers(product.totalPrice - product.totalNetPrice, companyLocale)}`,
                        column_sm_4,
                        yLine,
                        {
                            align: "center"
                        }
                    );
                    doc.text(`${formatFinancialNumbers(product.totalPrice, companyLocale)}`, column_sm_5, yLine, {
                        align: "right"
                    });
                    printProductName(product, doc, column_sm_2, yLine, setYLine);
                }),
                setYLine(yLine + 5),
                doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine);
        }),
        background(5, true),
        setYLine(yLine + 30),
        doc.setLineWidth(3),
        background(220),
        doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
        // summary for all orders
        doc.setFontSize(12),
        setYLine(yLine + 30),
        doc.text(`${translate("totalExclusiveMoms")}:`, 300, yLine, { align: "left" }),
        doc.text(`${formatFinancialNumbers(totalAmountExcludeVat, companyLocale)}`, 550, yLine, {
            align: "right"
        }),
        setYLine(yLine + 20),
        Object.keys(totalVatRates).map(
            vatRate => (
                doc.text(`${translate("vat")} ${vatRate}%`, 300, yLine, { align: "left" }),
                doc.text(`${formatFinancialNumbers(totalVatRates[vatRate], companyLocale)}`, 550, yLine, {
                    align: "right"
                }),
                setYLine(yLine + 15)
            )
        ),
        doc.setFont(invoicePDFFont, subFontStyle),
        setYLine(yLine + 20),
        doc.setFontSize(14),
        doc.text(`${translate("totalInclusiveMoms")}:`, 300, yLine, { align: "left" }),
        doc.text(`${formatFinancialNumbers(totalAmount, companyLocale)}`, 550, yLine, { align: "right" }),
        setYLine(yLine + 10),
        background(5, true),
        doc.setLineWidth(1),
        doc.line(mergeLeftSubTitle, pageHeight - 30, mergeRight, pageHeight - 30),
        doc.setFont(invoicePDFFont, fontStyle),
        doc.setFontSize(12),
        doc.text("Powered by qopla", mergeRight, pageHeight - 10, { align: "right" }),
        doc.save(`${translate("invoiceForCompany")}_${groupedKey}`, { returnPromise: true })
    );
};

export const userAccountPDFGenerator = (order: Order, translate: any, companyLocale: CompanyLocale) => {
    if (!order) {
        return;
    }

    const serviceFee = order?.serviceFee as ServiceFee | undefined;
    const cartVatRates = order?.vatRatesAndAmounts;
    const vatRatesAndAmounts = applyServiceFeeVatToVatRatesAndAmounts(serviceFee, cartVatRates);
    const isSubscription = OrderType.SUBSCRIPTION === order.orderType;
    const totalAmount = order.paymentMethodAmounts[0].amount;
    const totalAmountIncServiceFee = !!serviceFee ? totalAmount + serviceFee?.amount : totalAmount;

    const shopOrCompanyName = order.shopName || order.contactInformation.name;

    const doc = new jsPDF("p", "pt");
    const pageHeight = doc.internal.pageSize.height;
    const mr = mergeRight - 10;
    const ml = mergeLeft + 10;
    const mlAddons = mergeLeft + 20;
    let pageBottom = pageHeight - 60;

    const onlineAddonsList = (addons: any) => {
        addons?.map((addon: any) => {
            const totalAddonPrice = getTotalAddonPrice(addon);
            const sign = mathSign(totalAddonPrice);
            setYLine(yLine + 15);
            doc.text(`+ ${addon.quantity}x ${addon.name}`, mlAddons, yLine);
            doc.text(`${sign} ${totalAddonPrice} kr`, mr, yLine, { align: "right" });
        });
    };
    const customerModificationsList = (modifications: Modifications | null) => {
        if (!modifications) return null;

        const modificationsWithHeader = Object.entries(modifications).reduce(
            (acc: Array<{ val: Modification | null; header: string }>, [key, val]) => {
                acc.push({ header: translate(key), val: val && +val.length > 0 ? (val as Modification[])[0] : null });
                return acc;
            },
            []
        );
        modificationsWithHeader.map(
            modification =>
                modification.val &&
                !!modification.val.name.trim() &&
                (setYLine(yLine + 15),
                doc.text(`${modification.header}: ${modification.val.name}`, mlAddons, yLine),
                modification.val.price > 0 &&
                    modification.val.price !== 0 &&
                    doc.text(`${modification.val.price} kr`, mr, yLine, { align: "right" }))
        );
    };

    let yLine = 20;
    const setYLine = (y: number) => {
        yLine = y;
        if (yLine >= pageHeight - 40) {
            doc.addPage();
            yLine = 40; // Restart height position
        }
    };

    let bg = false;
    const setBg = () => {
        bg = !bg;
    };
    const background = (h: number, reset?: boolean) => {
        setYLine(yLine + 5);
        doc.setFillColor(bg || reset ? "#fff" : "#fafafb");
        doc.rect(mergeLeftSubTitle, yLine, 500, yLine + h, "F");
        setBg();
    };

    const giftCardPaymentMethodAmount = order?.paymentMethodAmounts.find(
        p => p.paymentMethod === PaymentMethod.GIFT_CARD
    );

    // Top Block - order number
    const orderNumberAndBackgroundBlock = () => {
        doc.setDrawColor("#e4e4e5"),
            doc.setLineWidth(0.5),
            doc.text(translate("orderNumber"), center, yLine, { align: "center" }),
            setYLine(yLine + 10),
            doc.setFillColor("#000"),
            doc.rect(mergeLeftSubTitle, yLine, 500, 30, "F"),
            setYLine(yLine + 20),
            doc.setTextColor("#fff"),
            doc.setFontSize(20),
            setYLine(yLine + 2),
            doc.text(`${order.orderNo}`, center, yLine, { align: "center" }),
            setYLine(yLine + 25);
    };

    // Block 2 - shop details / contact information
    const shopAndDetailsBlock = () => {
        doc.setTextColor("#000"),
            doc.setFont(fontName, subFontStyle),
            doc.setFontSize(16),
            doc.text(translate("pointOfSale"), center, yLine, { align: "center" }),
            doc.setFont(fontName, fontStyle),
            doc.setFontSize(14),
            setYLine(yLine + 20),
            doc.text(`${shopOrCompanyName}`, center, yLine, { align: "center" }),
            doc.setFontSize(12),
            order.organisationNumber &&
                (setYLine(yLine + 20),
                doc.text(`${translate("orgNo")}: ${order.organisationNumber}`, center, yLine, { align: "center" })),
            setYLine(yLine + 15),
            doc.text(
                `${order.contactInformation.addressLine}, ${order.contactInformation.postCode}, ${order.contactInformation.city}`,
                center,
                yLine,
                { align: "center" }
            ),
            order.contactInformation?.email &&
                (setYLine(yLine + 15),
                doc.text(`${order.contactInformation.email}`, center, yLine, {
                    align: "center"
                })),
            order.contactInformation?.phoneNumber &&
                (setYLine(yLine + 15),
                doc.text(`${translate("telephone")}: ${order.contactInformation.phoneNumber}`, center, yLine, {
                    align: "center"
                })),
            setYLine(yLine + 15),
            background(5, true),
            setYLine(yLine + 25);
    };

    // Block 3 - date / receiptNo / payment method
    const receiptDetailsBlock = () => {
        doc.setFont(fontName, subFontStyle),
            doc.text(translate("dateOfPurchase"), mergeLeft, yLine),
            doc.setFont(fontName, fontStyle),
            doc.text(formatDateToLocal(order.purchaseDate), mr, yLine, { align: "right" }),
            background(5),
            setYLine(yLine + 25),
            order.userInformation?.onlineContactInformation?.name &&
                (doc.setFont(fontName, subFontStyle),
                doc.text(translate("recipient"), mergeLeft, yLine),
                doc.setFont(fontName, fontStyle),
                doc.text(
                    `${capitalize(order.userInformation?.onlineContactInformation?.name)} ${capitalize(
                        order.userInformation?.onlineContactInformation?.lastName || ""
                    )}`,
                    mr,
                    yLine,
                    { align: "right" }
                ),
                setYLine(yLine + 25)),
            doc.setFont(fontName, subFontStyle),
            doc.text(translate("receiptNo"), mergeLeft, yLine),
            doc.setFont(fontName, fontStyle),
            doc.text(`${order.receiptNumber}`, mr, yLine, { align: "right" }),
            background(5, true),
            setYLine(yLine + 25),
            doc.setFont(fontName, subFontStyle),
            doc.text(translate("paymentMethod"), mergeLeft, yLine),
            doc.setFont(fontName, fontStyle),
            doc.text(`${translate(order.paymentMethodAmounts![0].paymentMethod)}`, mr, yLine, {
                align: "right"
            }),
            order.creditCardInfo?.maskedPAN &&
                (setYLine(yLine + 25),
                doc.setFont(fontName, subFontStyle),
                doc.text(translate("card"), mergeLeft, yLine),
                doc.setFont(fontName, fontStyle),
                doc.text(order.creditCardInfo?.maskedPAN, mr, yLine, { align: "right" }));
    };

    // Block 4 - if subscription
    const subscriptionBlock = () => {
        doc.text(capitalize(translate("subscription")), mergeLeft, yLine),
            setYLine(yLine + 25),
            doc.setFont(fontName, fontStyle),
            doc.text(`1x ${order.subscriptionMeta.name}`, ml, yLine),
            doc.text(`${formatFinancialNumbers(order.totalAmount, companyLocale)}`, mr + 5, yLine, {
                align: "right"
            });
    };

    // Block 4 - if order products
    const orderProductsBlock = () => {
        doc.text(capitalize(translate("products")), mergeLeft, yLine),
            order.orderProducts
                .filter(orderProduct => orderProduct.name !== GLOBAL_TIP_PRODUCT_NAME)
                .map(orderProduct => {
                    setYLine(yLine + 25),
                        doc.setFont(fontName, fontStyle),
                        doc.text(`${orderProduct.quantity}x ${orderProduct.name}`, ml, yLine),
                        doc.text(`${formatFinancialNumbers(orderProduct.totalPrice, companyLocale)}`, mr + 5, yLine, {
                            align: "right"
                        });
                    onlineAddonsList(orderProduct.addons);
                    orderProduct.selectedBundleProductItems &&
                        orderProduct.selectedBundleProductItems.map((bundleItem: any) => {
                            const modAddonPrice =
                                bundleItem.modifications &&
                                getSelectedModificationAddonPriceFromBackend(bundleItem.modifications);
                            const sign = mathSign(modAddonPrice);
                            setYLine(yLine + 15);
                            doc.text(`${bundleItem.name}`, ml, yLine);
                            !!modAddonPrice &&
                                modAddonPrice !== 0 &&
                                (setYLine(yLine + 15),
                                doc.text(`${sign} ${modAddonPrice} kr`, mr, yLine, { align: "right" }));
                            bundleItem.addons.length > 0 && onlineAddonsList(bundleItem.addons);
                            bundleItem.modifications &&
                                customerModificationsList({
                                    sizes: bundleItem.modifications.sizes,
                                    flavours: bundleItem.modifications.flavours
                                });
                        });
                    !!orderProduct.modifications &&
                        customerModificationsList({
                            sizes: orderProduct.modifications.sizes as any,
                            flavours: orderProduct.modifications.flavours as any
                        });
                    orderProduct.comment &&
                        (setYLine(yLine + 15),
                        doc.text(translate("comment"), ml, yLine),
                        setYLine(yLine + 15),
                        doc.text(`${orderProduct.comment}`, ml, yLine));
                });
    };

    // Block 5 - only when comment
    const commentBlock = () => {
        doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
            setYLine(yLine + 25),
            doc.setFont(fontName, subFontStyle),
            doc.text(translate("comment"), ml, yLine),
            setYLine(yLine + 25),
            doc.setFont(fontName, fontStyle),
            doc.text(`${order.comment}`, ml, yLine);
    };

    // Block 6 - payments + charges tip / service fee / gift card
    const paymentAndChargesBlock = () => {
        //tip
        doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine),
            order.tip !== 0 &&
                (setYLine(yLine + 15),
                doc.text(translate("tip"), mergeLeft, yLine),
                doc.text(formatFinancialNumbers(order.tip!, companyLocale), mergeRight, yLine, { align: "right" })),
            setYLine(yLine + 15),
            // Service fee
            background(5, true),
            serviceFee &&
                serviceFee?.amount > 0 &&
                (setYLine(yLine + 15),
                doc.text(translate("serviceFee"), mergeLeft, yLine),
                doc.text(formatFinancialNumbers(serviceFee.amount, companyLocale), mergeRight, yLine, {
                    align: "right"
                })),
            background(5, true),
            // Gift Card
            giftCardPaymentMethodAmount &&
                (setYLine(yLine + 15),
                doc.setFont(fontName, subFontStyle),
                doc.text(translate("giftCard"), mergeLeft, yLine),
                doc.text(
                    `- ${formatFinancialNumbers(giftCardPaymentMethodAmount.amount, companyLocale)}`,
                    mergeRight,
                    yLine,
                    {
                        align: "right"
                    }
                ),
                setYLine(yLine + 15),
                background(5, true),
                doc.line(mergeLeftSubTitle, yLine, mergeRight, yLine)),
            setYLine(yLine + 25);
    };

    // Block 7 - total
    const totalBlock = () => {
        doc.setFont(fontName, subFontStyle),
            background(5, true),
            doc.setFontSize(20),
            doc.text(translate("total"), mergeLeft - 3, yLine),
            doc.text(`${formatFinancialNumbers(totalAmountIncServiceFee, companyLocale)}`, mergeRight + 5, yLine, {
                align: "right"
            }),
            setYLine(yLine + 10),
            background(5, true);
    };

    // Block 8 vat values + different %
    const vatAmountBlock = () => {
        setYLine(yLine + 15),
            vatRatesAndAmounts
                .filter(vat => vat.amount !== 0)
                .map(
                    vat => (
                        doc.setFont(fontName, subFontStyle),
                        doc.setFontSize(12),
                        doc.text(`${translate("vat")} ${vat.vatRate}%`, mergeLeft, yLine),
                        doc.setFont(fontName, fontStyle),
                        doc.text(`${formatFinancialNumbers(vat.amount, companyLocale)}`, mergeRight, yLine, {
                            align: "right"
                        }),
                        setYLine(yLine + 15)
                    )
                );
    };

    // Block 9 - qopla footer
    const pageFooter = () => {
        doc.setFont(fontName, fontStyle),
            doc.setFontSize(12),
            setYLine(yLine + 25),
            doc.text(`${translate("technicalQuestions")}: support@qopla.com`, center, (pageBottom += 25), {
                align: "center"
            }),
            doc.text(`© ${year} Qopla`, center, (pageBottom += 25), {
                align: "center"
            });
    };

    return (
        // pdf start - font & start size + style
        doc.setFont(fontName, fontStyle),
        doc.setFontSize(14),
        // order number if there
        setYLine(yLine + 45),
        order.orderNo !== 0 && orderNumberAndBackgroundBlock(),
        setYLine(yLine + 10),
        //contact information
        shopAndDetailsBlock(),
        // receipt details
        receiptDetailsBlock(),
        // Products or susbcription
        background(5),
        setYLine(yLine + 25),
        doc.setFont(fontName, subFontStyle),
        isSubscription ? subscriptionBlock() : orderProductsBlock(),
        setYLine(yLine + 15),
        // Commment
        background(5, true),
        !!order.comment && commentBlock(),
        setYLine(yLine + 15),
        // Payment + charges
        background(5, true),
        !isSubscription && paymentAndChargesBlock(),
        // Total
        totalBlock(),
        // VAT
        vatAmountBlock(),
        // Qopla footer
        pageFooter(),
        //save the pdf document
        doc.save(`${shopOrCompanyName}-${order.orderNo}`)
    );
};
