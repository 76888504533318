import React from "react";
import { BsFillInfoCircleFill } from "@react-icons/all-files/bs/BsFillInfoCircleFill";

import { Box, Button, Flex, Header, Text, TextProps, NewGrid as Grid } from "Atoms";
import { useDrawer, useModal, useQopla } from "Providers";
import { Languagekey, useLanguage } from "LanguageProvider";
import { Campaign, CampaignStatistics, CampaignStatusDisplay, CAMPAIGN_STATUS, UserCount } from "../types/types";
import {
    capitaliseEachWord,
    formatDateToLocal,
    formatFinancialNumbers,
    isSuperAdminOrQoplaSupport,
    showToastError,
    showToastSuccess
} from "Utils";
import { useQoplaStore } from "Stores";
import { confirmOrCancelNotification } from "TempUtils";
import { useMothershipMutation } from "Hooks";
import { END_CAMPAIGN_EARLY } from "GraphQLMutations";
import { getTotalUserCountAndCost } from "../utils/campaignFunctions";
import { formatNumber } from "NumberUtils";
import { modalNames } from "Constants";

const CampaignStatus: React.FC<{ statusDisplay: { colour: string; translationKey: string } }> = ({ statusDisplay }) => {
    const { translate } = useLanguage();
    return (
        <Flex
            position="relative"
            width="max-content"
            justifyContent="center"
            alignItems="center"
            mt={2}
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.400"
            maxH="30px"
            backgroundColor={statusDisplay.colour}
            borderRadius="xl"
            justifySelf={["flex-end", "flex-end", "flex-start"]}
            mr={[2, 2, 0]}
        >
            <Header as="h6" color="white" size="sm" m={0} p={2}>
                {translate(statusDisplay.translationKey as Languagekey)}
            </Header>
        </Flex>
    );
};

const StatisticsText = ({ ...props }: TextProps) => <Text width="100%" m={0} p={0} fontWeight="bold" {...props} />;

export const CampaignCard: React.FC<{
    campaign: Campaign;
    refetchCampaigns: () => Promise<void>;
}> = ({ campaign, refetchCampaigns }) => {
    const { translate } = useLanguage();
    const { authenticatedUser, selectedCompany } = useQopla();
    const { companyLocale } = useQoplaStore();
    const { onOpenDrawer } = useDrawer();
    const { openModal, closeModal } = useModal();

    /** Only support can cancel / end a campaign early */
    const isQoplaSupportOrSuperAdmin = isSuperAdminOrQoplaSupport(authenticatedUser.roles);

    /** More will be added - this is a quick way - future will probably be with ids and proper cost variables (quick and dirty) */
    const isQoplaPromotionCampaign = campaign.isQoplaPromotion ?? false;

    const [finishCampaignEarly] = useMothershipMutation(END_CAMPAIGN_EARLY);

    /** Formatting of start & end date */
    const startDate = formatDateToLocal(campaign.startDate.toLocaleString(), "YYYY-MM-DD");
    const endDate = formatDateToLocal(campaign.endDate.toLocaleString(), "YYYY-MM-DD");

    const isLive = campaign.status === CAMPAIGN_STATUS.LIVE;
    const isPending = campaign.status === CAMPAIGN_STATUS.PENDING;

    const showEndButton = (isLive || isPending) && isQoplaSupportOrSuperAdmin;

    const statusDisplay: CampaignStatusDisplay = {
        [CAMPAIGN_STATUS.CANCELLED_BEFORE_END_DATE]: { colour: "red.500", translationKey: "ended" },
        [CAMPAIGN_STATUS.ENDED]: { colour: "red.500", translationKey: "ended" },
        [CAMPAIGN_STATUS.LIVE]: { colour: "teal.500", translationKey: "IN_PROGRESS" },
        [CAMPAIGN_STATUS.PENDING]: { colour: "orange.500", translationKey: "notStarted" }
    };

    const hasMultipleShops = (campaign.statistics?.length ?? 0) > 1;

    /** Sum up campaign statistics */
    const totalStatistics = campaign?.statistics?.reduce<Partial<CampaignStatistics>>(
        (acc, stats) => ({
            totalCost: acc.totalCost! + stats.totalCost,
            numberRedeemedOrders: acc.numberRedeemedOrders! + stats.numberRedeemedOrders,
            numberTargetedUsers: acc.numberTargetedUsers! + stats.numberTargetedUsers,
            totalEarnings: acc.totalEarnings! + stats.totalEarnings
        }),
        {
            numberRedeemedOrders: 0,
            numberTargetedUsers: 0,
            totalCost: 0,
            totalEarnings: 0
        }
    );

    const onOpenCampaignInformationModal = () => {
        openModal(modalNames.CAMPAIGN_INFO_MODAL, {
            campaign: campaign,
            closeModal
        });
    };

    const totalStatsToUserCount: UserCount[] =
        campaign?.statistics?.map(stats => ({
            numberOfPotentialUsers: stats.numberTargetedUsers,
            shopId: stats.shopId
        })) ?? [];
    const totalPotentialCosts = getTotalUserCountAndCost(totalStatsToUserCount);

    const onShowStatisticsDrawer = () => {
        onOpenDrawer("campaignStatistics", { campaign });
    };

    const onEndCamaign = async () => {
        const { value } = await confirmOrCancelNotification(
            translate("endCampaign"),
            translate("areYouSure"),
            translate("yes"),
            "#d33",
            translate("cancel")
        );
        if (!!value) {
            
            /**
             * IF the end date is before the start date then we remove the promotion FLAG
             * This is because the promotion was never started and we don't want to remove the promotion
             */
            const startDate = new Date(campaign.startDate);
            const endDate = new Date();
            const endDateIsBeforeStart = endDate.getTime() < startDate.getTime();

            const response = await finishCampaignEarly({
                variables: {
                    companyId: selectedCompany.id,
                    campaignId: campaign.id!,
                    removePromotion: endDateIsBeforeStart
                }
            });
            if (response.data?.finishCampaignEarly) {
                showToastSuccess(translate("campaignHasEnded"));
                await refetchCampaigns();
            } else {
                showToastError(translate("somethingWentWrong"));
            }
        }
    };

    return (
        <Flex
            width={"100%"}
            borderWidth="1px"
            borderStyle="solid"
            borderColor="gray.400"
            borderRadius="lg"
            boxShadow="md"
            minH="150px"
            backgroundColor="secondaryAlpha.50"
            mt={6}
        >
            <Grid templateColumns={["1fr", "1fr", "repeat(3, minmax(max-content, 1fr))"]} p={4} ml={4}>
                <Grid
                    templateColumns={["1fr 1fr", "1fr 1fr", "1fr"]}
                    alignItems="baseline"
                    justifyContent="flex-start"
                    height="max-content"
                    gap="1rem"
                    ml={1}
                >
                    {isQoplaSupportOrSuperAdmin ? (
                        <Flex alignItems={"center"}>
                            <Box
                                size="15px"
                                cursor="pointer"
                                textAlign="center"
                                as={BsFillInfoCircleFill}
                                onClick={onOpenCampaignInformationModal}
                            />
                            <Header as="h2" color="newPrimary" fontSize="1.7rem" m={0} ml={2}>
                                {campaign.name}
                            </Header>
                        </Flex>
                    ) : (
                        <Header as="h2" color="newPrimary" fontSize="1.7rem" m={0}>
                            {campaign.name}
                        </Header>
                    )}
                    <CampaignStatus statusDisplay={statusDisplay[campaign.status!]} />
                    <Flex justifyContent="flex-start" flexDirection="column" width="100%">
                        <Header
                            as="h6"
                            size="sm"
                            m={0}
                            mt={2}
                            color={statusDisplay[campaign.status!].colour}
                            mr={[2, 2, 0]}
                        >{`${startDate} - ${endDate}`}</Header>
                        <Header as="h6" size="sm" m={0} mt={4}>
                            {capitaliseEachWord(translate(`APPLIES_TO_${campaign.campaignType}` as Languagekey))}
                        </Header>
                    </Flex>
                    <Text fontWeight={"bold"}>
                        {`${translate("discountCode")}: `}
                        <Box as="span" ml={2} fontWeight={"bold"} color="yellow.800">
                            {campaign.campaignCode}
                        </Box>
                    </Text>
                </Grid>
                <Grid templateColumns="1fr" justifyContent="space-evenly" alignItems="center" mt={[4, 4, 0]}>
                    <Box>
                        <Flex width="100%" p={2} pl={0} justifyContent="space-between">
                            <StatisticsText>{translate("totalCost")}</StatisticsText>
                            <StatisticsText
                                display="flex"
                                justifyContent="flex-end"
                                style={{ textDecoration: isQoplaPromotionCampaign ? "line-through" : "" }}
                            >
                                {formatFinancialNumbers(totalPotentialCosts.totalCost!, companyLocale)}
                                {!isQoplaPromotionCampaign && (
                                    <Box as="span" ml={2}>
                                        {translate("excludingVAT")}
                                    </Box>
                                )}
                            </StatisticsText>
                            {isQoplaPromotionCampaign && (
                                <StatisticsText
                                    ml={2}
                                    maxW={"max-content"}
                                    display="flex"
                                    justifyContent="flex-end"
                                    color={"red.500"}
                                >
                                    {formatFinancialNumbers(0, companyLocale)}
                                </StatisticsText>
                            )}
                        </Flex>
                        <Flex width="100%" p={2} pl={0} justifyContent="space-between">
                            <StatisticsText>{translate("numberOfPotentialCustomers")}</StatisticsText>
                            <StatisticsText display="flex" justifyContent="flex-end">
                                {formatNumber(totalPotentialCosts.numberOfPotentialUsers, companyLocale)}
                            </StatisticsText>
                        </Flex>
                        <Flex width="100%" p={2} pl={0} justifyContent="space-between">
                            <StatisticsText>{translate("discountHasBeenUsed")}</StatisticsText>
                            <StatisticsText display="flex" justifyContent="flex-end">
                                {totalStatistics?.numberRedeemedOrders}
                            </StatisticsText>
                        </Flex>
                        <Flex width="100%" p={2} pl={0} justifyContent="space-between">
                            <StatisticsText>{translate("totalRevenue")}</StatisticsText>
                            <StatisticsText display="flex" justifyContent="flex-end">
                                {formatFinancialNumbers(totalStatistics?.totalEarnings!, companyLocale)}
                            </StatisticsText>
                        </Flex>
                    </Box>
                </Grid>
                <Grid templateColumns="1fr" justifyContent="space-around" alignItems="flex-end" mr={[0, 0, 10]}>
                    <Flex
                        width="100%"
                        justifyContent={["center", "center", "flex-end"]}
                        mt={[4, 4, 0]}
                        alignItems="flex-end"
                    >
                        <Header
                            as="h1"
                            fontSize="3rem"
                            color="red.500"
                            mr={[0, 0, 15, 20]}
                        >{`${campaign.discountRate}%`}</Header>
                    </Flex>

                    <Flex
                        width="100%"
                        alignItems={["center", "center", "flex-end"]}
                        justifyContent="flex-end"
                        mt={showEndButton ? [6, 6, 6] : [6, 6, 0]}
                        flexDirection="column"
                    >
                        {showEndButton && (
                            <Button
                                size="lg"
                                themeColor="red"
                                color="red.500"
                                variant="outline"
                                width={["100%", "100%", "50%"]}
                                onClick={onEndCamaign}
                            >
                                {translate("endThisCampaign")}
                            </Button>
                        )}
                        {hasMultipleShops && (
                            <Button
                                size="lg"
                                mt={4}
                                mb={1}
                                backgroundColor="newPrimary"
                                color="white"
                                _hover={{ backgroundColor: "primaryAlpha.800" }}
                                width={["100%", "100%", "50%"]}
                                onClick={onShowStatisticsDrawer}
                            >
                                {translate("moreInformation")}
                            </Button>
                        )}
                    </Flex>
                </Grid>
            </Grid>
        </Flex>
    );
};
