import gql from "graphql-tag";
import { ORDER_PRODUCT_ATTRIBUTES, SERVICE_FEE_ATTRIBUTES } from ".";
import { DISCOUNT_META_ATTRIBUTES } from "./discountFragments";

export const ORDER_ATTRIBUTES = gql`
    fragment orderResults on Order {
        id
        orderNo
        orderType
        webOrderType
        shopName
        shopId
        organisationNumber
        totalAmount
        pickupTime
        timeInterval
        purchaseDate
        POSReceiptType
        paymentStatus
        receiptNumber
        receiptType
        posName
        hasCopy
        comment
        comment2
        webPaymentId
        postponePayment
        onlineOrderStatus
        eatingOption
        takeAway
        homeDelivery
        driveThrough
        tip
        thirdPartyDelivery
        deliveryFee
        cateringConfirmationDate
        failedReason
        customerId
        referenceOrderId
        qr
        tip
        terminalId
        autoAccepted
        refundStatus
        puckNo
        anonymousUserId
        tableMeta {
            id
            shopId
            name
        }
        invoiceData {
            invoiceAddress {
                name
                addressLine
                city
                postCode
            }
            isHandled
            organisationNumber
            invoiceReference
            contactName
            isHandled
            customerNumber
        }
        creditCardInfo {
            cardIssuer
            cardNumber
            maskedPAN
            customerReceiptString
        }
        discountMeta {
            ... on DiscountMeta {
                ...discountMetaResults
            }
        }
        subscriptionMeta {
            name
            subscriptionId
            userSubscriptionId
        }
        giftCardMeta {
            userGiftCardId
            amount
        }
        contactInformation {
            name
            addressLine
            postCode
            city
            phoneNumber
            email
        }
        orderControlUnitValues {
            controlUnitNumber
            failedRequest
        }
        paymentMethodAmounts {
            paymentMethod
            amount
        }
        onlineOrderStatusTimestamps {
            timestamp
            onlineOrderStatus
        }
        vatRatesAndAmounts {
            vatRate
            amount
        }
        swishResponses {
            payeePaymentReference
            paymentReference
            payerAlias
            payeeAlias
            callbackUrl
            amount
            message
            status
            dateCreated
            datePaid
            currency
        }
        userInformation {
            onlineContactInformation {
                name
                lastName
                email
                phoneNumber
                phoneNumber2
                addressLine
                postCode
                floorNumber
                doorCode
                city
            }
            paymentInformation {
                paymentMethod
                swishPhoneNumber
                klarnaPaymentSessionData {
                    sessionId
                    clientToken
                    klarnaCreateOrderResponse {
                        orderId
                        fraudStatus
                        redirectUrl
                        authorizedPaymentMethod {
                            numberOfDays
                            numberOfInstallments
                            type
                        }
                    }
                }
            }
        }
        deliveryCreateResponse {
            arriveAtCustomerTime
            httpStatus
            error
            message
            delivered
        }
        orderProducts {
            ... on OrderProduct {
                ...orderProductResults
            }
        }
        serviceFee {
            ... on ServiceFee {
                ...serviceFeeResults
            }
        }
        __typename
    }
    ${DISCOUNT_META_ATTRIBUTES}
    ${ORDER_PRODUCT_ATTRIBUTES}
    ${SERVICE_FEE_ATTRIBUTES}
`;
