import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { CustomRadioButton, RHRadioButtonGroup, NewGrid as Grid, Button, Flex, FormLabel, RHSelectInput } from "Atoms";
import {
    ALL_SHOP_VALUE,
    Campaign,
    CampaignQuery,
    CampaignUserSearchDTO,
    CAMPAIGN_TYPE,
    DEFAULT_VALUES,
    numberOfMonthInactiveSelectOptions,
    numberOfMonthsActiveSelectOptions,
    numberOfOrdersSelectOptions,
    UserCount
} from "../types/types";
import { Languagekey, useLanguage } from "LanguageProvider";
import { useStepWizard, ValidStep } from "Organisms";
import { useMothershipLazyQuery } from "Hooks";
import { SEARCH_FOR_POTENTIAL_CUSTOMERS } from "GraphQLQueries";
import { useQoplaStore } from "Stores";
import { parseJavascriptDateForJavaLocalDateTime } from "Utils";
import { CampaignCustomersCard } from "./CampaignCustomersCards";
import { SelectOption } from "Molecules";
import { CampaignWizardButtons } from "./CampaignWizardButtons";

type MonthsBack = {
    [key in keyof typeof CAMPAIGN_TYPE]: string;
};

export const CampaignCustomersStep: React.FC<{ allShops: SelectOption[] }> = ({ allShops }) => {
    const {
        selectedCompany: { id: campanyId }
    } = useQoplaStore();
    const { translate, translateWithArgument, translateWithMultipleArguments } = useLanguage();
    const { setStepsToValidate, currentStep, stepsToValidate } = useStepWizard();
    const { control, setValue, watch, trigger, getValues } = useFormContext<Campaign>();

    const [campaignType, numberOfMonthsBack, numberOfHistoricOrders, shopIds] = watch([
        "campaignType",
        "numberOfMonthsBack",
        "numberOfHistoricOrders",
        "shopIds"
    ]);
    const { campaignUserSearch } = getValues();

    const [getPotentialCustomers, { data: targetCustomers, loading }] =
        useMothershipLazyQuery<CampaignQuery.getPotentialUsers>(SEARCH_FOR_POTENTIAL_CUSTOMERS);

    const onHandleSearchCustomers = () => {
        const { campaignType, numberOfHistoricOrders, numberOfMonthsBack, shopIds, startDate, endDate } = getValues();

        const activeCustomers = campaignType === CAMPAIGN_TYPE.ACTIVE_CUSTOMERS;

        /** Date Steps */
        const now = new Date();

        /** Step 1: To date - active = now, inactive = number selected back */
        const toDate = activeCustomers ? new Date(now) : new Date(now.setMonth(now.getMonth() - numberOfMonthsBack));

        /** Step 2: from date - active = selected number of months back, inactive = default active months back */
        const now2 = new Date();
        const fromDate = activeCustomers
            ? new Date(now2.setMonth(now2.getMonth() - numberOfMonthsBack))
            : new Date(now2.setMonth(now2.getMonth() - DEFAULT_VALUES.ACTIVE_MONTHS_BACK));

        const searchAllShops = shopIds.length === 1 && shopIds.includes(ALL_SHOP_VALUE);
        const shopIdsToUse = !searchAllShops ? shopIds : allShops.map(select => select.value);
        const campaignSearch: CampaignUserSearchDTO = {
            companyId: campanyId,
            shopIds: shopIdsToUse,
            campaignType: campaignType,
            numberOfMonthsBack: numberOfMonthsBack, //numberOfMonthsBack,
            fromDate: parseJavascriptDateForJavaLocalDateTime(new Date(fromDate)),
            toDate: parseJavascriptDateForJavaLocalDateTime(new Date(toDate)),
            campaignEndDate: parseJavascriptDateForJavaLocalDateTime(startDate),
            campaignStartDate: parseJavascriptDateForJavaLocalDateTime(endDate),
            numberOfHistoricOrders: activeCustomers ? numberOfHistoricOrders : 1
        };

        if (!activeCustomers) {
            setValue("numberOfHistoricOrders", DEFAULT_VALUES.NUMBER_HISTORIC_ORDERS);
        }

        setValue("campaignUserSearch", campaignSearch);
        setValue("shopIds", shopIdsToUse);

        getPotentialCustomers({
            variables: {
                userSearchDTO: campaignSearch
            }
        });
    };

    const isInactiveCustomers = campaignType === CAMPAIGN_TYPE.INACTIVE_CUSTOMERS;

    /** Switching between active & inactive */
    useEffect(() => {
        trigger("numberOfMonthsBack");
        if (campaignType === CAMPAIGN_TYPE.INACTIVE_CUSTOMERS) {
            setValue("numberOfHistoricOrders", DEFAULT_VALUES.NUMBER_HISTORIC_ORDERS);
            setValue("numberOfMonthsBack", DEFAULT_VALUES.INACTIVE_MONTHS_BACK);
        } else {
            setValue("numberOfMonthsBack", DEFAULT_VALUES.ACTIVE_MONTHS_BACK);
        }
    }, [campaignType]);

    useEffect(() => {
        const hasCampaignCustomers =
            campaignSearchWithShopNames?.potentialUserCount?.some(count => count.numberOfPotentialUsers > 0) ?? false;
        if (hasCampaignCustomers) {
            const steps = [...(stepsToValidate || [])];
            const containsCurrentStep = steps.some(value => value.step === currentStep);
            if (!containsCurrentStep) {
                const updateSteps = [...steps, { step: currentStep!, valid: true }];
                setStepsToValidate(updateSteps);
            } else {
                const stepsValid = steps.map((value: ValidStep) => {
                    if (value.step === currentStep) {
                        return {
                            step: value.step,
                            valid: true
                        };
                    }
                    return value;
                });
                setStepsToValidate(stepsValid);
            }
            setValue("campaignUserSearch", campaignSearchWithShopNames, { shouldDirty: true });
        }
    }, [targetCustomers?.potentialNumberOfEmailsForCampaign]);

    const campaignCustomers = targetCustomers?.potentialNumberOfEmailsForCampaign || campaignUserSearch;

    /** Search Count of shops + users to target inc. shop names for display  */
    const userCount: UserCount[] = shopIds?.map((shopId: string, index: number) => {
        const shopName = allShops.find(value => value.value === shopId);
        const potential = campaignCustomers?.potentialUserCount?.find(
            (potential: UserCount) => potential.shopId === shopId
        );
        if (!!potential) {
            return {
                ...potential,
                shopName: !!shopName?.label ? shopName?.label : (index + 1).toString()
            };
        } else {
            return {
                shopId: shopId,
                numberOfPotentialUsers: 0,
                shopName: !!shopName?.label ? shopName?.label : (index + 1).toString()
            };
        }
    });

    const campaignSearchWithShopNames: CampaignUserSearchDTO = !!campaignCustomers
        ? {
              ...campaignCustomers,
              potentialUserCount: userCount
          }
        : ({} as CampaignUserSearchDTO);

    const hasCampaignCustomers =
        campaignSearchWithShopNames?.potentialUserCount?.some(count => count.numberOfPotentialUsers > 0) ?? false;

    /** Total number of potential customers of all shops */
    const totalPotentialCustomer = campaignSearchWithShopNames?.potentialUserCount?.reduce(
        (acc, user) => (acc += user.numberOfPotentialUsers),
        0
    );

    const customerTypeDefaultMonths = !isInactiveCustomers
        ? DEFAULT_VALUES.ACTIVE_MONTHS_BACK
        : DEFAULT_VALUES.INACTIVE_MONTHS_BACK;

    const customerTypeMonthsBack: MonthsBack = {
        ACTIVE_CUSTOMERS: "allCustomersInLastMonths",
        INACTIVE_CUSTOMERS: "inactiveCustomersInLastMonths"
    };

    return (
        <>
            <RHRadioButtonGroup
                activeThemeColor="purple"
                name="campaignType"
                formLabel=""
                control={control}
                rounded={20}
                value={CAMPAIGN_TYPE.ACTIVE_CUSTOMERS}
                wrapperProps={{
                    alignItems: "center",
                    marginBottom: "2rem"
                }}
            >
                <CustomRadioButton value={CAMPAIGN_TYPE.ACTIVE_CUSTOMERS}>
                    {translate(CAMPAIGN_TYPE.ACTIVE_CUSTOMERS)}
                </CustomRadioButton>
                <CustomRadioButton value={CAMPAIGN_TYPE.INACTIVE_CUSTOMERS}>
                    {translate(CAMPAIGN_TYPE.INACTIVE_CUSTOMERS)}
                </CustomRadioButton>
            </RHRadioButtonGroup>
            <Grid templateColumns={["1fr", "1fr", isInactiveCustomers ? "1fr" : "1fr 1fr"]} mb={2}>
                {!isInactiveCustomers && (
                    <RHSelectInput
                        name="numberOfHistoricOrders"
                        control={control}
                        formLabel={translateWithArgument(
                            "campaignMinNumberOfNumbers",
                            numberOfHistoricOrders ?? DEFAULT_VALUES.NUMBER_HISTORIC_ORDERS
                        )}
                        options={numberOfOrdersSelectOptions}
                        wrapperProps={{ width: "100%" }}
                        isFullWidth
                    />
                )}
                <RHSelectInput
                    name="numberOfMonthsBack"
                    formLabel={translateWithArgument(
                        customerTypeMonthsBack[campaignType] as Languagekey,
                        numberOfMonthsBack ?? customerTypeDefaultMonths
                    )}
                    control={control}
                    options={
                        !isInactiveCustomers ? numberOfMonthsActiveSelectOptions : numberOfMonthInactiveSelectOptions
                    }
                    wrapperProps={{ width: "100%", ml: isInactiveCustomers ? 0 : 2 }}
                    isFullWidth
                />
            </Grid>
            <Button
                backgroundColor="newPrimary"
                color="white"
                _hover={{ backgroundColor: "primaryAlpha.800" }}
                fullWidth
                onClick={onHandleSearchCustomers}
                isLoading={loading}
                loadingText={translate("searchCanTakeSomeTime")}
            >
                {translate("search")}
            </Button>
            <Flex mt={8} mb={6} justifyContent="center" width="100%" alignItems="center" flexDirection="column">
                <FormLabel mb={4}>
                    {translateWithArgument("potentialCustomersCost", totalPotentialCustomer ?? 0)}
                </FormLabel>
                {!!campaignCustomers ? (
                    <CampaignCustomersCard
                        key={campaignSearchWithShopNames?.shopIds?.length ?? 0}
                        campaignSearch={campaignSearchWithShopNames}
                    />
                ) : null}
            </Flex>
            {/*     
                MAYBE MVP 2
            {!!campaignCustomers && (
                <RHNumericInput
                    min={0}
                    name="maxNumberOfUsersPerShop"
                    control={control}
                    formLabel={`${translate("maxNumberOfCustomersPerShop")} - (${translate("nullIsUnlimited")})`}
                />
            )} */}
            <CampaignWizardButtons isValid={hasCampaignCustomers} />
        </>
    );
};
