import React, { useState } from "react";
import { MdEdit } from "@react-icons/all-files/md/MdEdit";
import { FaTrash } from "@react-icons/all-files/fa/FaTrash";
import { FaCommentAlt } from "@react-icons/all-files/fa/FaCommentAlt";

import { ExpressButton } from "../shared/ExpressButton";
import { ExpressQuantitySelector } from "../product/ExpressQuantitySelector";
import { Box, Flex, FlexProps, Image, MotionDiv, Text } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { useTheme } from "ThemeProvider";
import { formatFinancialNumbers, getBasePriceForCartProduct } from "PriceUtils";
import { ExpressCartContentCardDetails } from "./ExpressCartContentCardDetails";
import { useOrderWindowsStore, useQoplaStore } from "Stores";
import { AddonsHashMapValue, CartProduct } from "Types";
import { TextImageFallback } from "../shared/TextImageFallback";
import {
    checkIsSimpleBundleProduct,
    getCartProductType,
    getCheckoutSummaryModificationPrices
} from "../../Utils/expressProductUtils";
import { CART_PRODUCT_TYPE } from "../../Utils/types";
import { useModal, usePos } from "Providers";
import { modalNames } from "Constants";

type Props = {
    cartProduct: any;
    switchOrientation?: boolean;
    productAddons?: AddonsHashMapValue;
    handleOpenViewToModify: (cartProduct: CartProduct, productAddons?: AddonsHashMapValue) => void;
    isEditMode?: boolean;
    displayQuantitySelector?: boolean;
} & FlexProps;

/** Cart product types when modify is not to show */
const cartProductTypesToHideModify = [CART_PRODUCT_TYPE.SIMPLE_MENU_PRODUCT, CART_PRODUCT_TYPE.SIMPLE_MODIFIED_PRODUCT];

//When we plug in this component, we need to finish add the functions for the buttons & the quantity selector, target orderWindows Store.
export const ExpressCartContentCard: React.FC<Props> = ({
    cartProduct,
    productAddons = [],
    handleOpenViewToModify,
    isEditMode,
    switchOrientation = false,
    displayQuantitySelector = false,
    ...props
}) => {
    const { openModal, closeModal } = useModal();
    const { translate } = useLanguage();
    const { companyLocale, selectedShop } = useQoplaStore();
    const {
        orientation: { isPortrait }
    } = useTheme();

    const [showQuantitySelector, setShowQuantitySelector] = useState(displayQuantitySelector);
    const {
        addOrderProductComment,
        incrementOrderProduct,
        decrementOrderProduct,
        removeCartProduct,
    } = useOrderWindowsStore();

    const { menuProduct, menuBundleProduct, orderProduct } = cartProduct;
    const refProduct = menuProduct?.refProduct;
    const refBundleProduct = menuBundleProduct?.refBundleProduct;
    const imageUrl = refProduct?.imageUrl || refBundleProduct?.imageUrl;

    const orientationIsPortrait = switchOrientation ? !isPortrait : isPortrait;
    const { shopId, name, totalPrice, quantity, addons, modifications, comment } = orderProduct;
    const [productComment, setProductComment] = useState(comment);
    const hasAddons = addons.length > 0;
    const isSimpleBundle = refBundleProduct && checkIsSimpleBundleProduct(refBundleProduct, hasAddons);
    const allowComment = selectedShop?.settings?.allowCommentsInExpressPOS ?? true;

    const imageVariants = {
        selected: { opacity: 1, x: 0 },
        notSelected: { opacity: 0, x: "-100%" }
    };
    const quantityVariants = {
        notSelected: { opacity: 0 },
        selected: { opacity: 1 }
    };
    const commentVariants = {
        notSelected: { opacity: 0 },
        selected: { opacity: 1 }
    };
    const textVariants = {
        selected: { opacity: 1, width: "100%" },
        notSelected: { opacity: 1, width: "100%" }
    };
    const addCommentAndCloseModal = (comment: string) => {
        const hasNewComment = comment !== (orderProduct.comment ?? "");
        if (hasNewComment) {
            addOrderProductComment(cartProduct.id, comment);
        }
        setProductComment(comment);
        closeModal(modalNames.EXPRESS_COMMENT_MODAL);
    };

    const QuantitySelector = () => (
        <ExpressQuantitySelector
            quantity={quantity}
            decrementOrderProduct={e => {
                e.stopPropagation();
                if (quantity > 1) {
                    e.stopPropagation();
                    decrementOrderProduct(cartProduct);
                } else {
                    removeCartProduct(cartProduct);
                }
            }}
            incrementOrderProduct={e => {
                e.stopPropagation();
                incrementOrderProduct(cartProduct);
            }}
            mx={4}
            marginTop={orientationIsPortrait ? "-10px" : 0}
            isInExpressCart
            size={!isPortrait ? "sm" : "md"}
            isDisabled={!showQuantitySelector}
            display={showQuantitySelector ? "grid" : "none"}
        />
    );

    const ModifyButton = (
        <ExpressButton
            size={orientationIsPortrait ? "sm" : "xs"}
            variant="outline"
            children={translate("modify")}
            rightIcon={MdEdit}
            onClick={() => handleOpenViewToModify(cartProduct, productAddons)}
            isDisabled={isEditMode}
        />
    );

    const CommentButton = (
        <ExpressButton
            size={orientationIsPortrait ? "sm" : "xs"}
            variant="outline"
            children={translate("comment")}
            rightIcon={FaCommentAlt}
            onClick={() =>
                openModal(modalNames.EXPRESS_COMMENT_MODAL, {
                    comment: productComment,
                    addCommentAndCloseModal,
                    closeModal
                })
            }
        />
    );

    const Img = imageUrl ? (
        <Box
            w={showQuantitySelector ? 0 : "80px"}
            {...(orientationIsPortrait && { w: "112px" })}
            h={orientationIsPortrait ? "102px" : "70px"}
            mr={6}
        >
            <Image
                src={imageUrl}
                w={showQuantitySelector ? 0 : "80px"}
                {...(orientationIsPortrait && { w: "112px" })}
                h={orientationIsPortrait ? "102px" : "70px"}
                objectFit={"cover"}
                style={{
                    transition: "width 0.3s ease-in-out"
                }}
            />
        </Box>
    ) : (
        <TextImageFallback
            name={name}
            w={showQuantitySelector ? 0 : "80px"}
            {...(orientationIsPortrait && { w: "112px" })}
            h={orientationIsPortrait ? "102px" : "70px"}
            mr={6}
            p={2}
            truncatedTextProps={{
                width: showQuantitySelector ? 0 : "80px",
                ...(orientationIsPortrait && { width: "112px" }),
                fontSize: "0.4rem",
                lines: 5,
                style: {
                    transition: "width 0.3s ease-in-out"
                }
            }}
        />
    );

    const productQuantityPrice = getBasePriceForCartProduct(cartProduct);

    const { hasModifications, isPriceModification, isAddonPriceModification, priceModification } =
        getCheckoutSummaryModificationPrices(cartProduct);

    const isPriceModificationTotal = isPriceModification && !!priceModification;

    let shouldShowRowSumPrice = productQuantityPrice.toFixed(2) !== totalPrice.toFixed(2);

    if (shouldShowRowSumPrice && hasModifications) {
        shouldShowRowSumPrice = isAddonPriceModification;
    }

    const cartProductType = getCartProductType(cartProduct, productAddons.length > 0);
    const isSimpleProductWithModifications = CART_PRODUCT_TYPE.SIMPLE_MODIFIED_PRODUCT === cartProductType;
    const showModifyButton = !cartProductTypesToHideModify.includes(cartProductType);
    const showCommentButton = refBundleProduct ? checkIsSimpleBundleProduct(refBundleProduct, hasAddons) : true;

    return (
        <Flex flexDirection="column" maxW={orientationIsPortrait ? "952px" : "416px"} {...props}>
            <Flex onClick={() => !orientationIsPortrait && setShowQuantitySelector(!showQuantitySelector)}>
                {!orientationIsPortrait ? (
                    <MotionDiv
                        variants={imageVariants}
                        animate={!showQuantitySelector ? "selected" : "notSelected"}
                        //@ts-ignore
                        transition={{ duration: 0.3 }}
                    >
                        {Img}
                    </MotionDiv>
                ) : (
                    Img
                )}
                <MotionDiv
                    variants={textVariants}
                    animate={!orientationIsPortrait && showQuantitySelector ? "selected" : "notSelected"}
                    //@ts-ignore
                    transition={{ duration: 0.3 }}
                    width="100%"
                >
                    <Flex flexDirection="column">
                        <Flex
                            mb={2}
                            fontSize={orientationIsPortrait ? "xl" : "md"}
                            fontWeight="600"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            height={"max-content"}
                        >
                            <Text m={0} width="70%">
                                {quantity + " x " + name}
                            </Text>
                            {!orientationIsPortrait && (
                                <MotionDiv
                                    variants={quantityVariants}
                                    initial="notSelected"
                                    animate={!showQuantitySelector ? "notSelected" : "selected"}
                                    //@ts-ignore
                                    transition={{ duration: 0.3 }}
                                    style={{ marginTop: "-5px" }}
                                >
                                    <QuantitySelector />
                                </MotionDiv>
                            )}
                            <Flex alignItems="flex-start">
                                {orientationIsPortrait && (
                                    <ExpressButton
                                        size="sm"
                                        variant="outline"
                                        children={translate("delete")}
                                        rightIcon={FaTrash}
                                        marginTop={"-7px"}
                                        onClick={() => removeCartProduct(cartProduct)}
                                    />
                                )}
                                {orientationIsPortrait && <QuantitySelector />}
                                <Text
                                    width={!orientationIsPortrait ? "75px" : "100px"}
                                    m={0}
                                    display="flex"
                                    justifyContent={"flex-end"}
                                >
                                    {formatFinancialNumbers(
                                        isPriceModificationTotal ? priceModification : productQuantityPrice,
                                        companyLocale
                                    )}
                                </Text>
                            </Flex>
                        </Flex>
                        <ExpressCartContentCardDetails orderProduct={orderProduct} />
                        <Flex>
                            {orientationIsPortrait && showModifyButton && (
                                <Box mb={4} mr={2}>
                                    {ModifyButton}
                                </Box>
                            )}
                            {orientationIsPortrait && allowComment && showCommentButton && (
                                <MotionDiv
                                    variants={commentVariants}
                                    initial="notSelected"
                                    animate={showQuantitySelector ? "selected" : "notSelected"}
                                    //@ts-ignore
                                    transition={{ duration: 0.3 }}
                                    mb={4}
                                    style={{ display: showQuantitySelector ? "block" : "none" }}
                                >
                                    {CommentButton}
                                </MotionDiv>
                            )}
                        </Flex>
                        {orientationIsPortrait && shouldShowRowSumPrice && (
                            <Text
                                m={0}
                                textAlign="right"
                                fontSize={orientationIsPortrait ? "md" : "sm"}
                                fontWeight="600"
                            >
                                {formatFinancialNumbers(totalPrice, companyLocale)}
                            </Text>
                        )}
                    </Flex>
                </MotionDiv>
            </Flex>
            {!orientationIsPortrait && (
                <Flex alignItems="center" justifyContent="space-between" py={4}>
                    <Flex>
                        {showModifyButton && <Box mr={2}>{ModifyButton}</Box>}
                        {allowComment && showCommentButton && (
                            <MotionDiv
                                variants={commentVariants}
                                initial="notSelected"
                                animate={showQuantitySelector ? "selected" : "notSelected"}
                                //@ts-ignore
                                transition={{ duration: 0.3 }}
                                style={{ display: showQuantitySelector ? "block" : "none" }}
                            >
                                {CommentButton}
                            </MotionDiv>
                        )}
                    </Flex>
                    {shouldShowRowSumPrice && (
                        <Text
                            p={0}
                            m={0}
                            textAlign="right"
                            fontSize={orientationIsPortrait ? "md" : "sm"}
                            fontWeight="600"
                            {...(isSimpleProductWithModifications && { marginLeft: "auto" })}
                        >
                            {formatFinancialNumbers(totalPrice, companyLocale)}
                        </Text>
                    )}
                </Flex>
            )}
        </Flex>
    );
};
