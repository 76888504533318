import React from "react";
import { FaRegFilePdf } from "@react-icons/all-files/fa/FaRegFilePdf";

import { userAccountPDFGenerator } from "../../../reports/PDFGenerator";
import { NewGrid as Grid, Flex, Box } from "Atoms";
import { StandardText } from "./StandardText";
import { formatSwedishFinancialNumbers } from "PriceUtils";
import { SubscriptionOrder } from "../types";
import { useLanguage } from "Providers";
import { OnlineOrderStatus } from "Types";
import { useQoplaStore } from "Stores";
import { useMothershipQuery } from "Hooks";
import { GET_ORDER_BY_ID } from "GraphQLQueries";

export const OrderTile: React.FC<{ subscriptionOrder: SubscriptionOrder }> = ({ subscriptionOrder }) => {
    const { companyLocale } = useQoplaStore();
    const { translate } = useLanguage();

    const { refetch } = useMothershipQuery(GET_ORDER_BY_ID, { skip: true });

    const isSubscriptionOrder = subscriptionOrder.subscriptionPayments;
    const orderWasCancelled = subscriptionOrder.onlineOrderStatus === OnlineOrderStatus.CANCELLED;
    const typeTranslation = isSubscriptionOrder ? translate("subscriptions") : translate("altOrder");

    const generatePDF = async (orderId: string) => {
        const { data } = await refetch({ orderId });
        if (data?.getOrderById) {
            userAccountPDFGenerator(data?.getOrderById, translate, companyLocale);
        }
    };

    const Orders = () => {
        const { orderProducts } = subscriptionOrder;
        if (!orderProducts) {
            return <></>;
        }
        return (
            <>
                {orderProducts.map((product, index) => {
                    const productValue = `${product.quantity} x ${product.name}`;
                    const disountedFrom = product.combinedDiscounts ? product.combinedDiscounts[0].discountedFrom : 0;
                    return (
                        <Grid
                            gridTemplateColumns={["1fr", "1fr", "1fr repeat(2, 0.5fr)", "1fr repeat(2, 0.5fr)"]}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            key={index}
                        >
                            <StandardText>{productValue}</StandardText>
                            <StandardText
                                textAlign="center"
                                marginLeft={["none", "none", "auto", "auto"]}
                                style={{ textDecoration: "line-through" }}
                            >
                                {formatSwedishFinancialNumbers(disountedFrom ?? 0)}
                            </StandardText>
                            <StandardText color="orange.500">
                                {formatSwedishFinancialNumbers(product.totalPrice)}
                            </StandardText>
                        </Grid>
                    );
                })}
            </>
        );
    };

    const Payments = () => {
        const { subscriptionPayments } = subscriptionOrder;
        return (
            <Flex justifyContent="flex-start" alignItems="flex-start">
                <StandardText>{subscriptionPayments?.subscriptionMeta.name}</StandardText>
                <StandardText marginLeft="auto">
                    {formatSwedishFinancialNumbers(subscriptionPayments?.totalAmount ?? 0)}
                </StandardText>
                <Box
                    ml={2}
                    cursor="pointer"
                    width="1rem"
                    height="1rem"
                    as={FaRegFilePdf}
                    onClick={() => generatePDF(subscriptionPayments?.id!)}
                ></Box>
            </Flex>
        );
    };

    return (
        <Grid
            gridTemplateColumns={["1fr", "1fr", "1fr 2fr", "1fr 2fr"]}
            gap={[2, 0]}
            style={{ margin: "0.5rem 0.5rem", justifyContent: "center" }}
            alignItems="center"
            p={3}
        >
            <Flex direction="column" justifyContent="flex-start">
                <StandardText
                    p={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="lg"
                    backgroundColor={!isSubscriptionOrder ? "teal.500" : "yellow.500"}
                    color="white"
                >
                    {typeTranslation}
                </StandardText>
                <StandardText mt={2} marginRight="auto" marginLeft="auto">
                    {subscriptionOrder.purchaseDate.format("YYYY-MM-DD")}
                </StandardText>
                {orderWasCancelled && (
                    <StandardText mt={2} marginRight="auto" marginLeft="auto" color="red.500">
                        {translate("cancelled")}
                    </StandardText>
                )}
            </Flex>
            <Flex direction="column">{!isSubscriptionOrder ? <Orders /> : <Payments />}</Flex>
        </Grid>
    );
};
