import { confirmOrCancelNotification, printReceipt } from "TempUtils";
import {
    MenuProductCategory,
    MenuProduct,
    MenuBundleProduct,
    Order,
    Shop,
    SelectedPos,
    PosOrderDTO,
    PaymentTab,
    Terminal,
    OrderInput,
    PosOrderDTOInput
} from "Types";
import { flatProductsFromCategories, fromFractionizedAmount, getPickupDisplayMobileUrl } from "Utils";
import { PaymentMethod } from "Constants";

/**
 * Function to create the searched POS menuProductCategory based on
 * the user's search query
 * @param {MenuProductCategory[]} menuProductCategories Available menuProductCategories from menu
 * @param {string} productTextSearchValue Search query
 * @returns {MenuProductCategory} which holds the found menuProducts and menuBundleProducts based on the search query
 */
const getMenuProductCategoryFromSearchedProducts = (
    menuProductCategories: MenuProductCategory[],
    productTextSearchValue: string
): MenuProductCategory => {
    const flattenProducts = flatProductsFromCategories(menuProductCategories);

    const menuBundleProducts = flattenProducts.filter(
        product => "refBundleProduct" in product && product.refBundleProduct
    ) as MenuBundleProduct[];
    const menuProducts = flattenProducts.filter(
        product => "refProduct" in product && product.refProduct
    ) as MenuProduct[];

    const filteredMenuBundleProducts = menuBundleProducts.filter(product =>
        product.refBundleProduct.name.toLowerCase().includes(productTextSearchValue.toLowerCase())
    );
    const filteredMenuProducts = menuProducts.filter(product =>
        product.refProduct.name.toLowerCase().includes(productTextSearchValue.toLowerCase())
    );

    return {
        menuBundleProducts: filteredMenuBundleProducts ?? [],
        menuProducts: filteredMenuProducts ?? [],
        id: "",
        name: "",
        sortOrder: 0,
        upsellCategory: false,
        upsellCategoryAtPayment: false,
        posFavorite: false
    };
};

/**
 * Function to handle receipt printing based on the order and shop settings
 * @param {Order[]} order The order to be printed
 * @param {Shop} shop The shop settings - used to determine if online order url or pickupScreenUrl QR should be printed
 * @param {boolean} optionalReceipts If the user should be prompted to print an optional receipt
 * @param {SelectedPos} pos The selected POS - used to get receipt printer info
 */
const handleReceiptPrint = async (
    order: Order,
    shop: Shop,
    optionalReceipts: boolean,
    pos: SelectedPos,
    cashChangeAmount: number
) => {
    const restaurantOnlineOrderUrl = shop.settings.onlineSettings.restaurantOnlineOrderUrl ?? null;
    const enablePickUpScreenQrOnReceipt = shop.settings.enablePickUpScreenQrOnReceipt;
    const pickupDisplayUrl = enablePickUpScreenQrOnReceipt
        ? `${getPickupDisplayMobileUrl()}?orderNo=${order.orderNo}`
        : null;

    if (optionalReceipts) {
        const shouldPrintOptionalReceipt = await confirmOptionalReceipts();
        if (shouldPrintOptionalReceipt) {
            printReceipt(
                order,
                pos.receiptPrinter,
                null,
                null,
                cashChangeAmount,
                restaurantOnlineOrderUrl,
                pickupDisplayUrl
            );
        }
    } else {
        printReceipt(
            order,
            pos.receiptPrinter,
            null,
            null,
            cashChangeAmount,
            restaurantOnlineOrderUrl,
            pickupDisplayUrl
        );
    }
};

const confirmOptionalReceipts = async () => {
    const { value } = await confirmOrCancelNotification("Vill du ha kvitto?", "", "Ja", "#89CB9C", "Nej", "question");
    return value;
};

const addTipToCardPaymentMethod = (paymentMethodAmounts: PosOrderDTO["paymentMethodAmounts"], tip: number) => {
    return paymentMethodAmounts.map(paymentMethodAmount =>
        // add tip only to card payment
        paymentMethodAmount.paymentMethod === PaymentMethod.CARD
            ? {
                  paymentMethod: paymentMethodAmount.paymentMethod,
                  amount: paymentMethodAmount.amount + tip
              }
            : paymentMethodAmount
    );
};

const addTipToCardOrder = (order: PosOrderDTOInput, netsTip: number) => {
    const tip = fromFractionizedAmount(netsTip);
    // Append tip to paymentMethodAmounts
    return {
        ...order,
        tip,
        paymentMethodAmounts: addTipToCardPaymentMethod(order.paymentMethodAmounts!, tip)
    };
};

const lastPurchaseCheckWrapper = async (
    activePaymentTab: PaymentTab,
    posTerminals: Terminal[],
    baxiGetLastPurchase: (terminalId: string, approveThroughWebsocket?: boolean) => { order: OrderInput },
    sendOrderToBackendCallbackFn: (orderInput: OrderInput) => void
) => {
    const terminal = posTerminals.find(terminal => terminal.terminalId === activePaymentTab.terminalId);
    if (!terminal) {
        console.log("could not find terminal");
        return;
    }

    if (terminal?.cloudRESTEnabled) {
        const foundOrderContainer = await baxiGetLastPurchase(terminal.terminalId, false);
        console.log("foundorder", foundOrderContainer);
        if (foundOrderContainer && foundOrderContainer.order) {
            sendOrderToBackendCallbackFn(foundOrderContainer.order);
        }
    } else {
        // websocket solution, will auto approve which in turn will update the useEffect on terminalStates
        baxiGetLastPurchase(terminal.terminalId);
    }
};

export { getMenuProductCategoryFromSearchedProducts, handleReceiptPrint, addTipToCardOrder, lastPurchaseCheckWrapper };
