import { VALIDATE_USER_EMAIL } from "../../graphql/queries/Query";
import { VALIDATE_PRODUCT_ARTICLE_NUMBER } from "../../graphql/queries/ProductQueries";
import { VALIDATE_COMPANY_EMAIL, VALIDATE_ORGNR } from "../../graphql/queries/CompanyQueries";
import { GET_SHOP_BY_URL } from "GraphQLQueries";

export const validateLuhn = value => {
    return (
        value.split("").reduceRight(function (prev, curr, idx) {
            if ((idx + 1) % 2 !== 0) {
                curr = (curr * 2)
                    .toString()
                    .split("")
                    .reduce(function (p, c) {
                        return +p + +c;
                    });
            }
            return +prev + +curr;
        }) %
            10 ===
        0
    );
};

export async function validateOrganisationNumber(client, value) {
    const isOk = await client
        .query({
            query: VALIDATE_ORGNR,
            variables: { organisationNumber: value },
            fetchPolicy: "network-only"
        })
        .then(({ data }) => {
            return !data.validateOrganisationNumber;
        });
    return isOk;
}

export async function validateCompanyEmail(client, value) {
    const isOk = await client
        .query({
            query: VALIDATE_COMPANY_EMAIL,
            variables: { email: value },
            fetchPolicy: "network-only"
        })
        .then(({ data }) => {
            if (data.validateCompanyEmail) {
                return data.validateCompanyEmail.email !== value;
            }
            return true;
        });
    return isOk;
}

export async function validateArticleNumber(client, value, companyId) {
    const isOk = await client
        .query({
            query: VALIDATE_PRODUCT_ARTICLE_NUMBER,
            variables: { articleNumber: value, companyId },
            fetchPolicy: "network-only"
        })
        .then(({ data }) => {
            if (data.validateProductArticleNumber) {
                return data.validateProductArticleNumber !== parseInt(value);
            }
            return true;
        });
    return isOk;
}

export async function validateShopUrl(client, value) {
    const isOk = await client
        .query({
            query: GET_SHOP_BY_URL,
            variables: { url: value },
            fetchPolicy: "network-only"
        })
        .then(({ data }) => {
            return !!data.getShopByUrl;
        });
    return isOk;
}
