import create from "zustand";
import { v4 as uuidv4 } from "uuid";
import { flatMap, isEqual, uniqBy, get as getFromLodash } from "lodash";
import { createTrackedSelector } from "react-tracked";

import { PersistConstants, POS_MODE, SPLIT_TYPE } from "Constants";
import {
    createNewOrderWindow,
    getAllTableOrderWindows,
    getLocalOrderWindows,
    getNewOrderWindowId,
    getPostponedOrderWindows,
    getVisibleOrderWindows,
    getAllFromSplitModeOrderWindows,
    mergeOrderWindows,
    transformNestedOrderProductModifications,
    getInitialStockState,
    getOutOfStockProductIds,
    getOrderWindowsForPos,
    findCartProductInCartProducts,
    createCartProductHashId,
    createSimpleHashId,
    mergeCartProducts
} from "Providers/pos/utils";
import { calculateActiveOrderWindowDiscount } from "Providers/discountsProvider";
import {
    OnlineFixedDiscount,
    OrderWindow,
    UpsellDiscountOrderWindow,
    PotentialComboDiscount,
    UpsellDiscount,
    ComboDiscount,
    CartProduct,
    MenuBundleProduct,
    MenuProduct,
    Modification,
    Addon,
    DISCOUNT_TYPE,
    DISCOUNT_STATE,
    Menu,
    OnlineMenus,
    SplitCartProductsState,
    SplitOrderState,
    SplitState,
    ProductsStock,
    FixedDiscount,
    OrderWindowContactInformation,
    SplitOrderWindowDiscounts,
    StringOrNull,
    MenuProductCategory,
    CustomerMetaStatus,
    MenuProductHashValues,
    LastOrderProduct,
    UserSubscriptions,
    LastOrderIssue,
    LastOrderDTO,
    CartIdAndQuantity
} from "Types";
import { CartManager } from "./CartManager";
import { LastOrderManager } from "./LastOrderManager";
import {
    getProductCartQuantity,
    isProductOutOfStockInventory,
    findCartProduct,
    selectedBundleIdIsInOrderWindow,
    getPersistedObject,
    persistToStorage,
    calculateMenuProductOrBundlePrice
} from "Utils";
import {
    menuProductToCartProduct,
    menuProductToCartProductWithWeight,
    tempBundleProductOrderProduct,
    recalculatePriceAndDiscount,
    updateCombinedDiscountsAndOtherDiscounts,
    findFixedSubscriptionDiscount
} from "../admin/components/poses/shared/utils/cartController";
import { orderWindowSocketStore, posStore, qoplaStore, tableStore, onlineStore } from "Stores";
import { ScaleInputMode } from "../admin/components/poses/pos/components/ScaleModal/ScaleModal";
import { newMothershipApolloClient } from "../graphql/clients";
import { UPSERT_ORDER_WINDOW } from "GraphQLMutations";
import { ProductStockManager } from "./ProductStockManager";
import {
    calculateProductPrice,
    getDiscountedCartTotalAdjustedQuantities,
    getDiscountedCartTotalFromCartProducts,
    getDiscountedCartTotalOriginalPrice
} from "../admin/components/poses/pos/utils/priceUtils";
import { GET_SHOP_INVENTORY } from "GraphQLQueries";
import { getCombinedDiscountedProductIds } from "../providers/discountsProvider/utils/comboDiscountHelper";
import { getRefIdAndQuantityHashMap } from "./productStockManagerUtils";
import { isVariablePriceType } from "Utils/product";

export type OrderWindowsStore = {
    // Type this and maybe move to own file later?
    CartManager: any;
    ProductStockManager: any;
    LastOrderManager: LastOrderManager;

    // Temp store for discount values and helper functions
    comboDiscountState: DISCOUNT_STATE;
    setComboDiscountState: (discountState: DISCOUNT_STATE) => void;
    previousComboDiscountIds: Map<string, number>;
    setPreviousComboDiscountIds: (previousComboDiscountIds: Map<string, number>) => void;
    upsoldDiscounts: UpsellDiscountOrderWindow[];
    setUpsoldDiscounts: (upsoldDiscounts: UpsellDiscountOrderWindow[]) => void;
    comboDiscountIds: Map<string, number>;
    setComboDiscountIds: (comboDiscountIds: Map<string, number>) => void;
    potentialComboDiscounts: PotentialComboDiscount[];
    setPotentialComboDiscounts: (potentialComboDiscounts: PotentialComboDiscount[]) => void;
    allComboDiscounts: ComboDiscount[];
    setAllComboDiscounts: (allComboDiscounts: ComboDiscount[]) => void;
    potentialUpsellDiscounts: UpsellDiscount[];
    setPotentialUpsellDiscounts: (potentialUpsellDiscounts: UpsellDiscount[]) => void;
    activeFixedMenuDiscount: FixedDiscount | null;
    setActiveFixedMenuDiscount: (activeFixedMenuDiscount: FixedDiscount) => void;
    lastOrderIssues: LastOrderIssue[] | null;
    setLastOrderIssues: (lastOrderIssue: LastOrderIssue) => void;
    resetLastOrderIssues: () => void;
    // Move these later, they are here to prevent a dependency on PosProvider
    shopId: string | null;

    selectedBundleCartProduct: CartProduct | null;
    setSelectedBundleCartProduct: (cartProduct: CartProduct | null) => void;

    selectedCartProductWithMods: CartProduct | null;
    setSelectedCartProductWithMods: (cartProduct: CartProduct | null) => void;

    deselectSelectedBundleCartProduct: () => void;
    allActiveFixedDiscounts: OnlineFixedDiscount[];
    setAllActiveFixedDiscounts: (activeFixedDiscounts: OnlineFixedDiscount[]) => void;
    // This one can probably be refactore to NOT be a state?
    // => MenuStore
    productAmount: string;
    setProductAmount: (productAmount: string) => void;
    activeMenus: OnlineMenus;
    setActiveMenus: (activeMenus: OnlineMenus) => void;
    activeCategory: MenuProductCategory | null;
    setActiveCategory: (activeCategory: MenuProductCategory, shouldClearSelectedBundleProduct?: boolean) => void;
    menus: Menu[];
    setMenus: (activeMenus: Menu[]) => void;
    productsStock: ProductsStock;
    setProductsStock: (ProductsStock: ProductsStock) => void;
    getRemainingStockQuantity: (refProductId: string) => number | undefined;
    isRefProductOutOfStock: (refProductId: string, outOfStockIds: Set<string>) => boolean;
    refetchProductsStock: () => void;
    findMenuProductCategoryForMenuProduct: (menuProductId: string) => MenuProductCategory | undefined;

    alcoholProductIds: string[];
    setAlcoholProductIds: (alcoholProductIds: string[]) => void;

    splitOrder: SplitOrderState;
    setSplitOrder: (splitOrder: SplitOrderState) => void;

    splitUpsoldDiscounts: UpsellDiscountOrderWindow | null;
    setSplitUpsoldDiscounts: (splitUpsoldDiscounts: UpsellDiscountOrderWindow | null) => void;
    splitWindowDiscounts: SplitOrderWindowDiscounts | null;
    setSplitWindowDiscounts: (splitOrderWindowDiscounts: SplitOrderWindowDiscounts | null) => void;
    splitMode: SPLIT_TYPE;
    setSplitMode: (splitMode: SPLIT_TYPE) => void;
    isPaymentStarted: boolean;
    setIsPaymentStarted: (isPaymentStarted: boolean) => void;
    splitCartProducts: SplitCartProductsState;
    setSplitCartProducts: (splitCartProducts: SplitCartProductsState) => void;
    split: SplitState;
    setSplit: (split: SplitState) => void;
    addSplitOrderId: (orderId: string, orderNo: number) => void;

    isInSplitMode: boolean;

    lastOrders: LastOrderDTO[];
    setLastOrders: (lastOrders: LastOrderDTO[]) => void;

    orderWindows: OrderWindow[];
    activeOrderWindowId: string | null;
    recalculateOrderWindows(): void;
    updateOrderWindow: (orderWindowToUpdate: OrderWindow) => void;
    setOrderWindowsAndActiveOrderWindowId: (orderWindows: OrderWindow[], orderWindowId: string | null) => void;
    setOrderWindowsState: (orderWindows: OrderWindow[]) => void;
    setActiveOrderWindowId: (orderWindowId: string | null) => void;
    setShopId: (shopId: string | null) => void;
    getOrderWindow: (orderWindowId: string | null) => OrderWindow | undefined;
    getActiveOrderWindow: () => OrderWindow | undefined;
    isLocalOrderWindow: (orderWindowId: string) => boolean;
    clearOrderWindow: (
        orderWindowId: string,
        newActiveOrderWindowId?: string | null,
        newActiveOrderWindowState?: OrderWindow | null
    ) => OrderWindow[];
    clearOrderWindows: (
        orderWindowId: string[],
        newActiveOrderWindowId?: string | null,
        newActiveOrderWindowState?: OrderWindow | null
    ) => OrderWindow[];
    removeEmptyOrderWindow: () => void;
    emptyCart: (orderWindowId: StringOrNull, shouldClearPuckNo?: boolean) => OrderWindow | undefined;
    emptyOrderWindowCarts: (orderWindowIds: string[], shouldClearPuckNo: boolean) => OrderWindow[];
    addOrderWindow: (
        splitOrderWindowSourceId: string | null,
        isTakeAway: boolean,
        tableId: string | null
    ) => OrderWindow;
    setOrderWindows: (updatedOrderWindows: OrderWindow[], overriddenOrderWindows?: OrderWindow[]) => void;
    upsertOrderWindows: (orderWindowsToAdd: OrderWindow[]) => OrderWindow[];

    attachContactInformationToPostponedOrderWindow: (
        orderWindowId: string,
        postponeOrderId: string,
        contactInformation: OrderWindowContactInformation | null
    ) => OrderWindow;
    convertToPostponedOrderWindow: (orderWindow: OrderWindow, postponeOrderId: string) => OrderWindow;

    incrementOrderProduct: (cartProduct: CartProduct) => void;
    decrementOrderProduct: (cartProduct: CartProduct) => void;
    removeCartProduct: (cartProduct: CartProduct) => void;
    addBundleProductToCart: (
        bundleProduct: MenuBundleProduct,
        shopId: string,
        categoryId: string,
        hasBeenSearched: boolean
    ) => CartProduct;

    addOrderProductToCart: (
        menuProduct: MenuProduct,
        shopId: string,
        categoryId: string,
        selectedModifications: Modification | null,
        hasBeenSearched: boolean,
        comment: string | null | undefined,
        addons: Addon[],
        upsell: boolean,
        fixedDiscount: OnlineFixedDiscount | undefined,
        isStockTracked: boolean,
        nickname?: string,
        isLastOrderProduct?: boolean | null | undefined,
        quantity?: number
    ) => void;
    addOrderProductComment: (cardProductID: string, comment: string) => void;
    upsertBundleCartProductToCart: (
        bundleCartProduct: CartProduct,
        quantity: number,
        fixedDiscount: OnlineFixedDiscount | undefined,
        comment: string | null | undefined,
        addons: Addon[],
        isEditing: boolean,
        upsell?: boolean
    ) => void;

    addOrderProductToCartWithWeight: (
        menuProduct: MenuProduct,
        shopId: string,
        categoryId: string,
        weight: number,
        scaleType: ScaleInputMode
    ) => void;
    recalculateDiscountWithAddons: (orderWindow: any) => void;
    handleDiscountedIdChangeAndQuantities: (updatedOrderWindows: OrderWindow[]) => OrderWindow[];
    handleCartProductDiscountedQuantities: (orderWindow: OrderWindow) => void;
    handleUpdateAddonOnCartProduct: (cartProductToUpdate: CartProduct, orderWindow: OrderWindow) => void;
    updateAddonToCartProduct: (cartProduct: CartProduct) => void;
    handleEditMenuProduct: (cartProduct: CartProduct) => void;
    editProduct: (cartProduct: CartProduct) => void;
    toggleTakeAway: (orderWindowId: string) => void;
    setTakeAwayOnActiveOrderWindow: (takeAway: boolean) => void;
    addCartToDTPreviousCart: (orderWindowId?: string) => void;
    updateOrderWindowPuckNo: (orderWindowId: string, puckNo: string) => void;
    addLastOrderProductsToCart: (orderId: string, userSubscriptions?: UserSubscriptions) => void;
};

export const orderWindowsStore = create<OrderWindowsStore>(
    // Had to not use the devtools here until we can resolve which state is being set to a huge object
    //  Serialization can be super slow in some cases.
    //  We might have access to a sanitizer via Zustand.  Haven't checked yet
    //  Read this https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/Troubleshooting.md#excessive-use-of-memory-and-cpu
    // wrapZustandStoreWithDevToolsIfNotProd((set, get) => ({
    (set, get) => ({
        CartManager: new CartManager(get),
        ProductStockManager: new ProductStockManager(get),
        LastOrderManager: new LastOrderManager(get),

        comboDiscountState: DISCOUNT_STATE.NONE,
        setComboDiscountState: (comboDiscountState: DISCOUNT_STATE) => set(() => ({ comboDiscountState })),
        previousComboDiscountIds: new Map(),
        setPreviousComboDiscountIds: (previousComboDiscountIds: Map<string, number>) =>
            set(() => ({ previousComboDiscountIds })),
        upsoldDiscounts: [],
        setUpsoldDiscounts: (upsoldDiscounts: UpsellDiscountOrderWindow[]) => set(() => ({ upsoldDiscounts })),
        comboDiscountIds: new Map(),
        setComboDiscountIds: (comboDiscountIds: Map<string, number>) => set(() => ({ comboDiscountIds })),
        potentialComboDiscounts: [],
        setPotentialComboDiscounts: (potentialComboDiscounts: PotentialComboDiscount[]) =>
            set(() => ({ potentialComboDiscounts })),
        allComboDiscounts: [],
        setAllComboDiscounts: (allComboDiscounts: ComboDiscount[]) => set(() => ({ allComboDiscounts })),
        activeFixedMenuDiscount: null,
        setActiveFixedMenuDiscount: (activeFixedMenuDiscount: FixedDiscount | null) =>
            set(() => ({ activeFixedMenuDiscount })),
        lastOrderIssues: [],
        setLastOrderIssues: (lastOrderIssue: LastOrderIssue) => {
            const productId =
                lastOrderIssue.lastOrderProduct?.refProductId || lastOrderIssue.lastOrderProduct.refBundleProductId!;
            const issueId = createSimpleHashId([productId, lastOrderIssue.lastOrderProductReason]);
            const newState = get().lastOrderIssues;
            const exists = newState?.some(issue => issue.id === issueId);
            if (!exists) {
                set(() => ({
                    lastOrderIssues: [...(newState ?? []), { ...lastOrderIssue, id: issueId, productId: productId }]
                }));
            }
        },
        resetLastOrderIssues: () => set(() => ({ lastOrderIssues: [] })),

        alcoholProductIds: [],
        setAlcoholProductIds: (alcoholProductIds: string[]) => set(() => ({ alcoholProductIds })),

        productAmount: "",
        setProductAmount: (productAmount: string) => set(() => ({ productAmount })),
        activeMenus: [],
        setActiveMenus: (activeMenus: OnlineMenus) => set(() => ({ activeMenus })),
        activeCategory: null,
        setActiveCategory: (activeCategory: MenuProductCategory, shouldClearSelectedBundleProduct: boolean = true) =>
            set(() => {
                shouldClearSelectedBundleProduct && get().setSelectedBundleCartProduct(null);
                return { activeCategory };
            }),
        menus: [],
        setMenus: (menus: Menu[]) => set(() => ({ menus })),
        productsStock: new Map(),
        setProductsStock: (productsStock: ProductsStock) => set(() => ({ productsStock })),
        getRemainingStockQuantity: (refProductId: string) => {
            return get().productsStock.get(refProductId)?.remainingQuantity;
        },
        isRefProductOutOfStock: (refProductId: string, outOfStockIds: Set<string>) => {
            const isOutOfStock = outOfStockIds?.has(refProductId);
            const remainingQuantity = get().getRemainingStockQuantity(refProductId);

            if (isOutOfStock || isProductOutOfStockInventory(remainingQuantity)) {
                return true;
            } else {
                return false;
            }
        },
        refetchProductsStock: async () => {
            const { orderWindows, setProductsStock } = get();
            const { outOfStockIds, setOutOfStockIds } = posStore.getState();
            const { selectedShop } = qoplaStore.getState();
            const shopId = selectedShop?.id;

            if (!shopId) {
                return;
            }

            const response = await newMothershipApolloClient.query({
                query: GET_SHOP_INVENTORY,
                variables: {
                    shopId
                },
                fetchPolicy: "no-cache"
            });

            const data = response?.data;

            if (!data?.getShopOverride) {
                return;
            }

            const {
                getShopOverride: { outOfStockProductIds, inventoryStock, outOfStockAddonNames }
            } = data;

            const hasStockTracking = inventoryStock.length > 0;

            const initialStockMap = getInitialStockState(data);

            if (hasStockTracking) {
                const allCartProducts = flatMap(orderWindows, orderWindow => orderWindow?.cartProducts);
                const amountToRemove = getRefIdAndQuantityHashMap(allCartProducts);

                for (let [refId, { remainingQuantity, initialQuantity }] of initialStockMap) {
                    if (amountToRemove[refId]) {
                        const quantity = amountToRemove[refId];
                        initialStockMap.set(refId, {
                            initialQuantity,
                            remainingQuantity: remainingQuantity - quantity
                        });
                    }
                }
            }
            setProductsStock(initialStockMap);

            const _outOfStockProductIds = getOutOfStockProductIds(initialStockMap, "remainingQuantity")
                .concat(outOfStockProductIds)
                .concat(outOfStockAddonNames);

            const outOfStockProductsChanged =
                _outOfStockProductIds.length !== outOfStockIds.size &&
                (_outOfStockProductIds.some((item: string) => !outOfStockIds.has(item)) ||
                    [...outOfStockIds].some((item: string) => !_outOfStockProductIds.includes(item)));

            const hasOutOfStockProducts = _outOfStockProductIds.length > 0;
            if (hasOutOfStockProducts) {
                setOutOfStockIds(new Set([...outOfStockIds, ..._outOfStockProductIds]));
            }

            if (outOfStockProductsChanged) {
                setOutOfStockIds(new Set([..._outOfStockProductIds]));
            }
        },
        findMenuProductCategoryForMenuProduct: (menuProductId: string) => {
            const menus = get().menus;

            const categories = menus.flatMap(activeMenu => activeMenu.menuProductCategories);

            return categories.find(category => {
                const foundMenuProduct = category.menuProducts.find(menuProduct => menuProduct.id === menuProductId);
                const foundMenuBundleProduct = category.menuBundleProducts.find(
                    menuBundleProduct => menuBundleProduct.id === menuProductId
                );

                return foundMenuBundleProduct || foundMenuProduct;
            });
        },

        potentialUpsellDiscounts: [],
        setPotentialUpsellDiscounts: (potentialUpsellDiscounts: UpsellDiscount[]) =>
            set(() => ({ potentialUpsellDiscounts })),

        shopId: null,
        selectedBundleCartProduct: null,
        setSelectedBundleCartProduct: (selectedBundleCartProduct: CartProduct | null) =>
            set(() => ({ selectedBundleCartProduct })),
        deselectSelectedBundleCartProduct: () => {
            if (
                get().selectedBundleCartProduct &&
                selectedBundleIdIsInOrderWindow(
                    get().selectedBundleCartProduct,
                    get().orderWindows,
                    get().activeOrderWindowId
                )
            ) {
                get().setSelectedBundleCartProduct(null);
            }
        },
        selectedCartProductWithMods: null,
        setSelectedCartProductWithMods: (selectedCartProductWithMods: CartProduct | null) =>
            set(() => ({ selectedCartProductWithMods })),
        allActiveFixedDiscounts: [],
        setAllActiveFixedDiscounts: (activeFixedDiscounts: OnlineFixedDiscount[]) => {
            const getUniqActiveFixedDiscounts = (activeFixedDiscounts: OnlineFixedDiscount[]) => {
                return uniqBy(activeFixedDiscounts, discount => {
                    if (discount.type === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT) {
                        return [discount.name, discount.rate, discount.amount].join();
                    } else {
                        return [discount.id].join();
                    }
                });
            };

            const currActiveFixedDiscounts = get().allActiveFixedDiscounts;
            const allFixedDiscounts = [...currActiveFixedDiscounts, ...activeFixedDiscounts];

            const activeFixedDiscountsIds = activeFixedDiscounts.map(discount => discount.id);

            const allActiveFixedDiscounts = allFixedDiscounts.filter(discount => {
                return discount.type === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT
                    ? discount.type === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT
                    : activeFixedDiscountsIds.includes(discount.id);
            });

            set(() => ({ allActiveFixedDiscounts: getUniqActiveFixedDiscounts(allActiveFixedDiscounts) }));
        },

        splitOrder: {
            ids: [],
            orderNo: null
        },
        setSplitOrder: (splitOrder: SplitOrderState) => set(() => ({ splitOrder })),

        splitUpsoldDiscounts: null,
        setSplitUpsoldDiscounts: (splitUpsoldDiscounts: UpsellDiscountOrderWindow | null) =>
            set(() => ({ splitUpsoldDiscounts })),
        splitWindowDiscounts: null,
        setSplitWindowDiscounts: (splitWindowDiscounts: SplitOrderWindowDiscounts | null) =>
            set(() => ({ splitWindowDiscounts })),
        splitMode: SPLIT_TYPE.DISHES,
        setSplitMode: (splitMode: SPLIT_TYPE) => set(() => ({ splitMode })),
        isPaymentStarted: false,
        setIsPaymentStarted: (isPaymentStarted: boolean) => set(() => ({ isPaymentStarted })),
        splitCartProducts: {
            toPay: [],
            available: []
        },
        setSplitCartProducts: (splitCartProducts: SplitCartProductsState) => set(() => ({ splitCartProducts })),
        split: {
            mode: POS_MODE.REGULAR,
            fromOrderWindowId: null,
            to: []
        },
        setSplit: (split: SplitState) =>
            set(() => {
                return { split, isInSplitMode: split.mode == POS_MODE.SPLIT };
            }),
        addSplitOrderId: (orderId: string, orderNo: number) => {
            const isFirstOrder = get().splitOrder.ids.length === 0;

            get().setSplitOrder({
                ids: get().splitOrder.ids.concat(orderId),
                orderNo: isFirstOrder ? orderNo : get().splitOrder.orderNo
            });
        },
        isInSplitMode: false,

        lastOrders: [],
        setLastOrders: (lastOrders: LastOrderDTO[]) => set(() => ({ lastOrders })),

        orderWindows: [],
        activeOrderWindowId: "",
        setOrderWindowsAndActiveOrderWindowId: (orderWindows: OrderWindow[], orderWindowId: string | null) => {
            set(() => {
                const newState = combineOrderWindowsAndActiveOrderWindowIdState(get(), orderWindowId, orderWindows);
                return newState;
            });
        },
        setOrderWindowsState: (orderWindows: OrderWindow[]) => {
            set(() => {
                const newState = combineOrderWindowsAndActiveOrderWindowIdState(
                    get(),
                    get().activeOrderWindowId,
                    orderWindows
                );

                return newState;
            });
        },
        setActiveOrderWindowId: (activeOrderWindowId: string | null) => {
            set(() => {
                const newState = combineOrderWindowsAndActiveOrderWindowIdState(
                    get(),
                    activeOrderWindowId,
                    get().orderWindows
                );
                return newState;
            });
        },
        setShopId: (shopId: string | null) =>
            set(() => {
                return {
                    shopId
                };
            }),
        getActiveOrderWindow: () => {
            return get().getOrderWindow(get().activeOrderWindowId);
        },
        getOrderWindow: orderWindowId => get().orderWindows.find(orderWindow => orderWindow.id == orderWindowId),
        isLocalOrderWindow: (orderWindowId: string) => {
            return !!getLocalOrderWindows(get().orderWindows).find(orderWindow => orderWindow.id === orderWindowId);
        },
        updateWithFetchedOrderWindows: (fetchedOrderWindows: OrderWindow[]) => {
            set(state => {
                const orderWindows = state.orderWindows;
                const activeOrderWindow = state.getActiveOrderWindow();
                const selectedPos = posStore.getState().selectedPos;

                const isDriveThroughPOS = selectedPos?.postponePaymentEnabled ?? false;

                const isTableOpen = tableStore.getState().isTableOpen;

                // These are the OrderWindows directly from the backend
                // See the `transformNestedOrderProductModifications` function for more details about why we can't use the `rawOrderWindows` directly
                const transformedOrderWindows = transformNestedOrderProductModifications(
                    getOrderWindowsForPos(isDriveThroughPOS, fetchedOrderWindows)
                );
                const inactiveOrderWindows = transformedOrderWindows.filter((orderWindow: OrderWindow) => {
                    // We don't want to change orderWindows that are active
                    const isNotActiveOrderWindow = orderWindow.id != activeOrderWindow?.id;
                    // We don't want to update orderWindows while we have the table open
                    const isDifferentTable = isTableOpen ? orderWindow.tableId !== activeOrderWindow?.tableId : true;
                    // We can always allow hidden orderWindows to be updated in the background
                    //   This is important to allow parked orders to reload correctly when fetched, they are hidden
                    const isHidden = orderWindow.shouldHide;

                    return (isNotActiveOrderWindow && isDifferentTable) || isHidden;
                });
                // ONLY update the inactive order windows -- i.e. the ones we are not currently viewing

                // Get all table and postponed orderWindows locally
                const tableAndPostponedOrderWindows = [
                    ...getAllTableOrderWindows(orderWindows),
                    ...getPostponedOrderWindows(orderWindows),
                    ...getAllFromSplitModeOrderWindows(orderWindows)
                    // ...getAllTableOrderWindows(orderWindowsRef.current),
                    // ...getPostponedOrderWindows(orderWindowsRef.current)
                ];

                // Find local order windows that don't exist in the fetchedOrderWindows
                // which will be removed from local orderWindows
                const orderWindowIdsToRemove = tableAndPostponedOrderWindows
                    .filter(
                        orderWindow =>
                            // Not sure what this line achieves. Perhaps preventing the split mode order window from being removed?
                            !orderWindow.fromSplitOrderWindowId &&
                            !transformedOrderWindows.find(
                                (fetchedOrderWindow: OrderWindow) => fetchedOrderWindow.id === orderWindow.id
                            )
                    )
                    .filter(orderWindow => orderWindow.id !== activeOrderWindow?.id)
                    .map(orderWindow => orderWindow.id);

                if (!!orderWindowIdsToRemove.length) {
                    // Can't use upsert since upsert doesn't delete orderWindows.
                    // Need updated state from clearOrderWindows otherwise calling clearOrderWindows
                    // and upsertOrderWindows causes a race condition in the state
                    get().clearOrderWindows(orderWindowIdsToRemove);
                }
                get().upsertOrderWindows(inactiveOrderWindows);
            });
        },

        /**
         * Empties the cart if there are no parked or table orderWindows
         * NOTE: This seems awfully similar to `removeOrderWindow`
         */
        clearOrderWindow: (orderWindowId: string, newActiveOrderWindowId = null, newActiveOrderWindowState = null) => {
            return get().clearOrderWindows([orderWindowId], newActiveOrderWindowId, newActiveOrderWindowState);
        },
        clearOrderWindows: (
            orderWindowIds: string[],
            newActiveOrderWindowId = null,
            newActiveOrderWindowState = null
        ) => {
            const { closeTable, selectedTable } = tableStore.getState();
            const activeOrderWindow = get().getActiveOrderWindow();
            const tableId = activeOrderWindow?.tableId;
            const orderWindows = get().orderWindows;
            const activeOrderWindowIndex = getVisibleOrderWindows(
                // orderWindowsRef.current,
                orderWindows,
                tableId
            ).findIndex(orderWindow => orderWindow.id === activeOrderWindow?.id);

            // Remove specific orderWindows
            // let filteredOrderWindows = orderWindowsRef.current.filter(
            let filteredOrderWindows = orderWindows.filter(orderWindow => !orderWindowIds.includes(orderWindow.id));

            // Upsert the deleted orderWindow to the backend
            orderWindowIds.forEach(orderWindowId => {
                const orderWindowToDelete = get().getOrderWindow(orderWindowId);
                const shouldUpsertOrderWindow = !get().isLocalOrderWindow(orderWindowId);

                if (shouldUpsertOrderWindow) {
                    newMothershipApolloClient.mutate({
                        mutation: UPSERT_ORDER_WINDOW,
                        variables: { orderWindow: { ...orderWindowToDelete, deleted: true, disabled: true } }
                    });
                }
            });

            // Overwrite the activeOrderWindowState with the third optional argument
            if (newActiveOrderWindowState) {
                filteredOrderWindows = filteredOrderWindows.map(orderWindow =>
                    orderWindow.id == newActiveOrderWindowId ? newActiveOrderWindowState : orderWindow
                );
            }

            persistToStorage(PersistConstants.ORDER_WINDOWS, getLocalOrderWindows(filteredOrderWindows));

            // Chose new active orderWindow
            const visibleOrderWindows = getVisibleOrderWindows(filteredOrderWindows, tableId);
            if (newActiveOrderWindowId) {
                get().setOrderWindowsAndActiveOrderWindowId(filteredOrderWindows, newActiveOrderWindowId);
            } else if (visibleOrderWindows.length > 0) {
                const nextAvailableVisibleOrderWindowId = getNewOrderWindowId(
                    activeOrderWindowIndex,
                    visibleOrderWindows
                );
                get().setOrderWindowsAndActiveOrderWindowId(filteredOrderWindows, nextAvailableVisibleOrderWindowId);
            } else {
                // No next activeOrderWindow and no visibleOrderWindows
                get().setOrderWindowsAndActiveOrderWindowId(filteredOrderWindows, null);
                if (!!selectedTable) {
                    closeTable(selectedTable);
                }
            }

            return filteredOrderWindows;
        },
        removeEmptyOrderWindow: () => {
            const filteredOrderWindows = get().orderWindows.filter(
                orderWindow => orderWindow?.cartProducts?.length !== 0
            );

            get().setActiveOrderWindowId(filteredOrderWindows[filteredOrderWindows.length - 1].id);
            get().setOrderWindowsState(filteredOrderWindows);
        },

        /**
         * TODO: Rename this function to `emptyOrderWindowCart`
         */
        emptyCart: (orderWindowId, shouldClearPuckNo = true) => {
            if (get().comboDiscountState === DISCOUNT_STATE.OFF) {
                get().setComboDiscountState(DISCOUNT_STATE.ON);
            }
            if (!orderWindowId) {
                return;
            }
            return get()
                .emptyOrderWindowCarts([orderWindowId], shouldClearPuckNo)
                .find(orderWindow => orderWindowId == orderWindow.id);
        },
        emptyOrderWindowCarts: (orderWindowIds: string[], shouldClearPuckNo = true) => {
            const selectedPos = posStore.getState().selectedPos;
            const updatedOrderWindows = get().orderWindows.map(orderWindow =>
                orderWindowIds.includes(orderWindow.id)
                    ? ({
                          ...orderWindow,
                          discount: null,
                          discountedIdsAndQuantity: [],
                          fixedDiscounts: undefined,
                          discountedProductIds: [],
                          fixedDiscountedProductIds: [],
                          comboDiscounts: [],
                          comboDiscountedProductIds: [],
                          cartProducts: [],
                          takeAway: selectedPos?.preferTakeAway || false,
                          postponeOrderId: orderWindow.postponeOrderId ? orderWindow.postponeOrderId : null,
                          puckNo: shouldClearPuckNo ? null : orderWindow.puckNo
                      } as OrderWindow)
                    : orderWindow
            );
            get().setSelectedBundleCartProduct(null);
            return get().upsertOrderWindows(updatedOrderWindows);
        },
        recalculateOrderWindows: () => {
            get().setOrderWindows(get().orderWindows);
        },
        updateOrderWindow: (orderWindowToUpdate: OrderWindow) => {
            const updatedOrderWindows = get().orderWindows.map(orderWindow =>
                orderWindow.id === orderWindowToUpdate.id ? orderWindowToUpdate : orderWindow
            );
            get().setOrderWindows(updatedOrderWindows);
        },
        // TODO - Handle removal of orderWindows
        // We might want not want to merge updatedOrderWindows with current ones.
        // Therefor you can pass in the orderWindows to override the current ones
        setOrderWindows: (updatedOrderWindows = [], overriddenOrderWindows = []) => {
            /**
             * We shall no longer persist the table or parked order windows to local storage:
             * 1. We want to share parked orders across multiple terminals
             * 2. We want to share table orders across multiple terminals
             *
             * Note: for parked orders, we need to fetch orderWindows by shopId
             */

            if (overriddenOrderWindows.length > 0) {
                const orderWindowsToPersistLocally = getLocalOrderWindows(overriddenOrderWindows);
                persistToStorage(PersistConstants.ORDER_WINDOWS, orderWindowsToPersistLocally);

                get().setOrderWindowsState(overriddenOrderWindows);
            } else {
                const modifiedOrderWindowIds = updatedOrderWindows.map((ow: OrderWindow) => ow.id);

                const unmodifiedOrderWindows = get().orderWindows.filter(
                    orderWindow => !modifiedOrderWindowIds.includes(orderWindow.id)
                );

                const allOrderWindows = [...unmodifiedOrderWindows, ...updatedOrderWindows];

                const localOrderWindows = getLocalOrderWindows(allOrderWindows);
                persistToStorage(PersistConstants.ORDER_WINDOWS, localOrderWindows);

                get().setOrderWindowsState(allOrderWindows);
            }
        },
        upsertOrderWindows: (orderWindowsToAdd: OrderWindow[]) => {
            const mergedOrderWindows = mergeOrderWindows(get().orderWindows, orderWindowsToAdd);
            get().setOrderWindows(mergedOrderWindows);
            return mergedOrderWindows;
        },
        addOrderWindow: (splitOrderWindowSourceId = null, isTakeAway = false, tableId = null) => {
            const shopId = qoplaStore.getState().selectedShop?.id;
            // const visibleOrderWindows = getVisibleOrderWindows(orderWindowsRef.current, tableId);
            const visibleOrderWindows = getVisibleOrderWindows(
                get().orderWindows,
                tableId || get().getActiveOrderWindow()?.tableId
            );

            const splitOrderWindow = get().getOrderWindow(splitOrderWindowSourceId);
            const newOrderWindow = createNewOrderWindow(
                // A better solution is to move the shopId to its own store with "selectedXXXXX" information
                //  We are doing this for now, but it relies on shopId being set at application startup
                shopId || "",
                visibleOrderWindows,
                isTakeAway,
                splitOrderWindow,
                tableId
            );
            const updatedOrderWindows = [...get().orderWindows, newOrderWindow];

            get().setOrderWindows(updatedOrderWindows);
            // handleSetActiveOrderWindowId(newOrderWindow.id);
            get().setActiveOrderWindowId(newOrderWindow.id);

            return newOrderWindow;
        },
        /*
        Current orderWindow contactInformation looks like this:
        contactInformation: {
            phoneNumber,
            name,
            comment,
            pickupTime
        }

        To keep things consistent in our application we should have the same fields as in Mothership,
        meaning, not have comment and pickupTime. That should be on the OrderWindow itself
    */
        attachContactInformationToPostponedOrderWindow: (
            orderWindowId: string,
            postponeOrderId: string,
            contactInformation: OrderWindowContactInformation | null
        ) => {
            const orderWindow = get().getOrderWindow(orderWindowId) as OrderWindow;
            const postponedOrderWindow = get().convertToPostponedOrderWindow(orderWindow, postponeOrderId);

            const updatedOrderWindow = {
                ...postponedOrderWindow,
                contactInformation: contactInformation
                    ? {
                          name: contactInformation.name,
                          phoneNumber: contactInformation.phoneNumber
                      }
                    : null,
                comment: contactInformation?.comment ?? null,
                pickupTime: contactInformation?.pickupTime ?? null
            } as OrderWindow;
            get().updateOrderWindow(updatedOrderWindow);

            return updatedOrderWindow;
        },
        convertToPostponedOrderWindow: (orderWindow: OrderWindow, postponeOrderId: string) => {
            const updatedOrderWindow = {
                ...orderWindow,
                previousCartProducts: orderWindow?.cartProducts,
                postponeOrderId,
                postponePayment: true
            } as OrderWindow;
            return updatedOrderWindow;
        },
        incrementOrderProduct: (cartProduct: CartProduct) => {
            if (cartProduct?.orderProduct.isStockTracked) {
                get().ProductStockManager.onUpdateRemainingStockQuantity(cartProduct, true);
            }
            const updatedOrderWindows = get().CartManager.handleCartProduct(
                cartProduct,
                get().CartManager.handleIncrementOrderProductQuantity
            );
            get().setOrderWindows(updatedOrderWindows);

            const updatedCartProduct: CartProduct = get().CartManager.getCartProduct(cartProduct.id);
            if (updatedCartProduct.orderProduct.isStockTracked) {
                get().ProductStockManager.onUpdateRemainingStockQuantity(updatedCartProduct);
            }
            if (get().selectedBundleCartProduct) {
                get().setSelectedBundleCartProduct(updatedCartProduct);
            }

            orderWindowSocketStore.getState().onOrderWindowUpdated(get().getActiveOrderWindow());
        },

        decrementOrderProduct: (cartProduct: CartProduct) => {
            if (cartProduct?.orderProduct.isStockTracked) {
                get().ProductStockManager.onUpdateRemainingStockQuantity(cartProduct, true);
            }
            const updatedOrderWindows: OrderWindow[] = get().CartManager.handleCartProduct(
                cartProduct,
                get().CartManager.handleDecrementOrderProductQuantity
            );

            get().setOrderWindows(updatedOrderWindows);

            const updatedCartProduct: CartProduct = get().CartManager.getCartProduct(cartProduct.id);
            if (updatedCartProduct?.orderProduct.isStockTracked) {
                get().ProductStockManager.onUpdateRemainingStockQuantity(updatedCartProduct);
            }
            if (get().selectedBundleCartProduct) {
                get().setSelectedBundleCartProduct(updatedCartProduct);
            }

            orderWindowSocketStore.getState().onOrderWindowUpdated(get().getActiveOrderWindow());
        },

        removeCartProduct: (cartProduct: CartProduct) => {
            if (cartProduct.id === get().selectedBundleCartProduct?.id) {
                get().setSelectedBundleCartProduct(null);
            }
            const updatedOrderWindows = get().CartManager.handleCartProduct(
                [cartProduct],
                get().CartManager.handleDeleteOrderProducts
            );
            get().setOrderWindows(updatedOrderWindows);

            if (cartProduct?.orderProduct?.isStockTracked) {
                get().ProductStockManager.onUpdateRemainingStockQuantity(cartProduct, true);
            }

            orderWindowSocketStore.getState().onOrderWindowUpdated(get().getActiveOrderWindow());
        },
        addBundleProductToCart: (
            bundleProduct: MenuBundleProduct,
            shopId: string,
            categoryId: string,
            hasBeenSearched: boolean
        ) => {
            const newQuantity = getProductCartQuantity(get().productAmount, false, hasBeenSearched);
            const tempBundleCartProduct = tempBundleProductOrderProduct(bundleProduct, shopId, categoryId, newQuantity);
            const updatedOrderWindows = get().CartManager.handleCartProduct(
                tempBundleCartProduct,
                get().CartManager.handleAddNewCartProduct
            );
            get().setOrderWindows(updatedOrderWindows);
            return tempBundleCartProduct as CartProduct;
        },

        // Since we create a temp bundleCartProduct we will send in that, not a menuBundleProduct
        // This function is responsible to attach addons, comment, update prices etc
        upsertBundleCartProductToCart: (
            bundleCartProduct: CartProduct,
            quantity: number,
            fixedDiscount: OnlineFixedDiscount | undefined,
            comment: string | null | undefined,
            addons: Addon[],
            isEditing: boolean,
            upsell: boolean = false
        ) => {
            const activeOrderWindow = get().getActiveOrderWindow();
            const ProductStockManager = get().ProductStockManager;
            const cartTotalOriginalPrice = getDiscountedCartTotalOriginalPrice(activeOrderWindow);

            const hashValueExtra = !!fixedDiscount ? { type: fixedDiscount.type } : null;

            const isLastOrderProduct = !!bundleCartProduct.orderProduct?.isLastOrderProduct
                ? bundleCartProduct.orderProduct?.isLastOrderProduct
                : false;

            const bundleItemToHash = {
                ...bundleCartProduct,
                isFinished: true,
                fixedDiscount,
                orderProduct: {
                    ...bundleCartProduct.orderProduct,
                    addons: addons,
                    comment: comment
                }
            } as CartProduct;

            const newBundleCartProductId = createCartProductHashId(
                bundleItemToHash,
                get().activeOrderWindowId!,
                hashValueExtra
            );

            const hasBundleCartProductIdChanged = bundleCartProduct.id != newBundleCartProductId;

            const cartProductExists = isEditing
                ? bundleCartProduct
                : findCartProduct(activeOrderWindow, newBundleCartProductId);
            const newQuantity = isEditing
                ? Number(quantity)
                : Number(getProductCartQuantity(quantity, cartProductExists, false));
            const hasUpdatedUnitPrice = !!cartProductExists?.updatedUnitPrice;

            const pricesAndVats = calculateProductPrice(
                bundleCartProduct.menuBundleProduct,
                newQuantity,
                null,
                bundleCartProduct.orderProduct.selectedBundleProductItems,
                addons,
                hasUpdatedUnitPrice ? cartProductExists.orderProduct.unitPrice : null
            );

            let updatedBundleCartProduct = {
                ...bundleCartProduct,
                isFinished: true,
                id: newBundleCartProductId,
                // We only want to update the oldId if the new id has changed
                oldId: hasBundleCartProductIdChanged ? bundleCartProduct.id : bundleCartProduct.oldId,
                fixedDiscount,
                orderProduct: {
                    ...bundleCartProduct.orderProduct,
                    ...pricesAndVats,
                    addons: addons,
                    quantity: newQuantity,
                    comment,
                    isLastOrderProduct,
                    upsell
                }
            } as CartProduct;

            if (activeOrderWindow?.discount) {
                const updatedCartOriginalPrice =
                    cartTotalOriginalPrice + updatedBundleCartProduct.orderProduct.unitPrice;
                updatedBundleCartProduct = recalculatePriceAndDiscount(
                    updatedBundleCartProduct,
                    activeOrderWindow.discount,
                    activeOrderWindow.discountedProductIds,
                    updatedCartOriginalPrice,
                    activeOrderWindow?.discountedIdsAndQuantity ?? null
                ) as CartProduct;
            }

            let updatedOrderWindows;
            if (cartProductExists) {
                const cartProductToIncrement = hasUpdatedUnitPrice
                    ? {
                          ...updatedBundleCartProduct,
                          orderProduct: {
                              ...updatedBundleCartProduct.orderProduct,
                              unitPrice: cartProductExists.orderProduct.unitPrice
                          },
                          updatedUnitPrice: hasUpdatedUnitPrice
                      }
                    : updatedBundleCartProduct;
                updatedOrderWindows = get().CartManager.handleCartProduct(
                    cartProductToIncrement,
                    get().CartManager.handleUpdateCartProduct
                );
            } else {
                updatedOrderWindows = get().CartManager.handleCartProduct(
                    updatedBundleCartProduct,
                    get().CartManager.handleAddNewCartProduct
                );
            }

            get().setOrderWindows(updatedOrderWindows);

            // Check stock status
            if (bundleCartProduct.orderProduct.isStockTracked) {
                ProductStockManager.onUpdateRemainingStockQuantity(updatedBundleCartProduct);
            }

            orderWindowSocketStore.getState().onOrderWindowUpdated(get().getActiveOrderWindow());
        },
        addOrderProductToCart: (
            menuProduct: MenuProduct,
            shopId: string,
            categoryId: string,
            selectedModifications: Modification | null | undefined,
            hasBeenSearched: boolean,
            comment: string | null | undefined,
            addons: Addon[],
            upsell: boolean,
            fixedDiscount: OnlineFixedDiscount | undefined,
            isStockTracked: boolean,
            nickname?: string,
            isLastOrderProduct?: boolean | null | undefined,
            quantity?: number
        ) => {
            const activeOrderWindow = get().getActiveOrderWindow();
            const ProductStockManager = get().ProductStockManager;
            const cartTotalOriginalPrice = getDiscountedCartTotalOriginalPrice(activeOrderWindow);

            const menuProductToAdd = {
                menuProduct: menuProduct,
                orderProduct: {
                    modifications: selectedModifications,
                    comment: comment,
                    addons: addons
                },
                customerMeta: { addedBy: !!nickname ? nickname : null, paidBy: null, status: CustomerMetaStatus.ADDED }
            } as unknown as MenuProductHashValues;

            const hashValueExtra = !!fixedDiscount ? { type: fixedDiscount.type } : null;
            const newCartProductId = createCartProductHashId(
                menuProductToAdd,
                get().activeOrderWindowId!,
                hashValueExtra
            );

            const cartProductExists = findCartProductInCartProducts(
                activeOrderWindow?.cartProducts ?? [],
                newCartProductId
            );
            const newQuantity =
                quantity ?? getProductCartQuantity(get().productAmount, cartProductExists, hasBeenSearched);
            const hasUpdatedUnitPrice = !!cartProductExists?.updatedUnitPrice;

            const lastOrderProduct = !!isLastOrderProduct ? isLastOrderProduct : false;

            const cartProduct = menuProductToCartProduct(
                newCartProductId,
                menuProduct,
                shopId,
                categoryId,
                selectedModifications,
                activeOrderWindow,
                cartTotalOriginalPrice,
                newQuantity,
                addons,
                upsell,
                //@ts-ignore
                hasUpdatedUnitPrice ? cartProductExists.orderProduct.unitPrice : null,
                comment || "",
                isStockTracked,
                nickname,
                lastOrderProduct
            );

            const updatedCartProduct = {
                ...cartProduct,
                fixedDiscount
            };

            let updatedOrderWindows;
            if (cartProductExists) {
                const cartProductToIncrement = hasUpdatedUnitPrice
                    ? {
                          ...updatedCartProduct,
                          orderProduct: {
                              ...updatedCartProduct.orderProduct,
                              unitPrice: cartProductExists.orderProduct.unitPrice
                          },
                          updatedUnitPrice: hasUpdatedUnitPrice
                      }
                    : updatedCartProduct;
                updatedOrderWindows = get().CartManager.handleCartProduct(
                    cartProductToIncrement,
                    get().CartManager.handleUpdateCartProduct
                );
            } else {
                updatedOrderWindows = get().CartManager.handleCartProduct(
                    updatedCartProduct,
                    get().CartManager.handleAddNewCartProduct
                );
            }

            get().setOrderWindows(updatedOrderWindows);

            // Check stock status
            if (isStockTracked) {
                ProductStockManager.onUpdateRemainingStockQuantity(updatedCartProduct);
            }

            orderWindowSocketStore.getState().onOrderWindowUpdated(get().getActiveOrderWindow());
        },
        addOrderProductToCartWithWeight: (
            menuProduct: MenuProduct,
            shopId: string,
            categoryId: string,
            weight: number,
            scaleType: ScaleInputMode
        ) => {
            const activeOrderWindow = get().getOrderWindow(get().activeOrderWindowId);
            const cartTotalOriginalPrice = getDiscountedCartTotalOriginalPrice(activeOrderWindow);

            // Generate the cardProductId using uuid and this will not change even if comment is added
            // -- used to prevent matching ids for discounts
            const cartProductId = uuidv4();
            //const cartProductId = getMenuProductHash(menuProduct.id, null, null, [], null, get().activeOrderWindowId);

            const cartProduct = menuProductToCartProductWithWeight(
                cartProductId,
                menuProduct,
                shopId,
                categoryId,
                activeOrderWindow,
                cartTotalOriginalPrice,
                weight,
                scaleType
            );

            const updatedCartProduct = {
                ...cartProduct
                // fixedDiscount -- Not sure what to do about this
            };

            /**
             * We don't combine variably priced products in the cart
             * e.g.
             *  Salad @ 500grams + Salad @ 700grams
             *
             * Does not become
             *  Salad @ 1200grams
             *
             * Weight products will always be separate entries in the cart
             */
            const updatedOrderWindows = get().CartManager.handleCartProduct(
                updatedCartProduct,
                get().CartManager.handleAddNewCartProduct
            );
            get().setOrderWindows(updatedOrderWindows);
        },
        addOrderProductComment: (cartProductId: string, comment: string) => {
            const activeOrderWindow = get().getActiveOrderWindow();
            const shouldUpdateSelectedBundleCartProductId = cartProductId === get().selectedBundleCartProduct?.id;
            const cartProductToUpdate = activeOrderWindow?.cartProducts.find(cp => cp.id === cartProductId);

            const cartProductWithComment = {
                ...cartProductToUpdate,
                orderProduct: {
                    ...cartProductToUpdate?.orderProduct,
                    comment: comment
                }
            };

            /**Do not change weighted product id each weighted product id should not change
             * from creation - Uuid identification is used
             */
            const isWeightedProduct =
                cartProductToUpdate && isVariablePriceType(cartProductToUpdate.orderProduct.priceType);
            const newCartProductId = isWeightedProduct
                ? cartProductId //@ts-ignore
                : createCartProductHashId(cartProductWithComment, get().activeOrderWindowId);

            const prodWithCommentExistsIdx = activeOrderWindow?.cartProducts?.findIndex(
                cp => cp.id === newCartProductId
            );

            let updatedCartProducts = activeOrderWindow?.cartProducts || [];

            // if product with comment already exists, remove and add to that quantity
            if (
                prodWithCommentExistsIdx &&
                prodWithCommentExistsIdx > -1 &&
                cartProductId !== newCartProductId &&
                !isWeightedProduct
            ) {
                updatedCartProducts[prodWithCommentExistsIdx] = get().CartManager.handleIncrementDecrementPriceUpdates(
                    activeOrderWindow,
                    updatedCartProducts[prodWithCommentExistsIdx],
                    cartProductToUpdate?.orderProduct.quantity
                );
                updatedCartProducts = updatedCartProducts.filter(cp => cp.id !== cartProductId);
            } else {
                updatedCartProducts = updatedCartProducts.map(cp =>
                    cp.id === cartProductId
                        ? {
                              ...cp,
                              id: newCartProductId,
                              ...(!isWeightedProduct && { oldId: cartProductId }),
                              orderProduct: {
                                  ...cp.orderProduct,
                                  comment
                              }
                          }
                        : cp
                );
            }

            const updatedOrderWindow = {
                ...activeOrderWindow,
                cartProducts: updatedCartProducts
            };

            if (shouldUpdateSelectedBundleCartProductId) {
                const updatedSelectedBundleCartProduct = {
                    ...get().selectedBundleCartProduct,
                    id: newCartProductId,
                    orderProduct: {
                        ...get().selectedBundleCartProduct?.orderProduct,
                        comment: comment
                    }
                };
                // @ts-ignore
                get().setSelectedBundleCartProduct(updatedSelectedBundleCartProduct);
            }
            // @ts-ignore
            get().updateOrderWindow(updatedOrderWindow);
        },
        toggleTakeAway: (orderWindowId: string) => {
            const orderWindow = get().getOrderWindow(orderWindowId);
            const updatedOrderWindow = {
                ...orderWindow,
                takeAway: !orderWindow?.takeAway
            } as OrderWindow;
            return get().updateOrderWindow(updatedOrderWindow);
        },
        setTakeAwayOnActiveOrderWindow: (takeAway: boolean) => {
            const activeOrderWindowId = get().activeOrderWindowId;
            const activeOrderWindow = get().getOrderWindow(activeOrderWindowId);
            const updatedOrderWindow = {
                ...activeOrderWindow,
                takeAway
            } as OrderWindow;
            return get().updateOrderWindow(updatedOrderWindow);
        },

        addCartToDTPreviousCart: (orderWindowId?: string) => {
            const _orderWindowId = orderWindowId || get().activeOrderWindowId;
            const orderWindow = get().getOrderWindow(_orderWindowId);
            const updatedOrderWindow = {
                ...orderWindow,
                previousCartProducts: orderWindow?.cartProducts
            } as OrderWindow;
            return get().updateOrderWindow(updatedOrderWindow);
        },

        updateOrderWindowPuckNo: (orderWindowId: string, puckNo: string) => {
            const orderWindow = get().getOrderWindow(orderWindowId);
            return get().updateOrderWindow({
                ...orderWindow,
                puckNo
            } as OrderWindow);
        },
        recalculateDiscountWithAddons: (orderWindow: OrderWindow) => {
            const cartTotalOriginalPrice = getDiscountedCartTotalAdjustedQuantities(
                orderWindow,
                orderWindow.discountedIdsAndQuantity
            );
            return orderWindow?.cartProducts?.map(cartProduct => {
                if (orderWindow.discount) {
                    return recalculatePriceAndDiscount(
                        cartProduct,
                        orderWindow.discount,
                        orderWindow.discountedProductIds,
                        cartTotalOriginalPrice,
                        orderWindow.discountedIdsAndQuantity
                    );
                } else {
                    return cartProduct;
                }
            });
        },
        handleDiscountedIdChangeAndQuantities: (updatedOrderWindows: OrderWindow[]) => {
            return updatedOrderWindows.map(window => {
                if (window.id === get().activeOrderWindowId) {
                    if (window.discount && window.discountedIdsAndQuantity?.length) {
                        const idsAndQuantities = window.cartProducts.reduce(
                            (totals: { cartId: string; quantity: number; discountType: DISCOUNT_TYPE }[], cart) => {
                                const discountedId = window?.discountedIdsAndQuantity?.find(
                                    quantity => quantity.cartId === cart.id || quantity.cartId === cart.oldId
                                );
                                if (discountedId) {
                                    const idAndQuantity = {
                                        cartId: cart.id,
                                        quantity: discountedId.quantity,
                                        discountType: discountedId.discountType
                                    };
                                    totals = totals.length > 0 ? [...totals, idAndQuantity] : [idAndQuantity];
                                }
                                return totals;
                            },
                            []
                        );
                        return {
                            ...window,
                            discountedIdsAndQuantity: idsAndQuantities
                        };
                    } else {
                        return window;
                    }
                } else {
                    return window;
                }
            });
        },
        handleCartProductDiscountedQuantities: (orderWindow: OrderWindow) => {
            const { discountedIdsAndQuantity, discount } = orderWindow;
            if (discountedIdsAndQuantity && discount) {
                return orderWindow?.cartProducts?.reduce(
                    (discountedTotal: CartIdAndQuantity[], cartProduct: CartProduct) => {
                        const discountQuantity = discountedIdsAndQuantity.find(
                            cart => cart.cartId === cartProduct.id || cart.cartId === cartProduct.oldId
                        );
                        if (discountQuantity) {
                            const cartIdAndQuantity = {
                                ...discountQuantity,
                                cartId: cartProduct.id
                            };
                            return [...discountedTotal, cartIdAndQuantity];
                        }
                        return discountedTotal;
                    },
                    []
                );
            } else {
                return discountedIdsAndQuantity;
            }
        },
        handleUpdateAddonOnCartProduct: (cartProductToUpdate: CartProduct, orderWindow: OrderWindow) => {
            const updatedCartProducts = orderWindow?.cartProducts?.map(cartProduct => {
                if (cartProductToUpdate.id === cartProduct.id) {
                    const { orderProduct } = cartProductToUpdate;

                    const updatedPriceAndVats = calculateMenuProductOrBundlePrice(
                        cartProductToUpdate.menuProduct ?? cartProductToUpdate.menuBundleProduct!,
                        orderProduct.quantity,
                        orderProduct.weight,
                        orderProduct.modifications ?? null,
                        orderProduct.selectedBundleProductItems ?? null,
                        orderProduct.addons,
                        cartProduct.updatedUnitPrice ? cartProduct.orderProduct.unitPrice : null
                    );
                    let updatedCartProduct = {
                        ...cartProductToUpdate,
                        orderProduct: {
                            ...orderProduct,
                            ...updatedPriceAndVats
                        }
                    };

                    const isWeightedProduct = isVariablePriceType(orderProduct.priceType);
                    if (!isWeightedProduct) {
                        const newId = createCartProductHashId(updatedCartProduct, orderWindow.id);

                        updatedCartProduct = {
                            ...updatedCartProduct,
                            id: newId,
                            oldId: cartProduct.id
                        };

                        if (orderWindow.discount) {
                            const { discountedProductIds } = orderWindow;
                            if (discountedProductIds.includes(updatedCartProduct.oldId ?? "")) {
                                const newDiscountedProductIds = discountedProductIds.filter(
                                    id => id !== updatedCartProduct.oldId
                                );
                                orderWindow.discountedProductIds = [...newDiscountedProductIds, newId];
                            }
                        }
                    }

                    return updatedCartProduct;
                } else {
                    return cartProduct;
                }
            });

            const addOnAdjustedOrderWindow = {
                ...orderWindow,
                cartProducts: mergeCartProducts(updatedCartProducts)
            };

            const updatedOrderWindow = {
                ...addOnAdjustedOrderWindow,
                discountedIdsAndQuantity: get().handleCartProductDiscountedQuantities(addOnAdjustedOrderWindow)
            };

            return get().recalculateDiscountWithAddons(updatedOrderWindow);
        },
        updateAddonToCartProduct: (cartProduct: CartProduct) => {
            const updatedOrderWindows = get().CartManager.handleCartProduct(
                cartProduct,
                get().handleUpdateAddonOnCartProduct
            );

            if (get().selectedBundleCartProduct) {
                const { orderProduct } = cartProduct;

                let updatedSelectedBundleCartProduct = {
                    ...get().selectedBundleCartProduct,
                    orderProduct: {
                        ...get().selectedBundleCartProduct?.orderProduct,
                        addons: orderProduct.addons
                    }
                };
                let updatedCartProduct = null;

                updatedOrderWindows.some((orderWindow: OrderWindow) => {
                    updatedCartProduct = orderWindow?.cartProducts?.find(cp => cp.oldId === cartProduct.id);
                    return !!updatedCartProduct; // Break the loop if a match is found
                });

                // During handleCartProduct we update id's in orderWindow,
                // but if we still have not finished bundleProduct,
                // we should update id's SelectedBundleCartProduct as well (Issue 4157)
                if (updatedCartProduct) {
                    updatedSelectedBundleCartProduct = {
                        ...updatedSelectedBundleCartProduct,
                        id: (updatedCartProduct as CartProduct).id,
                        oldId: (updatedCartProduct as CartProduct).oldId,
                        orderProduct: {
                            ...updatedSelectedBundleCartProduct.orderProduct,
                            selectedBundleProductItems: [
                                ...((updatedCartProduct as CartProduct).orderProduct.selectedBundleProductItems ?? [])
                            ]
                        }
                    };
                }
                // @ts-ignore
                get().setSelectedBundleCartProduct(updatedSelectedBundleCartProduct);
            }

            const handlePotentialdiscountQuantities = get().handleDiscountedIdChangeAndQuantities(updatedOrderWindows);
            return get().setOrderWindows(handlePotentialdiscountQuantities);
        },
        handleEditMenuProduct: (cartProduct: CartProduct) => {
            const activeOrderWindowId = get().activeOrderWindowId;
            const newCartProductId = createCartProductHashId(cartProduct, activeOrderWindowId ?? "");
            const orderWindow = get().getOrderWindow(activeOrderWindowId);

            const isAmountDiscounted = orderWindow?.discount && orderWindow.discount.amount > 0;
            const hasFixedDiscount = getFromLodash(cartProduct, "fixedDiscount.isFixedDiscountActive", false);
            const hasBeenModified = cartProduct.id !== newCartProductId;

            const twoOfSameIndex =
                hasBeenModified &&
                orderWindow?.cartProducts?.findIndex(cartProduct => cartProduct.id === newCartProductId);
            const isTwoOfSame = hasBeenModified && twoOfSameIndex ? twoOfSameIndex > -1 : false;

            const clonedCartProducts = [...(orderWindow?.cartProducts || [])];
            const currCartProducts = isTwoOfSame
                ? clonedCartProducts.filter((_, i) => twoOfSameIndex !== i)
                : orderWindow?.cartProducts;

            const sameOrderProduct = {
                ...(typeof twoOfSameIndex === "number" && orderWindow?.cartProducts[twoOfSameIndex]?.orderProduct),
                quantity: isTwoOfSame
                    ? typeof twoOfSameIndex === "number" &&
                      (orderWindow?.cartProducts[twoOfSameIndex].orderProduct.quantity ?? 0) +
                          cartProduct.orderProduct.quantity
                    : cartProduct.orderProduct.quantity
            };
            
            const tmpCartProduct = {
                ...cartProduct,
                id: newCartProductId,
                oldId: cartProduct.id,
                orderProduct: isTwoOfSame ? sameOrderProduct : cartProduct.orderProduct
            };

            const usedFixedDiscount = findFixedSubscriptionDiscount(
                orderWindow?.fixedDiscounts ?? [],
                cartProduct?.fixedDiscount
            );

            const updatedDiscountIds = get().CartManager.updateDiscountedProductIds(newCartProductId, cartProduct.id);
            const discountedIdsAndQuantity = get().CartManager.updateAppliedDiscountQuantities(
                newCartProductId,
                cartProduct.id
            );

            let updatedCartProduct = recalculatePriceAndDiscount(
                // @ts-ignore
                tmpCartProduct,
                isAmountDiscounted ? null : orderWindow?.discount,
                isAmountDiscounted ? [] : updatedDiscountIds,
                null,
                discountedIdsAndQuantity
            );

            const fixedDiscountedIds = get().CartManager.updateFixedDiscountedProductIds(
                newCartProductId,
                cartProduct.id
            );

            if (isAmountDiscounted) {
                // needs to be calculated again to get full cart original price
                const updateCartProducts = orderWindow?.cartProducts?.map(cp =>
                    cp.id === updatedCartProduct.oldId ? updatedCartProduct : cp
                );

                const calculateNewCartValue = getDiscountedCartTotalFromCartProducts(
                    updateCartProducts,
                    orderWindow.discount,
                    updatedDiscountIds,
                    discountedIdsAndQuantity
                );

                updatedCartProduct = recalculatePriceAndDiscount(
                    // @ts-ignore
                    tmpCartProduct,
                    orderWindow.discount,
                    updatedDiscountIds,
                    calculateNewCartValue,
                    discountedIdsAndQuantity
                );
            }

            if (!isAmountDiscounted && hasFixedDiscount) {
                const updatedOrderWindow = {
                    ...orderWindow,
                    fixedDiscountedProductIds: orderWindow?.fixedDiscounts,
                    cartProducts: orderWindow?.cartProducts?.map(cp =>
                        cp.id === updatedCartProduct.oldId ? updatedCartProduct : cp
                    )
                };
                updatedCartProduct = recalculatePriceAndDiscount(
                    { ...updatedCartProduct, id: newCartProductId, oldId: cartProduct.id },
                    usedFixedDiscount as OnlineFixedDiscount,
                    fixedDiscountedIds,
                    getDiscountedCartTotalOriginalPrice(updatedOrderWindow),
                    orderWindow?.discountedIdsAndQuantity
                );
            }

            const updatedCartProducts = currCartProducts?.map(cp =>
                cp.id === updatedCartProduct.oldId ? updatedCartProduct : cp
            );

            if (isAmountDiscounted && updatedCartProducts && updatedCartProducts.length > 1) {
                let updatedOrderWindow = {
                    ...orderWindow,
                    discountedProductIds: updatedDiscountIds,
                    fixedDiscountedProductIds: fixedDiscountedIds,
                    cartProducts: updatedCartProducts,
                    discountedIdsAndQuantity: discountedIdsAndQuantity
                };

                if (updatedOrderWindow) {
                    updatedOrderWindow.cartProducts = updateCombinedDiscountsAndOtherDiscounts(updatedOrderWindow);
                }

                return get().updateOrderWindow(updatedOrderWindow);
            }

            return get().updateOrderWindow({
                ...orderWindow,
                discountedProductIds: updatedDiscountIds,
                fixedDiscountedProductIds: fixedDiscountedIds, // @ts-ignore
                cartProducts: updatedCartProducts,
                discountedIdsAndQuantity: discountedIdsAndQuantity
            });
        },
        editProduct: (cartProduct: CartProduct) => {
            if (cartProduct.menuBundleProduct) {
                get().setSelectedBundleCartProduct({ ...cartProduct, idBeforeEdit: cartProduct.oldId });
            } else if (cartProduct.menuProduct) {
                get().handleEditMenuProduct(cartProduct);
            }
        },
        addLastOrderProductsToCart: (orderId: string, userSubscriptions?: UserSubscriptions) => {
            const { addons, refProducts, outOfStockIds } = posStore.getState();
            const shopId = qoplaStore.getState().selectedShop?.id || "";

            /** Get subscription state */
            const { subscriptionState } = onlineStore.getState();
            /** Get users subscriptions (use subscription id) */
            const userSubscriptionIds = userSubscriptions?.map(value => value.subscriptionId) ?? [];
            /** Check if users sub is currently on hold */
            const userSubsOnHold = userSubscriptionIds.filter(value => !!subscriptionState.subscriptionsOnHold[value]);

            get().LastOrderManager.createCartProductsFromLastOrder(
                orderId,
                shopId,
                !!userSubsOnHold.length,
                userSubscriptions,
                addons,
                refProducts as any,
                outOfStockIds
            );
        }
    })
);

const combineOrderWindowsAndActiveOrderWindowIdState = (
    state: OrderWindowsStore,
    activeOrderWindowId: string | null,
    orderWindows: OrderWindow[]
) => {
    const { selectedTable } = tableStore.getState();
    const { selectedPos } = posStore.getState();
    const shopId = qoplaStore.getState().selectedShop?.id || "";
    let updatedOrderWindows: OrderWindow[] = orderWindows || state.orderWindows;
    let updatedActiveOrderWindowId: string | null = activeOrderWindowId || state.activeOrderWindowId;

    const visibleOrderWindows = getVisibleOrderWindows(updatedOrderWindows, selectedTable?.id, []);
    const hasVisibleOrderWindows = visibleOrderWindows.length !== 0;
    const hasActiveOrderWindow = activeOrderWindowId != null;

    if (!hasVisibleOrderWindows) {
        // Attempt to load some windows from localStorage
        const localOrderWindows = getVisibleOrderWindows(
            getLocalOrderWindows(getPersistedObject(PersistConstants.ORDER_WINDOWS) || []),
            selectedTable?.id
        );

        // Create blank orderWindow if no orderWindows from local storage
        if (!localOrderWindows.length) {
            const isTakeAway = selectedPos?.preferTakeAway || false;
            const newOrderWindow = createNewOrderWindow(
                shopId,
                orderWindows,
                isTakeAway,
                null,
                selectedTable?.id || null
            );
            updatedOrderWindows = [...orderWindows, newOrderWindow];
            updatedActiveOrderWindowId = newOrderWindow.id;
        } else {
            // Set OrderWindows from local storage
            updatedOrderWindows = localOrderWindows;
            updatedActiveOrderWindowId = localOrderWindows[localOrderWindows.length - 1].id;
        }
    } else {
        // ONLY SET THE ACTIVE ID
        if (!hasActiveOrderWindow) {
            updatedOrderWindows = orderWindows;
            updatedActiveOrderWindowId = visibleOrderWindows[0].id;
        } else {
            updatedOrderWindows = orderWindows;
            updatedActiveOrderWindowId = activeOrderWindowId;
        }
    }

    // Trigger discount logic
    updatedOrderWindows = calculateAndModifyOrderWindowsWithDiscounts(
        state,
        updatedActiveOrderWindowId,
        updatedOrderWindows
    );

    const updatedOrderWindowAndActiveId = {
        orderWindows: updatedOrderWindows,
        activeOrderWindowId: updatedActiveOrderWindowId
    };
    return updatedOrderWindowAndActiveId;
};

/**
 * The goal of this function is to replace most, if not all useEffects that were previously called in providers, with the goal of "updating"
 *  the discount information in the `orderWindows`
 *
 * 1) Each `orderWindow` has  quite a few pieces of discount information.  At the time of this comment:
        discount: any;
        discountedIdsAndQuantity?: CartIdAndQuantity[];
        fixedDiscounts?: FixedDiscounts;
        discountedProductIds: string[];
        fixedDiscountedProductIds?: string[];
        comboDiscounts?: ComboDiscount[];
        comboDiscountedProductIds?: string[];
 *
 *  2) In addition to this, there are some global states that are used to determine how the `orderWindow` should be modified
        comboDiscountState: DISCOUNT_STATE;
        previousComboDiscountIds: Map<string, number>;
        upsoldDiscounts: UpsellDiscountOrderWindow[];
        comboDiscountIds: Map<string, number>;
        potentialComboDiscounts: PotentialComboDiscount[];
        allComboDiscounts: ComboDiscount[];
        potentialUpsellDiscounts: UpsellDiscount[];
        activeFixedMenuDiscount: FixedDiscount | null;
 *
 * This function should look at the states in 2) and decide how 1) should be set.
 *
 * NOTE: This is the bulk of a possible `DiscountManager` in the future.  It's arguable whether there should be a `DiscountStore`.  It would be recommended
 *  that we move towards a system where if ANY of the 2) values change, we recalculate 1) for the activeOrderWindow (possible all orderWindows).
 *
 * WARNING: This is a very crucial bit of logic. This is  If you find yourself modifying or moving this in the solution, please:
 *  - Try to do this in pairs
 *  - Consider naming a high priority
 *  - Try to reduce the complexity
 *  - Try to reduce the code size
 *
 * TODO: This is definitely a "code smell" -- Moving a complex piece of code into a function just to isolate it.  This is not testable, due to the state.
 *  and it's quite a dig to understand that this triggers everytime you update activeOrderWindowId or orderWindows.  I'm sorry if you got here through that logic <3
 */
const calculateAndModifyOrderWindowsWithDiscounts = (
    state: OrderWindowsStore,
    activeOrderWindowId: StringOrNull,
    updatedOrderWindows: OrderWindow[]
) => {
    /*****************
     * DISCOUNT LOGIC
     */
    // Update orderWindow(s) based on all available discount info
    updatedOrderWindows = updatedOrderWindows.map(orderWindow => {
        if (orderWindow.id === activeOrderWindowId) {
            const { updatedOrderWindow } = calculateActiveOrderWindowDiscount(
                orderWindow,
                state.comboDiscountIds,
                state.previousComboDiscountIds,
                state.upsoldDiscounts,
                state.potentialComboDiscounts
            );
            return updatedOrderWindow as OrderWindow;
        } else {
            return orderWindow;
        }
    });

    // Update upsoldDiscount state based on orderWindow info
    const hasComboDiscounts = state.allComboDiscounts && state.allComboDiscounts.length > 0;
    if (hasComboDiscounts) {
        if (updatedOrderWindows.length > 0 && state.upsoldDiscounts && state.upsoldDiscounts.length > 0) {
            const splitModeId = state.splitUpsoldDiscounts?.orderWindowId;
            const activeOrderWindowIds = updatedOrderWindows
                .filter((value: OrderWindow) => value.cartProducts?.length > 0 || value.id === splitModeId)
                .map((value: OrderWindow) => value.id);

            const updatedUpsoldDiscount = state.upsoldDiscounts.filter((value: UpsellDiscountOrderWindow) => {
                return activeOrderWindowIds.includes(value.orderWindowId);
            });
            if (!isEqual(updatedUpsoldDiscount, state.upsoldDiscounts)) {
                if (updatedUpsoldDiscount.length > 0) {
                    state.setUpsoldDiscounts(updatedUpsoldDiscount);
                } else {
                    state.setUpsoldDiscounts([]);
                }
            }
        }
    }
    // Update orderWindow(s) with correct comboDiscount information
    let reApplyAppliedDiscount = false;
    let appliedDiscountIds: string[] = [];
    const updatedOrderWindow = updatedOrderWindows.find(orderWindow => orderWindow?.id == activeOrderWindowId);
    updatedOrderWindows = updatedOrderWindows?.map((orderWindow: OrderWindow) => {
        if (orderWindow.id === activeOrderWindowId && updatedOrderWindow) {
            const combinedDiscountProductIds = getCombinedDiscountedProductIds(updatedOrderWindow);

            appliedDiscountIds = orderWindow.discountedProductIds?.filter(
                (id: string) => !combinedDiscountProductIds.includes(id)
            );

            reApplyAppliedDiscount = appliedDiscountIds?.length !== orderWindow.discountedProductIds?.length;

            const newOrderWindow = {
                ...orderWindow,
                comboDiscounts: state.allComboDiscounts?.filter((combo: ComboDiscount) =>
                    state.comboDiscountIds.has(combo.id!)
                ),
                comboDiscountedProductIds: combinedDiscountProductIds,
                discountedProductIds: appliedDiscountIds,
                discount: appliedDiscountIds?.length > 0 ? orderWindow.discount : null
            };
            return newOrderWindow;
        } else {
            return orderWindow;
        }
    });

    return updatedOrderWindows;
    /*
     * END DISCOUNT LOGIC
     *****************/
};

export const useOrderWindowsStore = createTrackedSelector(orderWindowsStore);
