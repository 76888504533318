import React, { useContext } from "react";
import propTypes from "prop-types";
import { Icon } from "semantic-ui-react";

import { SpringButton } from "TempUtils";
import { getMenuProductModificationsToUse } from "Utils";
import { ModalContext, usePos, modals } from "Providers";
import { modalNames } from "Constants";
import "../../pos.scss";
import { isVariablePriceType } from "ProductUtils";
import { DISCOUNT_TYPE } from "Types";
import { useOrderWindowsStore } from "Stores";

const discountTypesNotIncrement = [
    DISCOUNT_TYPE.APPLIED_DISCOUNT,
    DISCOUNT_TYPE.APPLIED_DISCOUNT_PER_PRODUCT,
    DISCOUNT_TYPE.PRICE_ADJUST,
    DISCOUNT_TYPE.QUANTITY_APPLIED_DISCOUNT
];

export const ListItemButtons = ({
    cartProduct,
    prodAddons,
    openAddonModal,
    utilFuncs,
    isEditable,
    quantity,
    shouldDisableAllButtons
}) => {
    const { removeCartProduct, incrementOrderProduct, decrementOrderProduct } = useOrderWindowsStore();
    const { closeModal, openModal } = useContext(ModalContext);
    const { isRefProductOutOfStock } = usePos();
    const isBundleProduct = !!cartProduct.menuBundleProduct;
    const productPriceType = isBundleProduct
        ? cartProduct.menuBundleProduct.refBundleProduct.priceType
        : cartProduct.menuProduct.refProduct.priceType;
    const hasVariablePriceType = isVariablePriceType(productPriceType);
    const isIncrementable = !hasVariablePriceType;
    //const isProductFinished = !!cartProduct?.isFinished ? cartProduct.isFinished : hasVariablePriceType;

    const addCommentToOrderProduct = newComment => {
        utilFuncs.addOrderProductComment(cartProduct.id, newComment);
        closeModal(modalNames.ADD_COMMENTS_MODAL);
    };

    /** Disable increment on applied discount or quantity applied discount (not combo) */
    const disableIncrementWhenDiscounted = !!cartProduct?.orderProduct?.combinedDiscounts?.some(value =>
        discountTypesNotIncrement.includes(value.discountType)
    );

    const outOfStock = isBundleProduct
        ? cartProduct?.orderProduct?.selectedBundleProductItems?.some(bundleItem =>
              isRefProductOutOfStock(bundleItem.refProductId)
          )
        : isRefProductOutOfStock(cartProduct?.orderProduct?.refProductId);

    const handleEditProduct = cartProduct => {
        if (cartProduct.menuBundleProduct) {
            utilFuncs.editProduct(cartProduct);
        } else if (cartProduct.menuProduct.refProduct.modifications) {
            openModal(modals.posModificationsModal, {
                productName: cartProduct.menuProduct.refProduct.name,
                modificationsToUse: getMenuProductModificationsToUse(cartProduct.menuProduct),
                modalCallback: selectedModifications => {
                    const updatedCartProduct = {
                        ...cartProduct,
                        orderProduct: {
                            ...cartProduct.orderProduct,
                            modifications: selectedModifications
                        }
                    };
                    utilFuncs.editProduct(updatedCartProduct);
                }
            });
        }
    };

    return (
        <>
            {prodAddons && prodAddons.length > 0 && (
                <SpringButton
                    className="ui icon button"
                    disabled={shouldDisableAllButtons}
                    style={{
                        fontSize: "1.2em",
                        backgroundColor: "#3cbac8",
                        color: "#FFF"
                    }}
                    customOnClick={e => {
                        e.stopPropagation();
                        openAddonModal(cartProduct, prodAddons, cartProduct.orderProduct.addons || []);
                    }}
                >
                    <Icon name="plus cart" />
                </SpringButton>
            )}
            <SpringButton
                className="ui icon button"
                disabled={shouldDisableAllButtons}
                style={{ fontSize: "1.2em", backgroundColor: "#6495ED", color: "#FFF" }}
                customOnClick={e => {
                    e.stopPropagation();
                    openModal(modalNames.ADD_COMMENTS_MODAL, {
                        addCommentToOrderProduct,
                        currentComment: cartProduct.orderProduct.comment
                    });
                }}
            >
                <Icon name="sticky note" />
            </SpringButton>
            {isEditable && (
                <SpringButton
                    className="ui icon button"
                    disabled={shouldDisableAllButtons}
                    style={{
                        fontSize: "1.2em",
                        backgroundColor: "#ffa426",
                        color: "#FFF"
                    }}
                    customOnClick={e => {
                        e.stopPropagation();
                        handleEditProduct(cartProduct);
                    }}
                >
                    <Icon name="pencil alternate" />
                </SpringButton>
            )}
            {isIncrementable && (
                <SpringButton
                    className="ui icon button"
                    style={{ fontSize: "1.2em", backgroundColor: "#ef863b", color: "#FFF" }}
                    customOnClick={e => {
                        e.stopPropagation();
                        decrementOrderProduct(cartProduct);
                    }}
                    disabled={quantity === 1}
                >
                    <Icon name="minus" />
                </SpringButton>
            )}
            {isIncrementable && (
                <SpringButton
                    className="ui icon button"
                    style={{ fontSize: "1.2em", backgroundColor: "#73bd86", color: "#FFF" }}
                    customOnClick={e => {
                        e.stopPropagation();
                        incrementOrderProduct(cartProduct);
                    }}
                    disabled={disableIncrementWhenDiscounted || outOfStock}
                >
                    <Icon name="plus" />
                </SpringButton>
            )}
            <SpringButton
                className="ui icon button"
                style={{ fontSize: "1.2em", backgroundColor: "#ff6060", color: "#FFF", marginLeft: "2em" }}
                customOnClick={e => {
                    e.stopPropagation();
                    removeCartProduct(cartProduct);
                }}
            >
                <Icon name="trash alternate" />
            </SpringButton>
        </>
    );
};

ListItemButtons.propTypes = {
    cartProduct: propTypes.object,
    utilFuncs: propTypes.shape({
        increment: propTypes.func,
        decrement: propTypes.func,
        removeCartProduct: propTypes.func,
        editProduct: propTypes.func,
        addOrderProductComment: propTypes.func,
        addOrderProductToCart: propTypes.func,
        handleUpdateCartProduct: propTypes.func
    }),
    isEditable: propTypes.bool,
    quantity: propTypes.number
};
