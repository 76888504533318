import { useState } from "react";
import { flatMap } from "lodash";

import {
    GET_ALCOHOL_PRODUCT_IDS,
    GET_COMPANY_REF_PRODUCTS,
    GET_INGREDIENTS_GROUP,
    GET_MENU_BY_IDS,
    GET_PAIRED_POS,
    GET_SHOP_OVERRIDE,
    GET_SHOP_SETTINGS_FOR_POS,
    SEARCH_TERMINALS_BY_ID,
    SHOP_HAS_MULTIPLE_PAIRED_POS
} from "GraphQLQueries";
import { useCookieConsent, useImperativeQuery, useEffectOnce } from "Hooks";
import { CookieKeys } from "Types";
import { moveCookieExpirationDate } from "../../../../../utils";

export type PosQueriesVariables = {
    shopId: string;
    companyId: string;
    isSwedishCompanyAndSellsAlcohol: boolean;
};

type PosQueriesState = {
    data: any;
    loading: boolean;
    errors: any;
    variables: PosQueriesVariables | null;
};

type UsePosQueriesReturn = [PosQueriesState, (variables?: PosQueriesVariables) => Promise<void>];

export const usePosQueries = (): UsePosQueriesReturn => {
    const queryOptions = { notifyOnNetworkStatusChange: true };

    const { selectedCookie: posCookie } = useCookieConsent(CookieKeys.SELECTED_POS);

    const [posQueries, setPosQueries] = useState<PosQueriesState>({
        data: null,
        loading: true,
        errors: null,
        variables: null
    });

    const loadPairedPos = useImperativeQuery(GET_PAIRED_POS, queryOptions);
    const loadMenusByIds = useImperativeQuery(GET_MENU_BY_IDS, queryOptions);
    const loadShopOverride = useImperativeQuery(GET_SHOP_OVERRIDE, queryOptions);
    const loadPosTerminals = useImperativeQuery(SEARCH_TERMINALS_BY_ID, queryOptions);
    const loadCompanyRefProducts = useImperativeQuery(GET_COMPANY_REF_PRODUCTS, queryOptions);
    const loadIngredientsGroup = useImperativeQuery(GET_INGREDIENTS_GROUP, queryOptions);
    const loadShopSettings = useImperativeQuery(GET_SHOP_SETTINGS_FOR_POS, queryOptions);
    const loadingHasMultiplePos = useImperativeQuery(SHOP_HAS_MULTIPLE_PAIRED_POS, queryOptions);
    const loadingAlcoholProductIds = useImperativeQuery(GET_ALCOHOL_PRODUCT_IDS, queryOptions);

    useEffectOnce(() => {
        moveCookieExpirationDate(CookieKeys.SELECTED_POS, posCookie);
    });

    const formatResponse = (data: any) => {
        return data.reduce((acc: any, curr: any) => {
            const [first] = Object.entries(curr);
            acc[first[0]] = first[1];

            return acc;
        }, {});
    };

    const loadData = async (variables?: PosQueriesVariables) => {
        const _variables = variables || posQueries.variables;

        if (!_variables) {
            setPosQueries({ errors: "No variables present", loading: false, data: null, variables: _variables });
            return;
        }
        const { shopId, companyId, isSwedishCompanyAndSellsAlcohol } = _variables;

        if (!posCookie) {
            setPosQueries({ errors: "No posCookie present", loading: false, data: null, variables: _variables });
            return;
        }

        const _loadPairedPos = await loadPairedPos({ cookieId: posCookie, shopId });

        if (_loadPairedPos && _loadPairedPos.data?.getPairedPos) {
            const {
                data: { getPairedPos }
            } = _loadPairedPos;

            try {
                const allQueries = [
                    loadMenusByIds({ menuIds: getPairedPos.menuIds }),
                    loadShopOverride({ shopId }),
                    loadPosTerminals({ terminalIds: getPairedPos.connectedTerminalIds }),
                    loadCompanyRefProducts({ companyId }),
                    loadIngredientsGroup({ companyId }),
                    loadShopSettings({ shopId }),
                    loadingHasMultiplePos({ shopId })
                ];

                if (isSwedishCompanyAndSellsAlcohol) {
                    allQueries.push(loadingAlcoholProductIds({ companyId }));
                }

                const res = await Promise.all(allQueries);

                const _res = res.concat(_loadPairedPos);
                const flatData = flatMap(_res, query => query.data);

                setPosQueries({
                    errors: null,
                    loading: false,
                    data: formatResponse(flatData),
                    variables: _variables
                });
            } catch (errors) {
                setPosQueries({
                    data: null,
                    loading: false,
                    errors,
                    variables: _variables
                });
            }
        } else {
            setPosQueries({ errors: "No POS matched", loading: false, data: null, variables: _variables });
        }
    };

    return [posQueries, loadData];
};
