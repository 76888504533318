import React from "react";
import { Icon } from "semantic-ui-react";

import { getSelectedModificationAddonPrice } from "../../utils/priceUtils";
import { SpringButton } from "TempUtils";
import { AddonsCartItem } from "../../../shared/components";
import { Text, Flex, Box, NewList, NewListItem } from "Atoms";

const getBundleItemText = bundleItem => {
    let sizeName,
        flavourName = "";
    if (bundleItem.modifications) {
        sizeName = bundleItem.modifications.sizes ? bundleItem.modifications.sizes.name : "";
        flavourName = bundleItem.modifications.flavours ? bundleItem.modifications.flavours.name : "";
    }
    return bundleItem.modifications ? `${sizeName} ${flavourName} ${bundleItem.name}`.trim() : bundleItem.name;
};

export const PosCartBundleItems = ({
    cartProduct,
    selectedBundleItems,
    activeUtilItemsOpened,
    addons,
    openAddonModal,
    shouldDisableAllButtons = false,
    ...rest
}) => {
    return (
        <NewList listStyle="none" stretch={2} {...rest}>
            {selectedBundleItems.map((bundleItem, idx) => {
                const bundleItemAddonPrice = getSelectedModificationAddonPrice(bundleItem.modifications);
                const symbol = bundleItemAddonPrice > 0 ? "+" : "";
                const bundleItemText = getBundleItemText(bundleItem);
                const bundleItemAddonPriceText =
                    _.isEmpty(bundleItem.modifications) || bundleItemAddonPrice === 0
                        ? ""
                        : `${symbol} ${bundleItemAddonPrice} kr`;
                const addonsToDisplay = activeUtilItemsOpened && addons[bundleItem.refProductId];

                const bundleItemKey = bundleItem.name + idx;

                return (
                    <NewListItem key={bundleItemKey}>
                        <Flex align="center">
                            <Box flex="1">
                                <Text as="span">{bundleItemText}</Text>
                            </Box>
                            <Flex align="center">
                                <Text as="span" mr={4}>
                                    {bundleItemAddonPriceText}
                                </Text>
                                {addonsToDisplay && addonsToDisplay.length > 0 && (
                                    <SpringButton
                                        className="ui icon button"
                                        disabled={shouldDisableAllButtons}
                                        style={{
                                            fontSize: "0.7em",
                                            backgroundColor: "#3cbac8",
                                            color: "#FFF"
                                        }}
                                        customOnClick={e => {
                                            e.stopPropagation();
                                            openAddonModal(
                                                cartProduct,
                                                addonsToDisplay,
                                                bundleItem.addons ? bundleItem.addons : [],
                                                bundleItemKey
                                            );
                                        }}
                                    >
                                        <Icon name="plus cart" />
                                    </SpringButton>
                                )}
                            </Flex>
                        </Flex>
                        {bundleItem.addons && <AddonsCartItem addons={bundleItem.addons} />}
                    </NewListItem>
                );
            })}
        </NewList>
    );
};
