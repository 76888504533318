import React, { useEffect } from "react";
import { FiTag } from "@react-icons/all-files/fi/FiTag";
import { IoWarningOutline } from "@react-icons/all-files/io5/IoWarningOutline";
import { Control, useController } from "react-hook-form";

import { CHECKOUT_DISCOUNT_VALIDATIONS, CartProduct, CountryLocaleId, DiscountDTO } from "Types";
import { Modal, ModalActions, ModalBody, ModalCloseBtn, ModalHeader } from "Molecules";
import { Flex, Box, FormLabel, Button, Header, RHCheckBoxInput } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { modalNames } from "Constants";
import { formatDateToLocal, formatFinancialNumbers } from "Utils";
import { useOnlineStore, useQoplaStore } from "Stores";
import { OnlineCheckoutFormValues } from "../../../OnlineCheckout";

type ModalProps = {
    modalContent: {
        email: string;
        discount: DiscountDTO;
        control: Control<OnlineCheckoutFormValues>;
        cartProducts: CartProduct[];
        onSetUserDiscount: (discount: DiscountDTO) => void;
        onRemoveUserDiscount: () => void;
    };
    closeModal: (modal: string) => void;
};

export const OnlineCheckoutDiscountModal: React.FC<ModalProps> = ({ modalContent, closeModal }) => {
    /** onRemoveUserDiscount not used but leaving here - may need it */
    const { discount, cartProducts, onSetUserDiscount, onRemoveUserDiscount, email, control } = modalContent;
    const { translate } = useLanguage();
    const { companyLocale } = useQoplaStore();
    const { getAlcoholRefProductIds } = useOnlineStore();

    const {
        field: { value: acceptsCampaigns, onChange: onAcceptCampaignsChange }
    } = useController({ control, name: "acceptsCampaigns" });

    const isSwedishRestaurant = companyLocale?.baseName === CountryLocaleId.sv_SE || true;
    const alcoholRefProductIds = getAlcoholRefProductIds();

    const onCloseModal = () => {
        closeModal(modalNames.ONLINE_CHECKOUT_DISCOUNT_MODAL);
    };

    const onApplyDiscount = () => {
        onSetUserDiscount(discount);
        onCloseModal();
    };

    /** If the discount is not allowed for that product - this will provide the reason (displayed next to product name) */
    const noDiscountAvailableReason = (cartProduct: CartProduct) => {
        const refProductId = cartProduct.orderProduct?.refProductId ?? cartProduct.orderProduct.refBundleProductId!;
        const isExcludedFromDiscount = alcoholRefProductIds.has(refProductId);
        if (isExcludedFromDiscount && isSwedishRestaurant) {
            return translate(CHECKOUT_DISCOUNT_VALIDATIONS.NO_DISCOUNT_ON_ALCOHOL);
        }

        if (isExcludedFromDiscount) {
            return translate(CHECKOUT_DISCOUNT_VALIDATIONS.NO_DISCOUNT_ON_PRODUCTS);
        }

        if (!!cartProduct?.fixedDiscount) {
            const canCombineDiscount =
                (cartProduct.fixedDiscount && cartProduct.fixedDiscount.canCombineDiscounts) ||
                !cartProduct.fixedDiscount;
            if (!canCombineDiscount) {
                return translate(CHECKOUT_DISCOUNT_VALIDATIONS.NO_COMBINING_DISCOUNTS);
            }
        }

        return translate(CHECKOUT_DISCOUNT_VALIDATIONS.NO_DISCOUNT_ON_PRODUCTS);
    };

    const formattedEndDate = formatDateToLocal(discount.endDate!, "YYYY-MM-DD");
    const discountDisplayed = !!discount.amount
        ? formatFinancialNumbers(discount.amount, companyLocale, undefined, true)
        : `${discount.rate}%`;

    const hasEmail = email?.length > 0;

    const hasDiscountLimit = (discount?.maxRedeemed ?? 0) > 0;
    const multiUseDiscount = (discount?.maxRedeemed ?? 0) > 1;

    const isADiscountedProduct = (cartProduct: CartProduct) =>
        discount?.productIdsForDiscount?.includes(cartProduct.id) ?? false;

    useEffect(() => {
        if (!acceptsCampaigns) {
            onAcceptCampaignsChange(true);
        }
    }, []);

    return (
        <Modal
            border={"3px solid #E2E8F0"}
            open
            placement="center"
            size={"sm"}
            borderRadius={"2xl"}
            p={4}
            onClose={onCloseModal}
        >
            <ModalHeader
                color={"#111"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                fontSize={"xl"}
            >
                {discount.name}
            </ModalHeader>
            <ModalCloseBtn onClick={onCloseModal} />
            <ModalBody mt={0} pt={0} mb={0}>
                <Flex direction={"column"} width={"100%"}>
                    <Header fontSize={"5xl"} m={0} p={0} mb={4} color={"accent"} textShadow={"sm"} ml="auto" mr="auto">
                        {discountDisplayed}
                    </Header>

                    <FormLabel mb={2} ml="auto" mr="auto">
                        <Box as="span">{translate("validUntil")}: </Box>
                        <Box as="span" color="yellow.900" fontWeight={"600"}>
                            {formattedEndDate}
                        </Box>
                    </FormLabel>

                    {cartProducts
                        .sort((a, b) => (isADiscountedProduct(b) ? 0 : isADiscountedProduct(a) ? -1 : 1))
                        .map(cartProduct => {
                            const canDiscountItem = isADiscountedProduct(cartProduct);
                            const noDiscountReasonText = !canDiscountItem
                                ? noDiscountAvailableReason(cartProduct)
                                : null;
                            return (
                                <Flex
                                    flexDirection={["column", "row"]}
                                    key={cartProduct.id}
                                    mb={3}
                                    justifyContent={"flex-start"}
                                >
                                    <Flex alignItems={"center"}>
                                        <Box
                                            as={canDiscountItem ? FiTag : IoWarningOutline}
                                            color={canDiscountItem ? "accent" : ""}
                                        />
                                        <FormLabel fontSize={"md"} m={0} p={0} ml={2}>
                                            {cartProduct.orderProduct.name}
                                        </FormLabel>
                                    </Flex>
                                    <FormLabel fontSize={"xs"} color={"red.600"} m={0} p={0} ml={[6, 2]} mt={1}>
                                        {!!noDiscountReasonText && `(${noDiscountReasonText})`}
                                    </FormLabel>
                                </Flex>
                            );
                        })}
                </Flex>
            </ModalBody>
            <ModalActions
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                pt={0}
            >
                {hasDiscountLimit ? (
                    <FormLabel mb={2} ml="auto" mr="auto">
                        <Box as="span">{translate("maxUsage")}: </Box>
                        <Box as="span" color="yellow.900" fontWeight={"600"}>
                            {`${discount.maxRedeemed} ${multiUseDiscount ? translate("manyTimes") : translate("oneTime")}`}
                        </Box>
                    </FormLabel>
                ) : null}
                {hasEmail && (
                    <FormLabel fontSize={"sm"} mb={2} pr={0}>
                        <Box as="span" color="gray.600">{`${translate("personalOfferFor")}:`}</Box>
                        <Box as="span">{` ${email}`}</Box>
                    </FormLabel>
                )}

                <RHCheckBoxInput control={control} name="acceptsCampaigns">
                    {translate("onlineAcceptsCampaigns")}
                </RHCheckBoxInput>

                <Button fullWidth themeColor="blue" borderRadius={"xl"} onClick={onApplyDiscount}>
                    {translate("applyDiscount")}
                </Button>
            </ModalActions>
        </Modal>
    );
};
