import Cookies from "universal-cookie";

const MAX_ALLOWED_DAYS = 400;

export const moveCookieExpirationDate = (cookieName: string, cookieId: string) => {
    const cookies = new Cookies();
    const newExpirationDate = new Date();
    newExpirationDate.setDate(newExpirationDate.getDate() + MAX_ALLOWED_DAYS);
    cookies.set(cookieName, cookieId, {
        path: "/",
        expires: newExpirationDate
    });
};
